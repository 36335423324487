import {
  SET_MOBILE_USERS_PARTIAL_IMPORT_STATUS_REQUESTED,
  SET_MOBILE_USERS_BULK_IMPORT_STATUS_REQUESTED,
  CLEAR_MOBILE_USERS_PARTIAL_IMPORT_STATUS,
  CLEAR_MOBILE_USERS_BULK_IMPORT_STATUS,
  START_MOBILE_USERS_IMPORT_REQUESTED,
  FINISH_MOBILE_USERS_IMPORT_REQUESTED,
  SET_UPLOAD_PROGRESS
} from './actions'

const defaultState = {
  partialImportStatus: {},
  bulkImportStatus: {},
  importInProcess: false,
  uploadProgress: 0
};

const mobileUsersImportReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MOBILE_USERS_PARTIAL_IMPORT_STATUS_REQUESTED:
      return {
        ...state,
        partialImportStatus: action.payload
      }
    case SET_MOBILE_USERS_BULK_IMPORT_STATUS_REQUESTED:
      return {
        ...state,
        bulkImportStatus: action.payload
      }
    case CLEAR_MOBILE_USERS_PARTIAL_IMPORT_STATUS:
      return {
        ...state,
        partialImportStatus: {}
      }
    case CLEAR_MOBILE_USERS_BULK_IMPORT_STATUS:
      return {
        ...state,
        bulkImportStatus: {}
      }
    case START_MOBILE_USERS_IMPORT_REQUESTED:
      return {
        ...state,
        importInProcess: true
      }
    case FINISH_MOBILE_USERS_IMPORT_REQUESTED:
      return {
        ...state,
        importInProcess: false
      }
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload
      }
    default:
      return {...state}
  }
}

export default mobileUsersImportReducer;
