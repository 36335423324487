import {SET_SIDEBAR_COLLAPSED} from "./actions";

interface StateInterface {
  sidebarCollapsed: boolean;
}

const defaultState: StateInterface = {
  sidebarCollapsed: false,
};

const layoutReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case SET_SIDEBAR_COLLAPSED: {
      return setSidebarCollapsed(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setSidebarCollapsed(state, payload: boolean) {
  return {
    ...state,
    sidebarCollapsed: payload,
  }
}

export default layoutReducer;
