import {call, put, takeLatest} from 'redux-saga/effects';
import {DefaultAction} from "../interface";
import {GET_PHOTOS} from "../../api/Unsplash";
import {UNSPLASH_GET_PHOTOS, UnsplashPhotosGetRequestedPayload, unsplashSetPhotosAction} from "./actions";
import {Photos} from "unsplash-js/dist/methods/search/types/response";

function* getPhotos(action: DefaultAction<UnsplashPhotosGetRequestedPayload>) {
    try {
        const response: Photos = (yield call(GET_PHOTOS, action.payload.params)).response;
        yield put(unsplashSetPhotosAction(response));
        if (action.payload.callback) {
            action.payload.callback(response);
        }
    } catch (e) {
        console.log(e);
    }
}

function* unsplashSaga() {
    yield takeLatest(UNSPLASH_GET_PHOTOS, getPhotos);
}

export default unsplashSaga;