import { call, takeLatest } from 'redux-saga/effects';
import {DefaultAction} from "../interface";
import {
    TRANSITION_CREATE_REQUESTED, TRANSITION_DELETE_REQUESTED, TRANSITION_UPDATE_REQUESTED,
    TransitionCreateRequestedPayload, TransitionDeleteRequestedPayload, TransitionUpdateRequestedPayload
} from "./actions";
import {
    UpdateStepResponse
} from "../../api/Steps";
import {PrepareTransitionToUpload} from "./helpers";
import {CREATE_TRANSITION, CreateTransitionResponse, DELETE_TRANSITION, UPDATE_TRANSITION} from "../../api/Transitions";

function* createTransition(action: DefaultAction<TransitionCreateRequestedPayload>) {
    try {
        const responseData: CreateTransitionResponse = (yield call(CREATE_TRANSITION,
            PrepareTransitionToUpload(action.payload.data))).data;
        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateTransition(action: DefaultAction<TransitionUpdateRequestedPayload>) {
    try {
        const responseData: UpdateStepResponse = (yield call(UPDATE_TRANSITION, action.payload.data.id,
            PrepareTransitionToUpload(action.payload.data))).data;

    } catch (e) {
        console.log(e);
    }
}

function* deleteTransition(action: DefaultAction<TransitionDeleteRequestedPayload>) {
    try {
        yield call(DELETE_TRANSITION, action.payload.id);
    } catch (e) {
        console.log(e);
    }
}

function* transitionsSaga() {
    yield takeLatest(TRANSITION_CREATE_REQUESTED, createTransition);
    yield takeLatest(TRANSITION_UPDATE_REQUESTED, updateTransition);
    yield takeLatest(TRANSITION_DELETE_REQUESTED, deleteTransition);
}

export default transitionsSaga;