import {
  ACTION_SET_DASHBOARDS,
  ACTION_SET_META_BASES,
} from './actions';
import {DashboardInterface, MetaBaseInterface} from "../../api/MetaBase";

interface StateInterface {
  metaBases: MetaBaseInterface[];
  dashboards: DashboardInterface[];
}

const defaultState: StateInterface = {
  metaBases: [],
  dashboards: [],
};

const metaBaseReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_META_BASES: {
      return setMetaBases(state, action.payload);
    }
    case ACTION_SET_DASHBOARDS: {
      return setDashboards(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setMetaBases(state, payload: MetaBaseInterface[]) {
  return {
    ...state,
    metaBases: payload,
  }
}

function setDashboards(state, payload: DashboardInterface[]) {
  return {
    ...state,
    dashboards: payload,
  }
}

export default metaBaseReducer;