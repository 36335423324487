import {AuthApi} from "./index";
import {CONNECTION_KIND} from "../containers/Screenplay/constants";

const TRANSITIONS_API_URL: string = 'transitions';

export interface TransitionInterface {
    step_id?: string;
    next_step_id?: string;
    button_text?: string;
    kind?: CONNECTION_KIND;
    position?: number;

    id?: string;
    created_at?: string;
    updated_at?: string;
}

export interface CreateTransitionResponse {
    success: boolean;
    model: TransitionInterface,
    errors: any;
}

export const CREATE_TRANSITION = (data: TransitionInterface) => {
    return AuthApi.post<CreateTransitionResponse>(TRANSITIONS_API_URL, data);
};

export interface UpdateTransitionResponse {
    success: boolean;
    model: TransitionInterface,
    errors: any;
}

export const UPDATE_TRANSITION = (id: string, data: TransitionInterface, ) => {
    return AuthApi.patch<UpdateTransitionResponse>(`${TRANSITIONS_API_URL}/${id}`, data);
};

export interface DeleteTransitionResponse {
    success: boolean;
    model: TransitionInterface,
    errors: any;
}

export const DELETE_TRANSITION = (id: string) => {
    return AuthApi.delete<DeleteTransitionResponse>(`${TRANSITIONS_API_URL}/${id}`);
};