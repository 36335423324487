import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {BUILD_MODES} from "./constants/environment";
import {removeLocalStorageItem} from "./utils/localStorage";
import {LOCAL_STORAGE_KEYS} from "./constants/localStorage";
import moment from 'moment';

removeLocalStorageItem(LOCAL_STORAGE_KEYS.LANGUAGE);

i18n
// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: `/locales/{{lng}}/translation.json?v=${moment().format('Y.M.D.H.m')}`
        },
        lng: 'en',
        fallbackLng: 'en',
        debug: process.env.REACT_APP_BUILD_MODE === BUILD_MODES.DEVELOP,
        load: 'languageOnly',
        nsSeparator: false,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
