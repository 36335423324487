import {AuthApi} from "./index";
import {SCREENPLAY_TYPE} from "../containers/Screenplay/constants";
import {TransitionInterface} from "./Transitions";
import {SectionInterface} from "./Sections";
import {TestInterface} from "./Tests";

const STEPS_API_URL: string = 'steps';

export interface StepAttachmentInterface {
    created_at: string;
    display_name: string;
    download_thumbnail_url: string;
    download_url: string;
    duration: number;
    id: string;
    tenant_id: string;
    updated_at: string;
}

export interface StepInterface {
    id: string;
    attachment: StepAttachmentInterface;
    content: any;
    created_at: string;
    first_step: boolean;
    hints: boolean;
    kind: SCREENPLAY_TYPE;
    last_step: boolean;
    lesson_id: string;
    test: TestInterface;
    name: string;
    position_x: number;
    position_y: number;
    sections: SectionInterface[];
    tenant_id: string;
    updated_at: string;
    transitions?: TransitionInterface[];
}

export interface CreateStepResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const CREATE_STEP = (data: FormData) => {
    return AuthApi.post<CreateStepResponse>(STEPS_API_URL, data);
};

export interface UpdateStepResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const UPDATE_STEP = (id: string, data: FormData, ) => {
    return AuthApi.patch<UpdateStepResponse>(`${STEPS_API_URL}/${id}`, data);
};

export interface StepTransitionsPositionInterface {
    id: string;
    position: number;
}

export interface StepTransitionsPositionRequestData {
    transitions: StepTransitionsPositionInterface[];
}

export interface UpdateStepTransitionsPositionResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const UPDATE_STEP_TRANSITIONS_POSITION = (id: string, data: StepTransitionsPositionRequestData, ) => {
    return AuthApi.post<UpdateStepTransitionsPositionResponse>(`${STEPS_API_URL}/${id}/update_transitions_position`, data);
};

interface StepSectionPositionsInterface {
    id: string;
    position: number;
}

export interface StepSectionPositionsRequestData {
    sections: StepSectionPositionsInterface[];
}

export interface UpdateStepSectionPositionsResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const UPDATE_STEP_SECTIONS_POSITION = (id: string, data: StepSectionPositionsRequestData, ) => {
    return AuthApi.post<UpdateStepSectionPositionsResponse>(`${STEPS_API_URL}/${id}/update_sections_position`, data);
};

export interface CopyStepRequestData {
    position_x: number;
    position_y: number;
}

export interface CopyStepResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const COPY_STEP = (id: string, data: CopyStepRequestData) => {
    return AuthApi.post<CopyStepResponse>(`${STEPS_API_URL}/${id}/copy`, data);
};

export interface DeleteStepResponse {
    success: boolean;
    model: StepInterface,
    errors: any;
}

export const DELETE_STEP = (id: string) => {
    return AuthApi.delete<DeleteStepResponse>(`${STEPS_API_URL}/${id}`);
};