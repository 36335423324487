import ImageTypeIcon from '../../containers/Screenplay/image-icon.svg';
import TextTypeIcon from '../../containers/Screenplay/text-icon.svg';
import VideoTypeIcon from './video-type.svg';
import AudioTypeIcon from '../../containers/Screenplay/audio-icon.svg';
import GlossaryTypeIcon from '../../containers/Screenplay/glossary-icon.svg';
import {GlossaryCardClass, GlossaryCardInterface} from "../../containers/Screenplay/constants";

export const LONGREAD_AUDIO_NAME_LIMIT: number = 30;

export enum LessonContentType {
    TEXT = 'text',
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    GLOSSARY = 'cards',
}

export interface LongreadSectionAttachmentInterface {
    id?: string;
    display_name?: string;
    file?: File;
    url?: string;
    duration?: number;
}

export interface LongreadContentInterface {
    text?: string;
    cards?: GlossaryCardInterface[];
    idCounter?: number;
}

export interface LongreadSectionInterface {
    attachment_attributes: LongreadSectionAttachmentInterface;
    content: LongreadContentInterface;
    kind: LessonContentType;
    position: number;
    id?: string;
}

export interface LongreadSectionToPrepareInterface {
    attachment_attributes?: LongreadSectionAttachmentInterface;
    content?: LongreadContentInterface;
    kind?: LessonContentType;
    position?: number;
    id?: string;
}

export class LongreadSectionClass implements LongreadSectionInterface {
    constructor(data) {
        const {
            kind,
            id,
            position,
        } = data;
        this.kind = kind;
        this.id = String(id);
        this.position = position + 1;

        switch (kind) {
            case LessonContentType.TEXT:
                this.content.text = '';
                break;
            case LessonContentType.GLOSSARY:
                this.content.cards = [new GlossaryCardClass(0)];
                break;
            case LessonContentType.IMAGE:
                this.attachment_attributes.file = null;
                this.attachment_attributes.url = '';
                break;
            case LessonContentType.VIDEO:
                this.attachment_attributes.file = null;
                this.attachment_attributes.url = '';
                break;
            case LessonContentType.AUDIO:
                this.attachment_attributes.file = null;
                this.attachment_attributes.duration = null;
                this.attachment_attributes.display_name = '';
                this.attachment_attributes.url = '';
                break;
        }
    }

    kind;
    id;
    position;
    content: LongreadContentInterface = {};
    attachment_attributes: LongreadSectionAttachmentInterface = {};
}

export interface LongreadStaticDataInterface {
    value: LessonContentType;
    title: string;
    img: string;
}

type LongreadStaticDataType = {
    [key in LessonContentType]: LongreadStaticDataInterface
}

export const LongreadStaticData: LongreadStaticDataType = {
    [LessonContentType.TEXT]: {
        value: LessonContentType.TEXT,
        title: 'SCREENPLAY.BLOCK.LONGREAD_TEXT_TITLE',
        img: TextTypeIcon,
    },
    [LessonContentType.VIDEO]: {
        value: LessonContentType.VIDEO,
        title: 'SCREENPLAY.BLOCK.LONGREAD_VIDEO_TITLE',
        img: VideoTypeIcon,
    },
    [LessonContentType.AUDIO]: {
        value: LessonContentType.AUDIO,
        title: 'SCREENPLAY.BLOCK.LONGREAD_AUDIO_TITLE',
        img: AudioTypeIcon,
    },
    [LessonContentType.IMAGE]: {
        value: LessonContentType.IMAGE,
        title: 'SCREENPLAY.BLOCK.LONGREAD_IMAGE_TITLE',
        img: ImageTypeIcon,
    },
    [LessonContentType.GLOSSARY]: {
        value: LessonContentType.GLOSSARY,
        title: 'SCREENPLAY.BLOCK.LONGREAD_GLOSSARY_TITLE',
        img: GlossaryTypeIcon,
    },
};