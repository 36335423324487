import { call, put, takeLatest } from 'redux-saga/effects';
import {GET_TENANT, TenantInterface, UPDATE_TENANT} from "../../api/Tenant";
import {
    setTenantAction,
    TENANT_GET_REQUESTED,
    TENANT_UPDATE_REQUESTED,
    TenantGetRequestedPayload
} from "../tenant/actions";
import {SetAmplitudeUserProperties} from "../../utils/amplitude";
import {DefaultAction} from "../interface";

function* getTenant(action: DefaultAction<TenantGetRequestedPayload>) {
    try {
        const responseData: TenantInterface = (yield call(GET_TENANT)).data;
        if (action.payload.isInitial) {
            SetAmplitudeUserProperties({
                id: responseData.id,
                display_name: responseData.display_name,
            });
        }
        yield put(setTenantAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* updateTenant(action) {
    try {
        yield call(UPDATE_TENANT, action.payload.data);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        console.log(error);
    }
}

function* tenantSaga() {
    yield takeLatest(TENANT_GET_REQUESTED, getTenant);
    yield takeLatest(TENANT_UPDATE_REQUESTED, updateTenant);
}

export default tenantSaga;