import { call, put, takeLatest } from 'redux-saga/effects';
import { ACTION_DOWNLOAD_FILE_REQUESTED } from '../downloads/actions';
import { DOWNLOAD_FILE_DATA } from '../../api/Downloads';

function* downloadFile(action) {
  try {
    const {
      url,
      params,
      callback
    } = action.payload

    const responseData = yield call(DOWNLOAD_FILE_DATA, url, params);
    if (callback) {
      callback(responseData);
    }
  } catch (error) {
    console.log(error)
  }
}

function* downloadsSaga() {
  yield takeLatest(ACTION_DOWNLOAD_FILE_REQUESTED, downloadFile)
}

export default downloadsSaga;
