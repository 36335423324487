import {AuthApi} from "./index";

const LEVELS_API_URL = 'levels';

export interface LevelInterface {
    id: string;
    display_name: string;
    score: number;
}

export interface GetLevelsDataInterface {
    levels: LevelInterface[];
}

export const GET_LEVELS = () => {
    return AuthApi.get<GetLevelsDataInterface>(`${LEVELS_API_URL}`);
};

export interface CreateLevelBody {
    display_name: string;
    score: number;
}

export const CREATE_LEVEL = (body: CreateLevelBody) => {
    return AuthApi.post(`${LEVELS_API_URL}`, body);
};

export interface UpdateLevelBody {
    display_name: string;
    score?: number;
}

export const UPDATE_LEVEL = (id: string, body: UpdateLevelBody) => {
    return AuthApi.patch(`${LEVELS_API_URL}/${id}`, body);
};

export const DELETE_LEVEL = (id: string) => {
    return AuthApi.delete(`${LEVELS_API_URL}/${id}`);
};