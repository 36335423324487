import {AuthApi} from "./index";
import {PaginationMetaInterface} from "./interface";

export interface GetFeedbackCoursesParams {
    name?: string;
    limit?: number;
    offset?: number;
    published?: boolean;
    archived?: boolean;
}

export interface FeedbackCoursesInterface {
    id: string;
    attachment_id: string;
    average_score: number;
    cover_url: string;
    created_at: string;
    description: string;
    has_tests: boolean;
    mobile_users_count: number;
    name: string;
}

export interface FeedbackCoursesDataInterface {
    courses: FeedbackCoursesInterface[],
    meta: PaginationMetaInterface;
}

export const GET_FEEDBACK_COURSES = (params: GetFeedbackCoursesParams = {}) => {
    return AuthApi.get<FeedbackCoursesDataInterface>(`feedbacks/courses`, {
        params,
    });
};

interface CourseFeedbackCoursesInterface {
    id: string;
    name: string;
    score: number;
}

interface CourseFeedbacksInterface {
    id: string;
    tenant_id: string;
    course_id: string;
    mobile_user_id: string;
    score: number;
    comment: string;
    created_at: string;
    updated_at: string;
}

export interface FeedbackCourseInterface {
    title: string;
    feedback_count: number;
    average_score: string;
    one: number;
    two: number;
    three: number;
    four: number;
    five: number;
    courses: CourseFeedbackCoursesInterface[];
    feedbacks: CourseFeedbacksInterface[];
}

export const GET_FEEDBACK_COURSE = (id: string) => {
    return AuthApi.get<FeedbackCourseInterface>(`feedbacks/courses/${id}`);
};

export interface CourseFeedbackInterface {
    id: string;
    comment: string;
    created_at: string;
    score: number;
}

export interface CourseFeedbackData {
    feedbacks: CourseFeedbackInterface[],
    meta: PaginationMetaInterface;
}

export interface GetCourseFeedbackParams {
    limit?: number;
    offset?: number;
}

export const GET_COURSE_FEEDBACK = (id: string, params: GetCourseFeedbackParams = {}) => {
    return AuthApi.get<CourseFeedbackData>(`feedbacks/courses/${id}/reports`, {
        params,
    });
};