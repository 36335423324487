import {
    ACTION_SET_ERROR_CODE,
    ACTION_SET_USER_PROFILE,
    ACTION_SET_USERS,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {UserInterface, UsersDataInterface} from "./interface";
import {EmptyObjectType} from "../../interfaces/default";

interface StateInterface {
    userProfile: EmptyObjectType<UserInterface>;
    userProfileLoaded: boolean;
    usersData: UsersDataInterface;
    errorCode: number;
}

const defaultState: StateInterface = {
    userProfile: {},
    userProfileLoaded: false,
    usersData: {
        users: null,
        meta: PAGES_DEFAULT_META,
    },
    errorCode: null,
};

const userReducer = (state = defaultState, action): StateInterface => {
    switch (action.type) {
        case ACTION_SET_USERS: {
            return setUsersData(state, action.payload);
        }
        case ACTION_SET_USER_PROFILE: {
            return setUserProfile(state, action.payload);
        }
        case ACTION_SET_ERROR_CODE: {
            return setErrorCode(state, action.payload);
        }
        default: {
            return state;
        }
    }
};

function setUsersData(state, payload: UsersDataInterface) {
    return {
        ...state,
        usersData: payload,
    }
}

function setUserProfile(state, payload: EmptyObjectType<UserInterface>) {
    return {
        ...state,
        userProfile: payload,
        userProfileLoaded: true,
    }
}

function setErrorCode(state, payload: string) {
    return {
        ...state,
        errorCode: payload,
    }
}

export default userReducer;