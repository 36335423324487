import { call, put, takeLatest } from 'redux-saga/effects';
import {DefaultAction} from "../interface";
import {
    CREATE_NEWS,
    CreateNewsResponse,
    DELETE_NEWS, GET_NEWS, GET_ONE_NEWS,
    NewsDataInterface,
    NewsInterface, PUBLISH_NEWS,
    UPDATE_NEWS,
    UpdateNewsResponse
} from "../../api/News";
import {
    NEWS_CREATE_REQUESTED,
    NEWS_DELETE_REQUESTED, NEWS_GET_REQUESTED,
    NEWS_UPDATE_REQUESTED, NewsCreateRequestedPayload,
    NewsDeleteRequestedPayload, NewsGetRequestedPayload,
    NewsUpdateRequestedPayload, ONE_NEWS_GET_REQUESTED, OneNewsGetRequestedPayload,
    setNewsDataAction, setNewsFormAction,
} from "../news/actions";
import {PrepareNewsToUpload} from "../news/helpers";
import {createNotificationAction} from "../notifications/actions";

function* getNews(action: DefaultAction<NewsGetRequestedPayload>) {
    try {
        const { params, callback } = action.payload;
        const responseData: NewsDataInterface = (yield call(GET_NEWS, params)).data;
        yield put(setNewsDataAction(responseData));
        if (callback) callback(responseData);
    } catch (e) {
        console.log(e);
    }
}

function* getOneNews(action: DefaultAction<OneNewsGetRequestedPayload>) {
    try {
        const responseData: NewsInterface = (yield call(GET_ONE_NEWS, action.payload.id)).data;
        yield put(setNewsFormAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* createNews(action: DefaultAction<NewsCreateRequestedPayload>) {
    try {
        const responseData: CreateNewsResponse = (yield call(CREATE_NEWS,
            yield PrepareNewsToUpload(action.payload.data))).data;
        if (action.payload.publish) {
            yield call(PUBLISH_NEWS, responseData.model.id);
        }

        if (action.payload.callback) {
            action.payload.callback(responseData);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateNews(action: DefaultAction<NewsUpdateRequestedPayload>) {
    try {
        const responseData: UpdateNewsResponse = (yield call(UPDATE_NEWS, action.payload.id,
            yield PrepareNewsToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData);
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteNews(action: DefaultAction<NewsDeleteRequestedPayload>) {
    try {
        yield call(DELETE_NEWS, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(createNotificationAction({ text: 'NEWS.SUCCESS_DELETE_NEWS'}));
    } catch (e) {
        yield put(createNotificationAction({ text: 'NEWS.FAILED_DELETE_NEWS'}));
        console.log(e);
    }
}

function* newsSaga() {
    yield takeLatest(NEWS_GET_REQUESTED, getNews);
    yield takeLatest(ONE_NEWS_GET_REQUESTED, getOneNews);
    yield takeLatest(NEWS_CREATE_REQUESTED, createNews);
    yield takeLatest(NEWS_UPDATE_REQUESTED, updateNews);
    yield takeLatest(NEWS_DELETE_REQUESTED, deleteNews);
}

export default newsSaga;
