import {AuthApi} from "./index";

export interface MetaBaseInterface {
    name: string;
    id: string;
    created_at: string;
    iframe_url: string;
    resource_id: number;
    resource_type: string;
}

export const GET_META_BASES = () => {
    return AuthApi.get<MetaBaseInterface[]>(`metabases`);
};

interface DashboardCreatorInterface {
    common_name: string;
    date_joined: string;
    email: string;
    first_name: string;
    last_login: string;
    last_name: string;
    id: number;
    is_qbnewb: boolean;
    is_superuser: boolean;
}

interface DashboardLastEditInfoInterface {
    email: string;
    first_name: string;
    last_name: string;
    timestamp: string;
    id: number;
}

interface DashboardParameterInterface {
    default: string;
    id: string;
    name: string;
    sectionId: string;
    slug: string;
    type: string;
}

export interface DashboardInterface {
    description: string;
    name: string;
    created_at: string;
    updated_at: string;
    id: number;
    creator_id: number;
    collection_position: number;
    archived: boolean;
    enable_embedding: boolean;
    favorite: boolean;
    show_in_getting_started: boolean;
    caveats: null;
    collection_id: null;
    embedding_params: null;
    made_public_by_id: null;
    points_of_interest: null;
    position: null;
    public_uuid: null;
    creator: DashboardCreatorInterface;
    'last-edit-info': DashboardLastEditInfoInterface;
    parameters: DashboardParameterInterface[];
}

export const GET_DASHBOARDS = () => {
    return AuthApi.get<DashboardInterface[]>(`metabases/dashboards`);
};

export interface CreateMetaBaseGraphConfigBody {
    resource_type: 'dashboard';
    name: string;
    resource_id: number;
}

export interface CreateMetaBaseGraphConfigResponse {
    success: boolean;
    model: MetaBaseInterface,
    errors: any;
}

export const CREATE_META_BASE_GRAPH_CONFIG = (body: CreateMetaBaseGraphConfigBody) => {
    return AuthApi.post<CreateMetaBaseGraphConfigResponse>(`metabases`, body);
};

export interface DestroyMetaBaseGraphResponse {
    success: boolean;
    model: MetaBaseInterface,
    errors: any;
}

export const DESTROY_META_BASE_GRAPH = (id: string) => {
    return AuthApi.delete<DestroyMetaBaseGraphResponse>(`metabases/${id}`);
};