import {LocalTestQuestionInterface} from "../../components/TestQuestion/constants";

export interface TestToUpdateInterface {
    id?: string;
    name?: string;
    description?: string;
    success_text?: string;
    fail_text?: string;
    right_answers_count?: string;
    answer_time?: string;
    show_right_answers?: boolean;
    show_hints?: boolean;
    published?: boolean;
    questions?: LocalTestQuestionInterface[];
    cover?: {
        file?: File;
        url?: string;
    }
}

export interface LocalTestInterface {
    id: string;
    name: string;
    description: string;
    success_text: string;
    fail_text: string;
    right_answers_count: string;
    answer_time: string;
    show_right_answers: boolean;
    show_hints: boolean;
    published: boolean;
    questions: LocalTestQuestionInterface[];
    cover: {
        file: File;
        url: string;
    }
}

export class LocalTestClass implements LocalTestInterface {
    constructor(data) {
        const {
            id = 0
        } = data;
        this.id = String(id);
    }

    id;
    name = '';
    description = '';
    success_text = '';
    fail_text = '';
    right_answers_count = '';
    answer_time = '';
    show_right_answers = false;
    show_hints = false;
    published = true;
    questions = [];
    cover = {
        file: null,
        url: '',
    };
}