import {PayloadWithCallback} from "../../interfaces/store";
import {
  LocalTestQuestionInterface,
  LocalTestQuestionToUpdateInterface,
  LocalTestVariantInterface
} from "../../components/TestQuestion/constants";

export const QUESTION_CREATE_REQUESTED = 'QUESTION_CREATE_REQUESTED';

export interface QuestionCreateRequestedPayload extends PayloadWithCallback {
  data: LocalTestQuestionInterface;
  testId: string;
}

export const questionCreateRequestedAction = (data: QuestionCreateRequestedPayload) => ({
  type: QUESTION_CREATE_REQUESTED,
  payload: data,
});

export const QUESTION_UPDATE_REQUESTED = 'QUESTION_UPDATE_REQUESTED';

export interface QuestionUpdateRequestedPayload extends PayloadWithCallback {
  data: LocalTestQuestionToUpdateInterface;
}

export const questionUpdateRequestedAction = (data: QuestionUpdateRequestedPayload) => ({
  type: QUESTION_UPDATE_REQUESTED,
  payload: data,
});

export const QUESTION_VARIANT_POSITIONS_UPDATE_REQUESTED = 'QUESTION_VARIANT_POSITIONS_UPDATE_REQUESTED';

export interface QuestionVariantPositionsUpdateRequestedPayload extends PayloadWithCallback {
  questionId: string;
  data: LocalTestVariantInterface[];
}

export const questionVariantPositionsUpdateRequestedAction = (data: QuestionVariantPositionsUpdateRequestedPayload) => ({
  type: QUESTION_VARIANT_POSITIONS_UPDATE_REQUESTED,
  payload: data,
});

export const QUESTION_DELETE_REQUESTED = 'QUESTION_DELETE_REQUESTED';

export interface QuestionDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const questionDeleteRequestedAction = (data: QuestionDeleteRequestedPayload) => ({
  type: QUESTION_DELETE_REQUESTED,
  payload: data,
});