import {PayloadWithCallback} from "../../interfaces/store";
import {
  LocalTestVariantInterface, LocalTestVariantToUpdateInterface
} from "../../components/TestQuestion/constants";

export const VARIANT_CREATE_REQUESTED = 'VARIANT_CREATE_REQUESTED';

export interface VariantCreateRequestedPayload extends PayloadWithCallback {
  data: LocalTestVariantInterface;
  questionId: string;
}

export const variantCreateRequestedAction = (data: VariantCreateRequestedPayload) => ({
  type: VARIANT_CREATE_REQUESTED,
  payload: data,
});

export const VARIANT_UPDATE_REQUESTED = 'VARIANT_UPDATE_REQUESTED';

export interface VariantUpdateRequestedPayload extends PayloadWithCallback {
  data: LocalTestVariantToUpdateInterface;
}

export const variantUpdateRequestedAction = (data: VariantUpdateRequestedPayload) => ({
  type: VARIANT_UPDATE_REQUESTED,
  payload: data,
});

export const VARIANT_DELETE_REQUESTED = 'VARIANT_DELETE_REQUESTED';

export interface VariantDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const variantDeleteRequestedAction = (data: VariantDeleteRequestedPayload) => ({
  type: VARIANT_DELETE_REQUESTED,
  payload: data,
});