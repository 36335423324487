import {
  CreateMetaBaseGraphConfigBody,
  DashboardInterface,
  MetaBaseInterface
} from "../../api/MetaBase";
import {PayloadWithCallback} from "../../interfaces/store";

export const ACTION_SET_META_BASES = 'ACTION_SET_META_BASES';

export const setMetaBasesAction = (data: MetaBaseInterface[]) => ({
  type: ACTION_SET_META_BASES,
  payload: data,
});

export const ACTION_SET_DASHBOARDS = 'ACTION_SET_DASHBOARDS';

export const setDashboardsAction = (data: DashboardInterface[]) => ({
  type: ACTION_SET_DASHBOARDS,
  payload: data,
});

export const META_BASES_GET_REQUESTED = 'META_BASES_GET_REQUESTED';

export const metaBasesGetRequestedAction = () => ({
  type: META_BASES_GET_REQUESTED,
});

export const DASHBOARDS_GET_REQUESTED = 'DASHBOARDS_GET_REQUESTED';

export const dashboardsGetRequestedAction = () => ({
  type: DASHBOARDS_GET_REQUESTED,
});

export const META_BASE_CREATE_REQUESTED = 'META_BASE_CREATE_REQUESTED';

export interface MetaBaseCreateRequestedPayload extends PayloadWithCallback {
  body: CreateMetaBaseGraphConfigBody;
}

export const metaBaseCreateRequestedAction = (data: MetaBaseCreateRequestedPayload) => ({
  type: META_BASE_CREATE_REQUESTED,
  payload: data,
});

export const META_BASE_DESTROY_REQUESTED = 'META_BASE_DESTROY_REQUESTED';

export interface MetaBaseDestroyRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const metaBaseDestroyRequestedAction = (data: MetaBaseDestroyRequestedPayload) => ({
  type: META_BASE_DESTROY_REQUESTED,
  payload: data,
});