import {
  ACTION_ADD_NOTIFICATION,
  ACTION_DELETE_NOTIFICATION,
  DeleteNotificationPayload,
} from './actions';
import {NotificationInterface} from "./interface";

interface NotificationReducerState {
  idCounter: number;
  notifications: NotificationInterface[];
}

const defaultState: NotificationReducerState = {
  idCounter: 1,
  notifications: [],
};

const notificationsReducer = (state: NotificationReducerState = defaultState, action) => {
  switch (action.type) {
    case ACTION_ADD_NOTIFICATION: {
      return addNotification(state, action.payload);
    }
    case ACTION_DELETE_NOTIFICATION: {
      return deleteNotification(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function addNotification(state, payload: NotificationInterface) {
  return {
    ...state,
    notifications: [...state.notifications, payload],
    idCounter: state.idCounter + 1,
  }
}

function deleteNotification(state, payload: DeleteNotificationPayload) {
  const notificationIndex: number = state.notifications.findIndex((item) => item.id === payload.id);
  const newNotifications: NotificationInterface[] = [...state.notifications];
  newNotifications.splice(notificationIndex, 1);

  return {
    ...state,
    notifications: newNotifications,
  }
}

export default notificationsReducer;