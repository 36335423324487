import {CreateTokenParams, MaintenanceInterface, TokensInterface} from "../../api/Auth";
import {EmptyObjectType} from "../../interfaces/default";

export const SET_MAINTENANCE = 'SET_MAINTENANCE';

export const setMaintenanceAction = (data: MaintenanceInterface) => ({
  type: SET_MAINTENANCE,
  payload: data,
});

export const ACTION_SET_TOKENS = 'ACTION_SET_TOKENS';

export const setTokens = (data: TokensInterface) => ({
  type: ACTION_SET_TOKENS,
  payload: data,
});

export const ACTION_SET_EMAIL: string = 'ACTION_SET_EMAIL';

export const setEmail = (email: string) => ({
  type: ACTION_SET_EMAIL,
  payload: email,
});

export const ACTION_REMOVE_TOKENS: string = 'ACTION_REMOVE_TOKENS';

export const removeTokensAction = () => ({
  type: ACTION_REMOVE_TOKENS,
});

export const TOKEN_CREATE_REQUESTED = 'TOKEN_CREATE_REQUESTED';

export const tokenCreateRequestedAction = (data: EmptyObjectType<CreateTokenParams> = {}) => ({
  type: TOKEN_CREATE_REQUESTED,
  payload: data,
});