import {NotificationInterface} from "./interface";
import {NOTIFICATION_TYPE} from "../../containers/Notifier/constants";

export interface CreateNotificationPayload {
  text: string;
  type?: NOTIFICATION_TYPE;
  delay?: number;
  preventDuplication?: boolean;
}

export const ACTION_CREATE_NOTIFICATION: string = 'ACTION_CREATE_NOTIFICATION';

export const createNotificationAction = (data: CreateNotificationPayload) => ({
  type: ACTION_CREATE_NOTIFICATION,
  payload: data,
});

export const ACTION_ADD_NOTIFICATION: string = 'ACTION_ADD_NOTIFICATION';

export const addNotificationAction = (data: NotificationInterface) => ({
  type: ACTION_ADD_NOTIFICATION,
  payload: data,
});

export interface DeleteNotificationPayload {
  id: number;
}

export const ACTION_DELETE_NOTIFICATION: string = 'ACTION_DELETE_NOTIFICATION';

export const deleteNotificationAction = (data: DeleteNotificationPayload) => ({
  type: ACTION_DELETE_NOTIFICATION,
  payload: data,
});