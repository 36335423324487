import {AuthApi} from "./index";

export interface DownloadFileParamsInterface {
  url: string;
  params: object;
}

export const DOWNLOAD_FILE_DATA = (url, params = {}) => {
  return AuthApi.get(url, {
    params: { ...params }
  })
}
