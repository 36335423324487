import {
  CourseFeedbackData,
  FeedbackCourseInterface,
  FeedbackCoursesDataInterface, GetCourseFeedbackParams,
  GetFeedbackCoursesParams
} from "../../api/Feedback";

export const ACTION_SET_FEEDBACK_COURSES = 'ACTION_SET_FEEDBACK_COURSES';

export const setFeedbackCoursesAction = (data: FeedbackCoursesDataInterface) => ({
  type: ACTION_SET_FEEDBACK_COURSES,
  payload: data,
});

export const ACTION_SET_FEEDBACK_COURSE = 'ACTION_SET_FEEDBACK_COURSE';

export const setFeedbackCourseAction = (data: FeedbackCourseInterface) => ({
  type: ACTION_SET_FEEDBACK_COURSE,
  payload: data,
});

export const ACTION_SET_COURSE_FEEDBACK = 'ACTION_SET_COURSE_FEEDBACK';

export const setCourseFeedbackAction = (data: CourseFeedbackData) => ({
  type: ACTION_SET_COURSE_FEEDBACK,
  payload: data,
});

export const FEEDBACK_COURSES_GET_REQUESTED = 'FEEDBACK_COURSES_GET_REQUESTED';

export interface FeedbackCoursesGetRequestedPayload {
  data: GetFeedbackCoursesParams;
  isSearch: boolean;
}

export const feedbackCoursesGetRequestedAction = (data: FeedbackCoursesGetRequestedPayload) => ({
  type: FEEDBACK_COURSES_GET_REQUESTED,
  payload: data,
});

export const FEEDBACK_COURSE_GET_REQUESTED = 'FEEDBACK_COURSE_GET_REQUESTED';

export interface FeedbackCourseGetRequestedPayload {
  id: string;
}

export const feedbackCourseGetRequestedAction = (data: FeedbackCourseGetRequestedPayload) => ({
  type: FEEDBACK_COURSE_GET_REQUESTED,
  payload: data,
});

export const COURSE_FEEDBACK_GET_REQUESTED = 'COURSE_FEEDBACK_GET_REQUESTED';

export interface CourseFeedbackGetRequestedPayload {
  id: string;
  params: GetCourseFeedbackParams;
}

export const courseFeedbackGetRequestedAction = (data: CourseFeedbackGetRequestedPayload) => ({
  type: COURSE_FEEDBACK_GET_REQUESTED,
  payload: data,
});