import { AuthRegistrationParams } from "../../api/AuthRegistration";
import { EmptyObjectType } from "../../interfaces/default";

export const ACTION_REGISTRATION = "ACTION_REGISTRATION";

export const registrationAction = (data: EmptyObjectType<AuthRegistrationParams> = {}) => ({
    type: ACTION_REGISTRATION,
    payload: data,
});

export const ACTION_SET_VALIDATION_ERRORS = 'ACTION_SET_VALIDATION_ERRORS'

export const setValidationErrorsAction = (errors = {}) => ({
    type: ACTION_SET_VALIDATION_ERRORS,
    payload: errors
})
