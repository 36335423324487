import {PayloadWithCallback} from "../../interfaces/store";
import {ConnectionInterface, ConnectionToUpdateInterface} from "../../containers/Screenplay/constants";

export interface TransitionCreateRequestedPayload extends PayloadWithCallback {
  data: ConnectionInterface;
}

export const TRANSITION_CREATE_REQUESTED = 'TRANSITION_CREATE_REQUESTED';

export const transitionCreateRequestedAction = (data: TransitionCreateRequestedPayload) => ({
  type: TRANSITION_CREATE_REQUESTED,
  payload: data,
});

export interface TransitionUpdateRequestedPayload {
  data: ConnectionToUpdateInterface;
}

export const TRANSITION_UPDATE_REQUESTED = 'TRANSITION_UPDATE_REQUESTED';

export const transitionUpdateRequestedAction = (data: TransitionUpdateRequestedPayload) => ({
  type: TRANSITION_UPDATE_REQUESTED,
  payload: data,
});

export interface TransitionDeleteRequestedPayload {
  id: string;
}

export const TRANSITION_DELETE_REQUESTED = 'TRANSITION_DELETE_REQUESTED';

export const transitionDeleteRequestedAction = (data: TransitionDeleteRequestedPayload) => ({
  type: TRANSITION_DELETE_REQUESTED,
  payload: data,
});