import {AuthApi} from "./index";
import {GlossaryCardInterface} from "../containers/Screenplay/constants";
import {LessonContentType} from "../components/Longread/constants";

const SECTIONS_API_URL: string = 'sections';

export interface SectionContentInterface {
    text?: string;
    cards?: GlossaryCardInterface[];
}

export interface SectionAttachmentInterface {
    id?: string;
    download_url?: string;
    display_name?: string;
    duration?: number
}

export interface SectionInterface {
    id: string;
    attachment: SectionAttachmentInterface;
    attachment_id: string;
    attachment_thumbnail_width: string;
    attachment_url: string;
    content: SectionContentInterface;
    dimensions: any;
    kind: LessonContentType;
    position: number;
    thumbnail_url: string
}

export interface CreateSectionResponse {
    success: boolean;
    model: SectionInterface,
    errors: any;
}

export const CREATE_SECTION = (data: FormData) => {
    return AuthApi.post<CreateSectionResponse>(SECTIONS_API_URL, data);
};

export interface UpdateSectionResponse {
    success: boolean;
    model: SectionInterface,
    errors: any;
}

export const UPDATE_SECTION = (id: string, data: FormData, ) => {
    return AuthApi.patch<UpdateSectionResponse>(`${SECTIONS_API_URL}/${id}`, data);
};

export interface DestroySectionResponse {
    success: boolean;
    model: SectionInterface,
    errors: any;
}

export const DELETE_SECTION = (id: string) => {
    return AuthApi.delete<DestroySectionResponse>(`${SECTIONS_API_URL}/${id}`);
};