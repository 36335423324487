import amplitude from 'amplitude-js';
import {AMPLITUDE_EVENT} from "../constants/amplitude";
import {BUILD_MODES} from "../constants/environment";

export interface AmplitudeEventDataInterface {
    [key: string]: string | number | string[];
}

export const InitAmplitude = (): void => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
};

export const SetAmplitudeUserId = (userId: string = null): void => {
    if (process.env.REACT_APP_BUILD_MODE === BUILD_MODES.PRODUCTION) {
        amplitude.getInstance().setUserId(userId);
    }
};

export const SetAmplitudeUserProperties = (data: AmplitudeEventDataInterface = null): void => {
    if (process.env.REACT_APP_BUILD_MODE === BUILD_MODES.PRODUCTION) {
        if (data) {
            amplitude.getInstance().setUserProperties(data);
        } else {
            amplitude.getInstance().clearUserProperties();
        }
    }
};

export const LogAmplitudeEvent = (event: AMPLITUDE_EVENT | string, data?: AmplitudeEventDataInterface): void => {
    console.warn("AMPLITUDE EVENT ---", event)
    console.warn("AMPLITUDE EVENT DATA ---", data)
    if (process.env.REACT_APP_BUILD_MODE === BUILD_MODES.PRODUCTION) {
        amplitude.getInstance().logEvent(event, data);
    }
};
