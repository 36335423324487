import {TestInterface} from "../../api/Tests";
import {PayloadWithCallback} from "../../interfaces/store";
import {LocalTestInterface, TestToUpdateInterface} from "../../containers/TestForm/constants";
import {LocalTestQuestionInterface} from "../../components/TestQuestion/constants";

export const ACTION_SET_TEST = 'ACTION_SET_TEST';

export const setTestAction = (data: TestInterface) => ({
  type: ACTION_SET_TEST,
  payload: data,
});

export const TEST_GET_REQUESTED = 'TEST_GET_REQUESTED';

export interface TestGetRequestedPayload {
  id: string;
}

export const testGetRequestedAction = (data: TestGetRequestedPayload) => ({
  type: TEST_GET_REQUESTED,
  payload: data,
});

export const TEST_CREATE_REQUESTED = 'TEST_CREATE_REQUESTED';

export interface TestCreateRequestedPayload extends PayloadWithCallback {
  data: LocalTestInterface;
  stepId: string;
}

export const testCreateRequestedAction = (data: TestCreateRequestedPayload) => ({
  type: TEST_CREATE_REQUESTED,
  payload: data,
});

export const TEST_UPDATE_REQUESTED = 'TEST_UPDATE_REQUESTED';

export interface TestUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: TestToUpdateInterface;
}

export const testUpdateRequestedAction = (data: TestUpdateRequestedPayload) => ({
  type: TEST_UPDATE_REQUESTED,
  payload: data,
});

export const TEST_QUESTION_POSITIONS_UPDATE_REQUESTED = 'TEST_QUESTION_POSITIONS_UPDATE_REQUESTED';

export interface TestQuestionPositionsUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: LocalTestQuestionInterface[];
}

export const testUpdateQuestionPositionsRequestedAction = (data: TestQuestionPositionsUpdateRequestedPayload) => ({
  type: TEST_QUESTION_POSITIONS_UPDATE_REQUESTED,
  payload: data,
});

export const TEST_DELETE_REQUESTED = 'TEST_DELETE_REQUESTED';

export interface TestDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const testDeleteRequestedAction = (data: TestDeleteRequestedPayload) => ({
  type: TEST_DELETE_REQUESTED,
  payload: data,
});