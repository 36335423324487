import TextIcon from './text-icon.svg';
import VideoIcon from './video-icon.svg';
import AudioIcon from './audio-icon.svg';
import ImageIcon from './image-icon.svg';
import TestIcon from './test-icon.svg';
import GlossaryIcon from './glossary-icon.svg';
import LongreadIcon from './longread-icon.svg';
import TextCanvasIcon from './text-canvas-icon.svg';
import VideoCanvasIcon from './video-canvas-icon.svg';
import AudioCanvasIcon from './audio-canvas-icon.svg';
import ImageCanvasIcon from './image-canvas-icon.svg';
import TestCanvasIcon from './test-canvas-icon.svg';
import GlossaryCanvasIcon from './glossary-canvas-icon.svg';
import LongreadCanvasIcon from './longread-canvas-icon.svg';
import {COLORS} from "../../constants/colors";
import {LongreadSectionInterface} from "../../components/Longread/constants";
import {LocalTestClass, LocalTestInterface} from "../TestForm/constants";
import {LANGUAGE_CODE} from "../../constants/localization";
import TutorialImage from './tutorial.png';
import RuTutorialImage from './tutorial-ru.png';
import {HintInterface} from '../../components/Hints/constants';

export const ScreenplayDataByLocale = (locale: string) => {
    let data;
    switch (locale) {
        case LANGUAGE_CODE.RU:
            data = {
                tutorialImage: RuTutorialImage,
                tutorialWidth: 835,
                tutorialHeight: 156,
            }
            break;
        case LANGUAGE_CODE.EN:
            data = {
                tutorialImage: TutorialImage,
                tutorialWidth: 845,
                tutorialHeight: 140,
            }
            break;
        default:
            data = {
                tutorialImage: TutorialImage,
                tutorialWidth: 845,
                tutorialHeight: 140,
            }
    }
    return data;
}

export const CONNECTION_HEIGHT: number = 24;
export const CONNECTION_TEXT_HEIGHT: number = 20;
export const CONNECTION_TEXT_HORIZONTAL_BORDER_WIDTH: number = 8;
export const CONNECTION_Y: number = 20;
export const CONNECTION_BACKGROUND_RADIUS: number = 12;
export const CONNECTION_BORDER_RADIUS: number = 8;
export const CONNECTION_CIRCLE_RADIUS: number = 4;
export const NEW_CONNECTION_LINE_HORIZONTAL_MARGIN: number = 8;
export const STEP_CONTROLS_HEIGHT: number = 32;
export const SCREENPLAY_DEFAULT_FONT: string = '14px PTRootUIWebMedium, serif';
export const STEP_TITLE_LINE_HEIGHT: number = 18;
export const STEP_TITLE_ONE_LINE_MAX_LENGTH: number = 16;

export const START_CARD_ICON_WIDTH: number = 20;
export const START_CARD_ICON_HEIGHT: number = 20;
export const START_CARD_ICON_MARGIN_LEFT: number = -12;
export const START_CARD_ICON_PADDING_LEFT: number = 8;
export const START_CARD_ICON_Y: number = 4;
export const START_CARD_ICON_X: number = START_CARD_ICON_MARGIN_LEFT - START_CARD_ICON_WIDTH;

export const START_CARD_BLOCK_WIDTH: number = 36;
export const START_CARD_BLOCK_HEIGHT: number = 28;
export const START_CARD_BLOCK_MARGIN_LEFT: number = -4;
export const START_CARD_BLOCK_X: number = START_CARD_BLOCK_MARGIN_LEFT - START_CARD_BLOCK_WIDTH;
export const START_CARD_BLOCK_RADIUS: number = 4;

export const INVALID_ICON_WIDTH: number = 20;
export const INVALID_ICON_HEIGHT: number = 20;

export const VALID_ICON_WIDTH: number = 20;
export const VALID_ICON_HEIGHT: number = 20;

export const TOOLTIP_HEIGHT: number = 36;
export const TOOLTIP_RADIUS: number = 4;
export const TOOLTIP_PADDING_TOP: number = 10;
export const TOOLTIP_PADDING_LEFT: number = 12;
export const TOOLTIP_TRIANGLE_WIDTH: number = 8;
export const TOOLTIP_TRIANGLE_HEIGHT: number = 6;

export const INVALID_TOOLTIP_MARGIN_TOP: number = -20;
export const INVALID_TOOLTIP_Y: number = INVALID_TOOLTIP_MARGIN_TOP - TOOLTIP_HEIGHT;

export const START_CARD_TOOLTIP_MARGIN_TOP: number = -10;
export const START_CARD_TOOLTIP_Y: number = START_CARD_TOOLTIP_MARGIN_TOP - TOOLTIP_HEIGHT;

export enum SCREENPLAY_TYPE {
    TEXT = 'text',
    VIDEO = 'video',
    AUDIO = 'audio',
    IMAGE = 'image',
    TEST = 'test',
    GLOSSARY = 'glossary',
    LONGREAD = 'longread',
}

export interface GlossaryCardInterface {
    id: string;
    title: string;
    text: string;
}

export class GlossaryCardClass implements GlossaryCardInterface {
    constructor(data) {
        const {
            id = 0
        } = data;
        this.id = String(id);
    }

    id;
    title = '';
    text = '';
}

export interface StepContentInterface {
    text?: string;
    fileId?: string;
    file?: File;
    filename?: string;
    fileUrl?: string;
    duration?: number;
    cards?: GlossaryCardInterface[];
    sections?: LongreadSectionInterface[];
    idCounter?: number;
    validationErrorKey?: string;
    test?: LocalTestInterface;
    hints?: HintInterface[];
}

export interface StepToPrepareInterface {
    id?: string;
    x?: number;
    y?: number;
    w?: number;
    h?: number;
    type?: SCREENPLAY_TYPE;
    content?: StepContentInterface;
    title?: string;
    copyX?: number;
    copyY?: number;
    trashX?: number;
    trashY?: number;
    hover?: boolean;
    active?: boolean
    prevConnectionHovered?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    isStartCardHover?: boolean;
    validationTooltipVisibility?: boolean;
    connections?: ConnectionInterface[];
    image?: HTMLImageElement;
    hints?: boolean;
}

export interface StepInterface {
    id: string;
    x: number;
    y: number;
    w?: number;
    h?: number;
    type: SCREENPLAY_TYPE;
    connections: ConnectionInterface[];
    content?: StepContentInterface;
    title?: string;
    copyX?: number;
    copyY?: number;
    trashX?: number;
    trashY?: number;
    hover?: boolean;
    active?: boolean;
    prevConnectionHovered?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    hints?: boolean;
    isStartCardHover?: boolean;
    validationTooltipVisibility?: boolean;
    image?: HTMLImageElement;
}

export class StepClass implements StepInterface {
    constructor(data) {
        const {
            id,
            type,
            connections,
            x, y,
            isFirst,
        } = data;
        this.id = id;
        this.type = type;
        if (connections) {
            this.connections = connections;
        }
        if (x) this.x = x;
        if (y) this.y = y;
        if (isFirst) {
            this.isFirst = isFirst;
        }
        if (type === SCREENPLAY_TYPE.GLOSSARY) {
            this.content.cards = [new GlossaryCardClass({})];
        }
    }

    id: string;
    x = 125;
    y = 75;
    w = 52;
    h = 52;
    title = '';
    isFirst = false;
    isLast = false;
    isStartCardHover = false;
    validationTooltipVisibility = false;
    content = {
        file: null,
        filename: '',
        fileUrl: '',
        text: '',
        validationErrorKey: '',
        cards: [],
        sections: [],
        test: new LocalTestClass({}),
    };
    connections: [];
    type;
    copyX;
    copyY;
    trashX;
    trashY;
    hover;
    active;
    prevConnectionHovered;
    image;
}

export enum CONNECTION_KIND {
    BUTTON = 'button',
    AUTO = 'auto',
}

export interface ConnectionInterface {
    position: number;
    kind: CONNECTION_KIND;
    start: {
        id: string;
        x?: number;
        y?: number;
        hover?: boolean;
    };
    end?: {
        id: string;
        x?: number;
        y?: number;
    };
    id?: string;
    text?: string;
    hover?: boolean;
    breakHovered?: boolean;
    breakConnectionX?: number;
    breakConnectionY?: number;
}

export interface ConnectionToUpdateInterface {
    position?: number;
    kind?: CONNECTION_KIND;
    start?: {
        x?: number;
        y?: number;
        id?: string;
    };
    end?: {
        x?: number;
        y?: number;
        id: string;
    };
    id?: string;
    text?: string;
    hover?: boolean;
    breakHovered?: boolean;
    breakConnectionX?: number;
    breakConnectionY?: number;
}

export class ConnectionClass implements ConnectionInterface {
    constructor(data) {
        const {id, position = 1, text = '', type, kind, start, end} = data;
        this.id = id;
        this.position = position;
        this.text = text;
        if (start) {
            this.start = start;
        }
        if (end) {
            this.end = end;
        }
        if (kind) {
            this.kind = kind
        } else if ([SCREENPLAY_TYPE.VIDEO, SCREENPLAY_TYPE.AUDIO].includes(type)) {
            this.kind = CONNECTION_KIND.AUTO;
        }
    }

    position = 1;
    kind = CONNECTION_KIND.BUTTON;
    text = '';
    id;
    start: {
        id;
    };
    end: {
        id;
    }
}

export interface ScreenplayDataByTypeInterface {
    w: number;
    h: number;
    icon: string;
    color: COLORS,
    lightColor: COLORS,
    text: string;
    titleVisibleWidth: number;
}

export type ScreenplayDataByTypeType = {
    [key in SCREENPLAY_TYPE]: ScreenplayDataByTypeInterface
};

export const ScreenplayDataByType: ScreenplayDataByTypeType = {
    [SCREENPLAY_TYPE.TEXT]: {
        w: 76,
        h: 64,
        icon: TextCanvasIcon,
        color: COLORS.ORANGE,
        lightColor: COLORS.ORANGE_LIGHT,
        text: 'SCREENPLAY.TEXT_LABEL',
        titleVisibleWidth: 45,
    },
    [SCREENPLAY_TYPE.VIDEO]: {
        w: 76,
        h: 64,
        icon: VideoCanvasIcon,
        color: COLORS.GREEN,
        lightColor: COLORS.GREEN_LIGHT,
        text: 'SCREENPLAY.VIDEO_LABEL',
        titleVisibleWidth: 140,
    },
    [SCREENPLAY_TYPE.AUDIO]: {
        w: 76,
        h: 64,
        icon: AudioCanvasIcon,
        color: COLORS.NAVY,
        lightColor: COLORS.NAVY_LIGHT,
        text: 'SCREENPLAY.AUDIO_LABEL',
        titleVisibleWidth: 51,
    },
    [SCREENPLAY_TYPE.IMAGE]: {
        w: 76,
        h: 64,
        icon: ImageCanvasIcon,
        color: COLORS.PURPLE,
        lightColor: COLORS.PURPLE_LIGHT,
        text: 'SCREENPLAY.IMAGE_LABEL',
        titleVisibleWidth: 108,
    },
    [SCREENPLAY_TYPE.TEST]: {
        w: 76,
        h: 64,
        icon: TestCanvasIcon,
        color: COLORS.LIME,
        lightColor: COLORS.LIME_LIGHT,
        text: 'SCREENPLAY.TEST_LABEL',
        titleVisibleWidth: 37,
    },
    [SCREENPLAY_TYPE.GLOSSARY]: {
        w: 76,
        h: 64,
        icon: GlossaryCanvasIcon,
        color: COLORS.PINK,
        lightColor: COLORS.PINK_LIGHT,
        text: 'SCREENPLAY.GLOSSARY_LABEL',
        titleVisibleWidth: 68,
    },
    [SCREENPLAY_TYPE.LONGREAD]: {
        w: 76,
        h: 64,
        icon: LongreadCanvasIcon,
        color: COLORS.BLUE,
        lightColor: COLORS.BLUE_LIGHT,
        text: 'SCREENPLAY.LONGREAD_LABEL',
        titleVisibleWidth: 69,
    },
};

export interface ScreenplayAddButtonInterface {
    text: string;
    icon: string;
    type: SCREENPLAY_TYPE;
}

export const ScreenplayAddButtons: ScreenplayAddButtonInterface[] = [
    {
        text: 'SCREENPLAY.TEXT_LABEL',
        icon: TextIcon,
        type: SCREENPLAY_TYPE.TEXT,
    },
    {
        text: 'SCREENPLAY.VIDEO_LABEL',
        icon: VideoIcon,
        type: SCREENPLAY_TYPE.VIDEO,
    },
    {
        text: 'SCREENPLAY.AUDIO_LABEL',
        icon: AudioIcon,
        type: SCREENPLAY_TYPE.AUDIO,
    },
    {
        text: 'SCREENPLAY.IMAGE_LABEL',
        icon: ImageIcon,
        type: SCREENPLAY_TYPE.IMAGE,
    },
    {
        text: 'SCREENPLAY.TEST_LABEL',
        icon: TestIcon,
        type: SCREENPLAY_TYPE.TEST,
    },
    {
        text: 'SCREENPLAY.GLOSSARY_LABEL',
        icon: GlossaryIcon,
        type: SCREENPLAY_TYPE.GLOSSARY,
    },
    {
        text: 'SCREENPLAY.LONGREAD_LABEL',
        icon: LongreadIcon,
        type: SCREENPLAY_TYPE.LONGREAD,
    },
];
