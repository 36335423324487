export const PAYMENTS_DO_PAYMENT_REQUESTED_ACTION: string = 'PAYMENTS_DO_PAYMENT_REQUESTED_ACTION';

export const paymentsDoPaymentRequestedAction = () => ({
    type: PAYMENTS_DO_PAYMENT_REQUESTED_ACTION,
});

export const PAYMENTS_CANCEL_PAYMENT_REQUESTED_ACTION: string = 'PAYMENTS_CANCEL_PAYMENT_REQUESTED_ACTION';

export const paymentsCancelPaymentRequestedAction = (pid) => ({
    payload: {
        pid,
    },
    type: PAYMENTS_CANCEL_PAYMENT_REQUESTED_ACTION,
});
