import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ACTION_GET_COMMON_PAGE_DATA_REQUESTED,
  ACTION_GET_USERS_ACTIVITY_DATA_REQUESTED,
  ACTION_GET_USER_ACTIVITY_DATA_REQUESTED,
  ACTION_GET_COURSES_REQUESTED,
  ACTION_GET_COURSE_ACTIVITY_DATA_REQUESTED,
  ACTION_GET_NEWS_DATA_REQUESTED,
  setCommonPageDataAction,
  setUsersActivityDataAction,
  setUserActivityDataAction,
  setCoursesDataAction,
  setCourseActivityDataAction,
  setNewsDataAction
} from '../dashboards/actions';

import {
  GET_COMMON_PAGE_DATA,
  GET_USERS_ACTIVITY_DATA,
  GET_USER_ACTIVITY_DATA,
  GET_COURSES_DATA,
  GET_COURSE_ACTIVITY_DATA,
  GET_NEWS_DATA
} from '../../api/Dashboards';

function* getCommonPageData(action) {
  try {
    const responseData = (yield call(GET_COMMON_PAGE_DATA, action.payload?.params)).data;
    yield put(setCommonPageDataAction(responseData));
    if (action?.payload?.callback) {
      action.payload.callback(responseData);
    }
  } catch(error) {
    console.log(error)
  }
}

function* getUsersActivityData(action) {
  try {
    const responseData = (yield call(GET_USERS_ACTIVITY_DATA, action.payload)).data;
    yield put(setUsersActivityDataAction(responseData));
  } catch(error) {
    console.log(error);
  }
}

function* getUserActivityData(action) {
  try {
    const responseData = (yield call(GET_USER_ACTIVITY_DATA, action.payload.id, action.payload.params)).data;
    yield put(setUserActivityDataAction(responseData));
  } catch (error) {
    console.log(error);
  }
}

function* getCoursesData(action) {
  try {
    const responseData = (yield call(GET_COURSES_DATA, action.payload)).data;
    yield put(setCoursesDataAction(responseData))
  } catch (error) {
    console.log(error);
  }
}

function* getCourseActivityData(action) {
  try {
    const responseData = (yield call(GET_COURSE_ACTIVITY_DATA, action.payload.id, action.payload.params)).data;
    yield put(setCourseActivityDataAction(responseData));
  } catch (error) {
    console.log(error);
  }
}

function* getNewsData(action) {
  try {
    const responseData = (yield call(GET_NEWS_DATA, action.payload)).data;
    yield put(setNewsDataAction(responseData));
  } catch (error) {
    console.log(error);
  }
}

function* dashboardsSaga() {
  yield takeLatest(ACTION_GET_COMMON_PAGE_DATA_REQUESTED, getCommonPageData);
  yield takeLatest(ACTION_GET_USERS_ACTIVITY_DATA_REQUESTED, getUsersActivityData);
  yield takeLatest(ACTION_GET_USER_ACTIVITY_DATA_REQUESTED, getUserActivityData);
  yield takeLatest(ACTION_GET_COURSES_REQUESTED, getCoursesData);
  yield takeLatest(ACTION_GET_COURSE_ACTIVITY_DATA_REQUESTED, getCourseActivityData);
  yield takeLatest(ACTION_GET_NEWS_DATA_REQUESTED, getNewsData);
}

export default dashboardsSaga;
