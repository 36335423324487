import { createApi } from 'unsplash-js';
import {ApiResponse} from "unsplash-js/dist/helpers/response";
import {Photos} from "unsplash-js/dist/methods/search/types/response";

const UnsplashApi = createApi({
    accessKey: 'fE2ZqgdCF4BvqNOaVMw5M8qN-u_hsKN4RIthETuC_cA',
    //...other fetch options
});

export interface UnsplashPhotoInterface {
    alt_description: string;
    blur_hash: string;
    categories: any[];
    color: string;
    created_at: string;
    current_user_collections: any[];
    description: string;
    height: number;
    id: string;
    liked_by_user: boolean;
    likes: number;
    links: {
        download: string;
        download_location: string;
        html: string;
        self: string;
    }
    promoted_at: string;
    sponsorship: string;
    tags: any[];
    topic_submissions: {};
    updated_at: string;
    urls: {
        full: string;
        raw: string;
        regular: string;
        small: string;
        small_s3: string;
        thumb: string;
    };
    width: number;
}

export interface UnsplashGetPhotosResponse {
    results: UnsplashPhotoInterface;
    total: number;
    total_pages: number;
}

export interface UnsplashGetPhotosParams {
    query: string;
    page: number;
    perPage: number;
}

export const GET_PHOTOS = (params: UnsplashGetPhotosParams): Promise<ApiResponse<Photos>> => {
    return UnsplashApi.search.getPhotos({
        ...params,
    })
};