import {AuthApi} from './index';

const BILLING_API_URL: string = 'billing';

type BILLING_PLAN_TAG = 'promo' | 'pro' | 'starter';

interface BillingAccountInterface {
    amount_cents: number;
}

interface BillingSubscriptionPeriodInterface {
    since: number;
    till: number;
    used_amount_cents: number;
    active_users_count: number;
}

interface BillingPlanInterface {
    tag: BILLING_PLAN_TAG;
    amount_cents_per_user: number;
    limit_users_count: number;
}

export interface BillingSubscribeBodyInterface {
    plan_tag: BILLING_PLAN_TAG;
}

export interface BillingInfoInterface {
    account: BillingAccountInterface;
    current_subscription_period: BillingSubscriptionPeriodInterface;
    plan: BillingPlanInterface;
    tenant_name: string;
    total_users_count: number;
}

export const GET_BILLING_INFO = () => {
    return AuthApi.get<BillingInfoInterface>(`${BILLING_API_URL}/info`);
};

export const SUBSCRIBE_BILLING = (body: BillingSubscribeBodyInterface) => {
    return AuthApi.post(`${BILLING_API_URL}/subscribe`, body);
};
