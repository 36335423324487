import {ConnectionInterface, StepInterface, StepToPrepareInterface} from "../../containers/Screenplay/constants";
import {PayloadWithCallback} from "../../interfaces/store";
import {CopyStepRequestData} from "../../api/Steps";
import {LocalTestQuestionInterface} from "../../components/TestQuestion/constants";
import {LongreadSectionInterface} from "../../components/Longread/constants";

export interface StepCreateRequestedPayload extends PayloadWithCallback {
  lessonId: string;
  data: StepInterface;
}

export const STEP_CREATE_REQUESTED = 'STEP_CREATE_REQUESTED';

export const stepCreateRequestedAction = (data: StepCreateRequestedPayload) => ({
  type: STEP_CREATE_REQUESTED,
  payload: data,
});

export interface StepUpdateRequestedPayload extends PayloadWithCallback {
  data: StepToPrepareInterface;
}

export const STEP_UPDATE_REQUESTED = 'STEP_UPDATE_REQUESTED';

export const stepUpdateRequestedAction = (data: StepUpdateRequestedPayload) => ({
  type: STEP_UPDATE_REQUESTED,
  payload: data,
});

export interface StepUpdateTransitionsPositionRequestedPayload extends PayloadWithCallback {
  id: string;
  data: ConnectionInterface[];
}

export const STEP_UPDATE_TRANSITIONS_POSITION_REQUESTED = 'STEP_UPDATE_TRANSITIONS_POSITION_REQUESTED';

export const stepUpdateTransitionsPositionRequestedAction = (data: StepUpdateTransitionsPositionRequestedPayload) => ({
  type: STEP_UPDATE_TRANSITIONS_POSITION_REQUESTED,
  payload: data,
});


export const STEP_SECTION_POSITIONS_UPDATE_REQUESTED = 'STEP_SECTION_POSITIONS_UPDATE_REQUESTED';

export interface StepSectionPositionsUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: LongreadSectionInterface[];
}

export const stepUpdateSectionPositionsRequestedAction = (data: StepSectionPositionsUpdateRequestedPayload) => ({
  type: STEP_SECTION_POSITIONS_UPDATE_REQUESTED,
  payload: data,
});

export const STEP_COPY_REQUESTED = 'STEP_COPY_REQUESTED';

export interface StepCopyRequestedPayload extends PayloadWithCallback {
  id: string;
  data: CopyStepRequestData;
}

export const stepCopyRequestedAction = (data: StepCopyRequestedPayload) => ({
  type: STEP_COPY_REQUESTED,
  payload: data,
});

export interface StepDeleteRequestedPayload {
  id: string;
  type?: string
}

export const STEP_DELETE_REQUESTED = 'STEP_DELETE_REQUESTED';

export const stepDeleteRequestedAction = (data: StepDeleteRequestedPayload) => ({
  type: STEP_DELETE_REQUESTED,
  payload: data,
});
