import { call, put, takeLatest } from "redux-saga/effects";

import { TokensInterface } from "../../api/Auth";
import { REGISTRATION_API } from "../../api/AuthRegistration";
import { AMPLITUDE_EVENT } from "../../constants/amplitude";
import { ROUTE_AUTH_REGISTRATION, ROUTE_STANDBY } from "../../constants/routes";
import { LogAmplitudeEvent, SetAmplitudeUserId } from "../../utils/amplitude";
import { setEmail, setMaintenanceAction, setTokens } from "../auth/actions";
import { ACTION_REGISTRATION } from "../authRegistration/actions";
import { history } from "../configureStore";
import { createNotificationAction } from "../notifications/actions";
import { NOTIFICATION_TYPE } from "../../containers/Notifier/constants";
import { setValidationErrorsAction } from "../authRegistration/actions";

export function* registration(action) {
    const display_name: string = action.payload.display_name;
    const locale: string = action.payload.locale;
    const email: string = action.payload.email;
    const password: string = action.payload.password;
    const name: string = action.payload.name;

    try {
        const responseData: TokensInterface = (yield call(REGISTRATION_API, display_name, locale, email, password, name)).data;

        yield put(setEmail(action.payload.email));
        yield put(setTokens(responseData));

        SetAmplitudeUserId(email);
        LogAmplitudeEvent(AMPLITUDE_EVENT.LOGGED_IN_SUCCESS);
    } catch (error) {
        const isUnauthorizedError: Boolean = error.response.status === 401;
        const isServiceModeError: Boolean = error.response.status === 521;
        const isUnprocessableEntity: Boolean = error.response.status === 422;

        if (window.location.pathname === ROUTE_STANDBY) {
            if (isServiceModeError) {
                yield put(setMaintenanceAction(error.response.data));
            } else if (!display_name && !locale && !email && !password && !name) {
                yield call(history.push, ROUTE_AUTH_REGISTRATION);
            }
        } else if (isServiceModeError) {
            yield put(setMaintenanceAction(error.response.data));
            yield call(history.push, ROUTE_STANDBY);
        } else if (isUnauthorizedError) {
            yield put(createNotificationAction({
                text: "AUTH.FAILED_LOGIN_NOTIFICATION_401",
                preventDuplication: true,
            }));
        } else if (isUnprocessableEntity) {
            yield put(createNotificationAction({
                text: "AUTH.FAILED_REGISTRATION_NOTIFICATION_422",
                preventDuplication: true,
                type: NOTIFICATION_TYPE.ERROR,
            }));
            yield put(setValidationErrorsAction({ email: true }))
        } else {
            console.error(error);
        }

        if (display_name && locale && email && password && name) {
            LogAmplitudeEvent(AMPLITUDE_EVENT.LOGGED_IN_FAIL, {
                error_type: isUnauthorizedError ? error.response.data : error.toString(),
            });
        }
    }
}

function* authRegistrationSaga() {
    yield takeLatest(ACTION_REGISTRATION, registration);
}

export default authRegistrationSaga;
