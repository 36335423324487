import { call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    VARIANT_CREATE_REQUESTED,
    VARIANT_DELETE_REQUESTED,
    VARIANT_UPDATE_REQUESTED,
    VariantCreateRequestedPayload,
    VariantDeleteRequestedPayload,
    VariantUpdateRequestedPayload,
} from "./actions";
import {PrepareVariantToUpload} from "./helpers";
import {CREATE_VARIANT, DELETE_VARIANT, UPDATE_VARIANT} from "../../api/TestVariants";
import {DefaultAction} from "../interface";

function* createVariant(action: DefaultAction<VariantCreateRequestedPayload>) {
    try {
        const responseData = (yield call(CREATE_VARIANT,
            PrepareVariantToUpload(action.payload.data, action.payload.questionId))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateVariant(action: DefaultAction<VariantUpdateRequestedPayload>) {
    try {
        const responseData = (yield call(UPDATE_VARIANT, action.payload.data.id,
            PrepareVariantToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteVariant(action: DefaultAction<VariantDeleteRequestedPayload>) {
    try {
        yield call(DELETE_VARIANT, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* testVariantsSaga() {
    yield takeEvery(VARIANT_CREATE_REQUESTED, createVariant);
    yield takeLatest(VARIANT_UPDATE_REQUESTED, updateVariant);
    yield takeLatest(VARIANT_DELETE_REQUESTED, deleteVariant);
}

export default testVariantsSaga;