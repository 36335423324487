import {call, put, takeLatest} from 'redux-saga/effects';
import {DefaultAction} from "../interface";
import {
    STEP_COPY_REQUESTED,
    STEP_CREATE_REQUESTED,
    STEP_DELETE_REQUESTED, STEP_SECTION_POSITIONS_UPDATE_REQUESTED,
    STEP_UPDATE_REQUESTED,
    STEP_UPDATE_TRANSITIONS_POSITION_REQUESTED,
    StepCopyRequestedPayload,
    StepCreateRequestedPayload,
    StepDeleteRequestedPayload, StepSectionPositionsUpdateRequestedPayload,
    StepUpdateRequestedPayload,
    StepUpdateTransitionsPositionRequestedPayload
} from "./actions";
import {
    COPY_STEP,
    CREATE_STEP,
    CreateStepResponse,
    DELETE_STEP, StepInterface,
    UPDATE_STEP, UPDATE_STEP_SECTIONS_POSITION,
    UPDATE_STEP_TRANSITIONS_POSITION,
    UpdateStepResponse
} from "../../api/Steps";
import {
    PrepareStepSectionPositionsToUpload,
    PrepareStepToUpload,
    PrepareStepTransitionsPositionToUpload
} from "./helpers";
import {UPDATE_TEST} from "../../api/Tests";
import {PrepareTestQuestionPositionsToUpload} from "../tests/helpers";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";

function* createStep(action: DefaultAction<StepCreateRequestedPayload>) {
    try {
        const responseData: CreateStepResponse = (yield call(CREATE_STEP,
            PrepareStepToUpload(action.payload.data, action.payload.lessonId))).data;
        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateStep(action: DefaultAction<StepUpdateRequestedPayload>) {
    try {
        const responseData: UpdateStepResponse = (yield call(UPDATE_STEP, action.payload.data.id,
            PrepareStepToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateStepTransitionsPosition(action: DefaultAction<StepUpdateTransitionsPositionRequestedPayload>) {
    try {
        const responseData: UpdateStepResponse = (yield call(UPDATE_STEP_TRANSITIONS_POSITION, action.payload.id,
            PrepareStepTransitionsPositionToUpload(action.payload.data))).data;

    } catch (e) {
        console.log(e);
    }
}

function* updateStepSectionPositions(action: DefaultAction<StepSectionPositionsUpdateRequestedPayload>) {
    try {
        const responseData = (yield call(UPDATE_STEP_SECTIONS_POSITION, action.payload.id,
            PrepareStepSectionPositionsToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* copyStep(action: DefaultAction<StepCopyRequestedPayload>) {
    try {
        const step: StepInterface = (yield call(COPY_STEP, action.payload.id, action.payload.data)).data.model;
        if (action.payload.callback) {
            action.payload.callback({
                step,
            });
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteStep(action: DefaultAction<StepDeleteRequestedPayload>) {
    try {
        yield call(DELETE_STEP, action.payload.id);
        LogAmplitudeEvent(AMPLITUDE_EVENT.SCREENPLAY_STEP_DELETE, {
          step_id: action.payload.id,
          step_type: action.payload.type
        })
    } catch (e) {
        console.log(e);
    }
}

function* stepsSaga() {
    yield takeLatest(STEP_CREATE_REQUESTED, createStep);
    yield takeLatest(STEP_UPDATE_REQUESTED, updateStep);
    yield takeLatest(STEP_UPDATE_TRANSITIONS_POSITION_REQUESTED, updateStepTransitionsPosition);
    yield takeLatest(STEP_SECTION_POSITIONS_UPDATE_REQUESTED, updateStepSectionPositions);
    yield takeLatest(STEP_COPY_REQUESTED, copyStep);
    yield takeLatest(STEP_DELETE_REQUESTED, deleteStep);
}

export default stepsSaga;
