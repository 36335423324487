import {AddPositionBody, GetPositionsParams, PositionInterface, UpdatePositionBody} from "../../api/Positions";
import {PayloadWithCallback} from "../../interfaces/store";
import {PaginationMetaInterface} from "../../api/interface";
import {GetCitiesParams} from "../../api/Cities";

export const ACTION_SET_POSITIONS = 'ACTION_SET_POSITIONS';

export const setPositionsAction = (data: PositionInterface[]) => ({
  type: ACTION_SET_POSITIONS,
  payload: data,
});

export const ACTION_SET_POSITIONS_META = 'ACTION_SET_POSITIONS_META';

export const setPositionsMetaAction = (data: PaginationMetaInterface) => ({
  type: ACTION_SET_POSITIONS_META,
  payload: data,
});

export const ACTION_SET_POSITION = 'ACTION_SET_POSITION';

export const setPositionAction = (data: PositionInterface) => ({
  type: ACTION_SET_POSITION,
  payload: data,
});

export const POSITIONS_GET_REQUESTED = 'POSITIONS_GET_REQUESTED';

export interface PositionsGetRequestedPayload {
  data?: GetPositionsParams;
  isSearch?: boolean;
}

export const positionsGetRequestedAction = (data: PositionsGetRequestedPayload = {}) => ({
  type: POSITIONS_GET_REQUESTED,
  payload: data,
});

export const POSITION_GET_REQUESTED = 'POSITION_GET_REQUESTED';

export interface PositionGetRequestedPayload {
  id: string;
}

export const positionGetRequestedAction = (data: PositionGetRequestedPayload) => ({
  type: POSITION_GET_REQUESTED,
  payload: data,
});

export const POSITION_ADD_REQUESTED = 'POSITION_ADD_REQUESTED';

export interface PositionAddRequestedPayload extends PayloadWithCallback {
  body: AddPositionBody;
}

export const positionAddRequestedAction = (data: PositionAddRequestedPayload) => ({
  type: POSITION_ADD_REQUESTED,
  payload: data,
});

export const POSITION_UPDATE_REQUESTED = 'POSITION_UPDATE_REQUESTED';

export interface PositionUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  body: UpdatePositionBody;
}

export const positionUpdateRequestedAction = (data: PositionUpdateRequestedPayload) => ({
  type: POSITION_UPDATE_REQUESTED,
  payload: data,
});

export const POSITION_DELETE_REQUESTED = 'POSITION_DELETE_REQUESTED';

export interface PositionDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const positionDeleteRequestedAction = (data: PositionDeleteRequestedPayload) => ({
  type: POSITION_DELETE_REQUESTED,
  payload: data,
});