import {createBrowserHistory, History} from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router'
import rootSaga from './sagas';
import rootReducer from './reducers';

export const history: History = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
    const store = createStore(
        rootReducer(history),
        compose(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
            ),
        ),
    );
    sagaMiddleware.run(rootSaga);

    return store;
}
