import { call, put, takeLatest, } from 'redux-saga/effects';
import {
    COURSE_FEEDBACK_GET_REQUESTED,
    FEEDBACK_COURSE_GET_REQUESTED,
    FEEDBACK_COURSES_GET_REQUESTED, FeedbackCoursesGetRequestedPayload,
    setCourseFeedbackAction,
    setFeedbackCourseAction,
    setFeedbackCoursesAction,
} from "../feedback/actions";
import {
    GET_COURSE_FEEDBACK,
    GET_FEEDBACK_COURSE,
    GET_FEEDBACK_COURSES,
    CourseFeedbackData,
    FeedbackCourseInterface,
    FeedbackCoursesDataInterface, GetFeedbackCoursesParams,
} from "../../api/Feedback";
import {DefaultAction} from "../interface";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";

function* getFeedbackCourses(action: DefaultAction<FeedbackCoursesGetRequestedPayload>) {
    try {
        const params: GetFeedbackCoursesParams = action.payload.data;
        const responseData: FeedbackCoursesDataInterface = (yield call(GET_FEEDBACK_COURSES, params)).data;
        if (action.payload.isSearch) {
            LogAmplitudeEvent(params.published
                    ? AMPLITUDE_EVENT.PUBLISHED_FEEDBACK_SEARCH
                    : AMPLITUDE_EVENT.ARCHIVED_FEEDBACK_SEARCH, {
                keywords: params.name,
                number_of_results_returned: responseData.meta.total,
            });
        }
        yield put(setFeedbackCoursesAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* getFeedbackCourse(action) {
    try {
        const responseData: FeedbackCourseInterface = (yield call(GET_FEEDBACK_COURSE, action.payload.id)).data;
        yield put(setFeedbackCourseAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* getCourseFeedback(action) {
    try {
        const responseData: CourseFeedbackData = (yield call(GET_COURSE_FEEDBACK, action.payload.id, action.payload.params)).data;
        yield put(setCourseFeedbackAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* feedbackSaga() {
    yield takeLatest(FEEDBACK_COURSES_GET_REQUESTED, getFeedbackCourses);
    yield takeLatest(FEEDBACK_COURSE_GET_REQUESTED, getFeedbackCourse);
    yield takeLatest(COURSE_FEEDBACK_GET_REQUESTED, getCourseFeedback);
}

export default feedbackSaga;