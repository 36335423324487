import {AuthApi} from './index';

const PAYMENTS_API_URL: string = 'payments';

export const DO_PAYMENT = () => {
    return AuthApi.post(`${PAYMENTS_API_URL}`);
};

export const CANCEL_PAYMENT = (pid: string) => {
    return AuthApi.post(`${PAYMENTS_API_URL}/${pid}/cancel`);
}
