import {
  ACTION_RESET_LESSONS,
  ACTION_SET_LESSON, SetLessonPayload
} from './actions';
import {PrepareStepsToDisplay} from "../steps/helpers";
import {StepInterface as LocalStepInterface} from "../../containers/Screenplay/constants";
import {StepInterface} from "../../api/Steps";
import {PrepareLessonToDisplay} from "./helpers";

interface StateInterface {
  lesson: any;
  steps: LocalStepInterface[];
  tests: any[];
}

const defaultState: StateInterface = {
  lesson: {
    name: '',
    description: '',
    steps: [],
  },
  steps: [],
  tests: [],
};

const lessonsReducer = (state: StateInterface = defaultState, action) => {
  switch (action.type) {
    case ACTION_SET_LESSON: {
      return setLesson(state, action.payload);
    }
    case ACTION_RESET_LESSONS: {
      return resetLessons();
    }
    default: {
      return state;
    }
  }
};

function setLesson(state, payload: SetLessonPayload) {
  return {
    ...state,
    lesson: PrepareLessonToDisplay(payload.lesson),
    steps: PrepareStepsToDisplay(payload.lesson.steps),
  }
}

function resetLessons() {
  return {
    ...defaultState,
  }
}

export default lessonsReducer;