import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import configureStore, {history} from './store/configureStore';
import {BUILD_MODES} from "./constants/environment";
import {INTERCOM_SETTINGS} from "./constants/intercom"
import './i18n';
import './index.scss';
import Loader from "./components/Loader";
import {InitAmplitude} from "./utils/amplitude";
import {ConnectedRouter} from "connected-react-router";
import { IntercomProvider, useIntercom } from 'react-use-intercom';


const App = React.lazy(() => import('./App'));
if (process.env.REACT_APP_BUILD_MODE === BUILD_MODES.PRODUCTION) {
    Sentry.init({
        dsn: "https://0cafa754e9894240ad6a7cc822754392@sentry.vmb.co/7",
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
    InitAmplitude();
}

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Loader isFullscreen/>}>
            <ConnectedRouter history={history}>
                <IntercomProvider appId={INTERCOM_SETTINGS.APP_ID} autoBoot={true}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </IntercomProvider>
            </ConnectedRouter>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

if (process.env.REACT_APP_BUILD_MODE === BUILD_MODES.DEVELOP) {
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
