import { call, put, takeLatest } from 'redux-saga/effects';
import {
    POSITION_ADD_REQUESTED,
    POSITION_DELETE_REQUESTED,
    POSITION_GET_REQUESTED,
    POSITION_UPDATE_REQUESTED,
    POSITIONS_GET_REQUESTED, PositionsGetRequestedPayload,
    setPositionAction,
    setPositionsAction, setPositionsMetaAction,
} from "../positions/actions";
import {
    ADD_POSITION,
    DELETE_POSITION,
    GET_POSITION,
    GET_POSITIONS, GetPositionsDataInterface, GetPositionsParams, PositionInterface,
    UPDATE_POSITION,
} from "../../api/Positions";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {DefaultAction} from "../interface";

function* getPositions(action: DefaultAction<PositionsGetRequestedPayload>) {
    try {
        const params: GetPositionsParams = action.payload.data;
        const responseData: GetPositionsDataInterface = (yield call(GET_POSITIONS, params)).data;
        yield put(setPositionsAction(responseData.positions));
        if (params && params.paginate) {
            yield put(setPositionsMetaAction(responseData.meta));
            if (action.payload.isSearch) {
                LogAmplitudeEvent(AMPLITUDE_EVENT.SETTINGS_POSITION_SEARCH, {
                    keywords: params.display_name,
                    numbers_of_results_returned: responseData.meta.total,
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* getPosition(action) {
    try {
        const responseData: PositionInterface = (yield call(GET_POSITION, action.payload.id)).data;
        yield put(setPositionAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* addPosition(action) {
    try {
        yield call(ADD_POSITION, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        const isError406 = error.response.status === 406;
        if (isError406) {
            yield put(createNotificationAction({ text: 'SETTINGS.POSITION_FAILED_ADD_NOTIFICATION_406'}));
        }
        LogAmplitudeEvent(AMPLITUDE_EVENT.SETTINGS_POSITION_CREATE_FAIL, {
            error_type: isError406 ? error.response.data : error.toString(),
        });
    }
}

function* updatePosition(action) {
    try {
        yield call(UPDATE_POSITION, action.payload.id, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        if (error.response.status === 406) {
            yield put(createNotificationAction({ text: 'SETTINGS.POSITION_FAILED_UPDATE_NOTIFICATION_406'}));
        }
    }
}

function* deletePosition(action) {
    try {
        yield call(DELETE_POSITION, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* positionsSaga() {
    yield takeLatest(POSITIONS_GET_REQUESTED, getPositions);
    yield takeLatest(POSITION_GET_REQUESTED, getPosition);
    yield takeLatest(POSITION_ADD_REQUESTED, addPosition);
    yield takeLatest(POSITION_UPDATE_REQUESTED, updatePosition);
    yield takeLatest(POSITION_DELETE_REQUESTED, deletePosition);
}

export default positionsSaga;