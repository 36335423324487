import { call, put, takeLatest } from 'redux-saga/effects';
import {
    DASHBOARDS_GET_REQUESTED, META_BASE_CREATE_REQUESTED, META_BASE_DESTROY_REQUESTED,
    META_BASES_GET_REQUESTED, MetaBaseCreateRequestedPayload, MetaBaseDestroyRequestedPayload,
    setDashboardsAction,
    setMetaBasesAction
} from "../metaBase/actions";
import {
    CREATE_META_BASE_GRAPH_CONFIG, CreateMetaBaseGraphConfigResponse,
    DashboardInterface, DESTROY_META_BASE_GRAPH, DestroyMetaBaseGraphResponse,
    GET_DASHBOARDS,
    GET_META_BASES,
    MetaBaseInterface
} from "../../api/MetaBase";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {DefaultAction} from "../interface";

function* getMetaBases() {
    try {
        const responseData: MetaBaseInterface[] = (yield call(GET_META_BASES)).data;
        yield put(setMetaBasesAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* getDashboards() {
    try {
        const responseData: DashboardInterface[] = (yield call(GET_DASHBOARDS)).data;
        if (Array.isArray(responseData)) {
            yield put(setDashboardsAction(responseData));
        }
    } catch (e) {
        console.log(e);
    }
}

function* createMetaBaseConfig(action: DefaultAction<MetaBaseCreateRequestedPayload>) {
    try {
        const responseData: CreateMetaBaseGraphConfigResponse = (yield call(CREATE_META_BASE_GRAPH_CONFIG, action.payload.body)).data;
        LogAmplitudeEvent(AMPLITUDE_EVENT.DASHBOARD_ADD_FINISHED, {
            dashboard_name: responseData.model.name,
        });

        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(createNotificationAction({ text: 'DASHBOARD.SUCCESS_CREATE_NOTIFICATION'}));
    } catch (error) {
        yield put(createNotificationAction({ text: 'DASHBOARD.FAILED_CREATE_NOTIFICATION'}));
        LogAmplitudeEvent(AMPLITUDE_EVENT.DASHBOARD_ADD_FAIL, {
            error_type: error.toString(),
        });
        console.log(error);
    }
}

function* destroyMetaBaseConfig(action: DefaultAction<MetaBaseDestroyRequestedPayload>) {
    try {
        const responseData: DestroyMetaBaseGraphResponse = (yield call(DESTROY_META_BASE_GRAPH, action.payload.id)).data;
        LogAmplitudeEvent(AMPLITUDE_EVENT.DASHBOARD_DELETE_FINISHED, {
            dashboard_name: responseData.model.name,
        });

        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(createNotificationAction({ text: 'DASHBOARD.SUCCESS_DELETE_NOTIFICATION'}));
    } catch (e) {
        yield put(createNotificationAction({ text: 'DASHBOARD.FAILED_DELETE_NOTIFICATION'}));
        console.log(e);
    }
}

function* metaBaseSaga() {
    yield takeLatest(META_BASES_GET_REQUESTED, getMetaBases);
    yield takeLatest(META_BASE_DESTROY_REQUESTED, destroyMetaBaseConfig);
    yield takeLatest(DASHBOARDS_GET_REQUESTED, getDashboards);
    yield takeLatest(META_BASE_CREATE_REQUESTED, createMetaBaseConfig);
}

export default metaBaseSaga;