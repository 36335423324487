import {LocalTestInterface, TestToUpdateInterface} from "../../containers/TestForm/constants";
import {TestInterface} from "../../api/Tests";
import {PrepareQuestionToDisplay} from "../questions/helpers";
import {LocalTestQuestionInterface} from "../../components/TestQuestion/constants";

export const PrepareTestToDisplay = (test: TestInterface): LocalTestInterface => {
    return {
        id: test.id,
        name: test.name,
        description: test.description,
        success_text: test.success_text,
        fail_text: test.fail_text,
        right_answers_count: typeof test.right_answers_count === 'number' ? String(test.right_answers_count) : '',
        answer_time: typeof test.answer_time === 'number' ? String(test.answer_time) : '',
        show_right_answers: test.show_right_answers,
        show_hints: test.show_hints,
        published: test.published,
        questions: test.questions.map(PrepareQuestionToDisplay),
        cover: {
            file: null,
            url: test.cover_url,
        },
    }
};

export const PrepareTestQuestionPositionsToUpload = (questions: LocalTestQuestionInterface[]): FormData => {
    const formData = new FormData();
    questions.forEach((question, index) => {
        formData.append('questions_attributes[][id]', question.id);
        formData.append('questions_attributes[][position]', JSON.stringify(index + 1));
    });
    return formData;
};

export const PrepareTestToUpload = (test: LocalTestInterface | TestToUpdateInterface, stepId?: string): FormData => {
    const formData: FormData = new FormData();
    if (stepId) {
        formData.append('step_id', stepId);
    }
    if (test.hasOwnProperty('name')) {
        formData.append('name', test.name);
    }
    if (test.hasOwnProperty('description')) {
        formData.append('description', test.description);
    }
    if (test.hasOwnProperty('success_text')) {
        formData.append('success_text', test.success_text);
    }
    if (test.hasOwnProperty('fail_text')) {
        formData.append('fail_text', test.fail_text);
    }
    if (test.hasOwnProperty('right_answers_count')) {
        formData.append('right_answers_count', test.right_answers_count);
    }
    if (test.hasOwnProperty('show_right_answers')) {
        formData.append('show_right_answers', JSON.stringify(test.show_right_answers));
    }
    if (test.hasOwnProperty('show_hints')) {
        formData.append('show_hints', JSON.stringify(test.show_hints));
    }
    if (test.hasOwnProperty('answer_time')) {
        formData.append('answer_time', test.answer_time);
    }
    if (test.cover?.hasOwnProperty('file')) {
        formData.append('cover_attributes[file]', test.cover.file);
    }

    return formData;
};