import { call, put, takeLatest } from 'redux-saga/effects';
import {GET_COLORS, ColorInterface} from "../../api/Colors";
import {COLORS_GET_REQUESTED, setColorsAction} from "../colors/actions";

function* getColors() {
    try {
        const responseData: ColorInterface[] = (yield call(GET_COLORS)).data;
        yield put(setColorsAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* colorsSaga() {
    yield takeLatest(COLORS_GET_REQUESTED, getColors);
}

export default colorsSaga;