import {
    LocalTestVariantInterface, LocalTestVariantToUpdateInterface
} from "../../components/TestQuestion/constants";
import {TestVariantInterface} from "../../api/TestVariants";

export const PrepareVariantToDisplay = (variant: TestVariantInterface): LocalTestVariantInterface => {
    return {
        id: variant.id,
        text: variant.text,
        kind: variant.kind,
        position: variant.position,
        right: variant.right,
        image: {
            url: variant.image_url,
        },
    }
};

export const PrepareVariantToUpload = (variant: LocalTestVariantInterface
    | LocalTestVariantToUpdateInterface, questionId?: string): FormData => {
    const formData: FormData = new FormData();
    if (questionId) {
        formData.append('question_id', questionId);
    }
    if (variant.hasOwnProperty('text')) {
        formData.append('text', variant.text);
    }
    if (variant.hasOwnProperty('right')) {
        formData.append('right', JSON.stringify(variant.right));
    }
    if (variant.hasOwnProperty('position')) {
        formData.append('position', JSON.stringify(variant.position));
    }
    if (variant.image?.hasOwnProperty('file')) {
        formData.append('image_attributes[file]', variant.image.file);
    }

    return formData;
};