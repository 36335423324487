import {
  ACTION_SET_POSITION,
  ACTION_SET_POSITIONS, ACTION_SET_POSITIONS_META,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {PositionInterface} from "../../api/Positions";
import {PaginationMetaInterface} from "../../api/interface";
import {EmptyObjectType} from "../../interfaces/default";

interface StateInterface {
  positions: PositionInterface[];
  meta: PaginationMetaInterface;
  position: EmptyObjectType<PositionInterface>;
}

const defaultState: StateInterface = {
  positions: [],
  meta: PAGES_DEFAULT_META,
  position: {},
};

const positionsReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_POSITIONS: {
      return setPositions(state, action.payload);
    }
    case ACTION_SET_POSITIONS_META: {
      return setMeta(state, action.payload);
    }
    case ACTION_SET_POSITION: {
      return setPosition(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setPositions(state, payload: PositionInterface[]) {
  return {
    ...state,
    positions: payload,
  }
}

function setMeta(state, payload: PaginationMetaInterface) {
  return {
    ...state,
    meta: payload,
  }
}

function setPosition(state, payload: PositionInterface) {
  return {
    ...state,
    position: payload,
  }
}

export default positionsReducer;