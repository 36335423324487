import {AuthApi} from "./index";
import {ApiDefaultResponse} from "../interfaces/api";

export interface GetCompilationCoversInterface {
    created_at: string;
    download_url: string;
    id: string;
    path: string;
    tenant_id: string;
    updated_at: string;
}

export const GET_COMPILATION_COVERS = () => {
    return AuthApi.get<GetCompilationCoversInterface>(`files/compilation_covers`);
};

export interface UploadFileResponseModel {
    id: string;
    path: string;
    tenant_id: string;
}

export interface UploadFileResponse extends ApiDefaultResponse<UploadFileResponseModel> {}

export const UPLOAD_FILE = (data: FormData) => {
    return AuthApi.post<UploadFileResponse>(`files/upload`, data);
};