import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    CREATE_TEST,
    DELETE_TEST,
    GET_TEST,
    TestInterface,
    UPDATE_TEST,
} from "../../api/Tests";
import {
    setTestAction,
    TEST_CREATE_REQUESTED,
    TEST_DELETE_REQUESTED,
    TEST_GET_REQUESTED, TEST_QUESTION_POSITIONS_UPDATE_REQUESTED,
    TEST_UPDATE_REQUESTED,
} from "./actions";
import {createNotificationAction} from "../notifications/actions";
import {PrepareTestQuestionPositionsToUpload, PrepareTestToUpload} from "./helpers";

function* getTest(action) {
    try {
        const test: TestInterface = (yield call(GET_TEST, action.payload.id)).data;
        yield put(setTestAction(test));
    } catch (e) {
        console.log(e);
    }
}

function* createTest(action) {
    try {
        const responseData = (yield call(CREATE_TEST,
            PrepareTestToUpload(action.payload.data, action.payload.stepId))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'TEST_FORM.FAILED_SAVE_NOTIFICATION'}));
        console.log(e);
    }
}

function* updateTest(action) {
    try {
        const responseData = (yield call(UPDATE_TEST, action.payload.id,
            PrepareTestToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'TEST_FORM.FAILED_SAVE_NOTIFICATION'}));
        console.log(e);
    }
}

function* updateTestQuestionPositions(action) {
    try {
        const responseData = (yield call(UPDATE_TEST, action.payload.id,
            PrepareTestQuestionPositionsToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'TEST_FORM.FAILED_SAVE_NOTIFICATION'}));
        console.log(e);
    }
}

function* deleteTest(action) {
    try {
        yield call(DELETE_TEST, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* testsSaga() {
    yield takeLatest(TEST_GET_REQUESTED, getTest);
    yield takeEvery(TEST_CREATE_REQUESTED, createTest);
    yield takeLatest(TEST_UPDATE_REQUESTED, updateTest);
    yield takeLatest(TEST_QUESTION_POSITIONS_UPDATE_REQUESTED, updateTestQuestionPositions);
    yield takeLatest(TEST_DELETE_REQUESTED, deleteTest);
}

export default testsSaga;