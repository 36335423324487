import {
  ACTION_SET_COMPILATION_COVERS,
} from './actions';
import {GetCompilationCoversInterface} from "../../api/Files";
import {DefaultAction} from "../interface";

interface FilesReducerInterface {
  compilationCovers: GetCompilationCoversInterface[];
}

const defaultState: FilesReducerInterface = {
  compilationCovers: [],
};

const filesReducer = (state: FilesReducerInterface = defaultState, action: DefaultAction<any>): FilesReducerInterface => {
  switch (action.type) {
    case ACTION_SET_COMPILATION_COVERS: {
      return setCompilationCovers(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setCompilationCovers(state: FilesReducerInterface, payload: GetCompilationCoversInterface[]): FilesReducerInterface {
  return {
    ...state,
    compilationCovers: payload,
  }
}

export default filesReducer;