import {AuthApi} from "./index";
import {StepInterface as LocalStepInterface} from "../containers/Screenplay/constants";
import {StepInterface} from "./Steps";

export interface LessonInterface {
    attachment_id: string;
    cover_url:  string;
    description: string;
    id: string;
    name: string;
    position: number;
    step_count: number;
    published: boolean;
    script_updated_at: string
    updated_at: string;
    steps?: StepInterface[];

    formData?: any;
}

export const GET_LESSON = (id: string) => {
    return AuthApi.get(`lessons/${id}`);
};

interface CreateLessonDataInterface {
    course_id: string,
    name: string,
    description: string,
    published: boolean,
    position: number,
    attachment: string,
    sections: [],
}

export interface CreateLessonResponseModel extends CreateLessonDataInterface {
    id: string;
}

export interface CreateLessonResponseData {
    success: boolean;
    model: CreateLessonResponseModel;
    errors: string;
}

export const CREATE_LESSON = (data: FormData) => {
    return AuthApi.post(`lessons`, data);
};

export const COPY_LESSON = (id: string) => {
    return AuthApi.post(`lessons/${id}/copy`);
};

interface UpdateLessonDataInterface {
    course_id: string,
    name: string,
    description: string,
    published: boolean,
    position: number,
    attachment: string,
    sections: [],
}

interface UpdateLessonResponseModel extends UpdateLessonDataInterface {
    id: string;
}

export interface UpdateLessonResponseData {
    success: boolean;
    model: UpdateLessonResponseModel;
    errors: string;
}

export const UPDATE_LESSON = (id: string, data: FormData) => {
    return AuthApi.patch<UpdateLessonResponseData>(`lessons/${id}`, data);
};

export const DELETE_LESSON = (id: string) => {
    return AuthApi.delete(`lessons/${id}`);
};