import {
  ACTION_SET_TEST,
} from './actions';
import {TestInterface} from "../../api/Tests";

interface StateInterface {
  test: TestInterface;
}

const defaultState: StateInterface = {
  test: null,
};

const testsReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_TEST: {
      return setTest(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setTest(state, payload: TestInterface) {
  return {
    ...state,
    test: payload,
  }
}

export default testsReducer;