import {
  ACTION_SET_COURSE,
  ACTION_SET_COURSE_CONTENT,
  ACTION_SET_COURSES,
  ACTION_ADD_NEW_COURSE_CONTENT,
  ACTION_REMOVE_NEW_COURSE_CONTENT,
  ACTION_UPDATE_NEW_COURSE_CONTENT_PUBLISHED,
  ACTION_UPDATE_NEW_COURSE,
  ACTION_UPDATE_NEW_COURSE_CONTENT,
  ACTION_UPDATE_NEW_COURSE_CONTENT_POSITION,
  ACTION_RESET_NEW_COURSE,
  AddNewCourseContentActionPayload,
  UpdateNewCourseContentActionPayload, RemoveNewCourseActionPayload,
} from './actions';
import {COURSE_CONTENT_TYPES} from "../../containers/CourseContent/constants";
import {CloneObject} from "../../utils/data";
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {CoursesDataInterface} from "../../api/Courses";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {PrepareStepsToDisplay} from "../steps/helpers";
import {LessonInterface} from "../../api/Lessons";

interface StateInterface {
  coursesData: CoursesDataInterface;
  course: any;
  courseContent: any;
  newCourse: any;
  newCourseContent: any;
}

const defaultState: StateInterface = {
  coursesData: {
    courses: null,
    meta: PAGES_DEFAULT_META,
  },
  course: null,
  courseContent: null,
  newCourse: {
    name: '',
    description: '',
    coverURL: '',
    coverName: '',
    positions: [],
    city: [],
    compilation: [],
  },
  newCourseContent: [],
};

const coursesReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_COURSES: {
      return setCoursesData(state, action.payload);
    }
    case ACTION_SET_COURSE: {
      return setCourse(state, action.payload);
    }
    case ACTION_UPDATE_NEW_COURSE: {
      return updateNewCourse(state, action.payload);
    }
    case ACTION_SET_COURSE_CONTENT: {
      return setCourseContent(state, action.payload);
    }
    case ACTION_ADD_NEW_COURSE_CONTENT: {
      return addNewCourseContent(state, action.payload);
    }
    case ACTION_UPDATE_NEW_COURSE_CONTENT: {
      return updateNewCourseContent(state, action.payload);
    }
    case ACTION_REMOVE_NEW_COURSE_CONTENT: {
      return removeNewCourseContent(state, action.payload);
    }
    case ACTION_UPDATE_NEW_COURSE_CONTENT_PUBLISHED: {
      return updateNewCourseContentPublished(state, action.payload);
    }
    case ACTION_UPDATE_NEW_COURSE_CONTENT_POSITION: {
      return updateNewCourseContentPosition(state, action.payload);
    }
    case ACTION_RESET_NEW_COURSE: {
      return resetNewCourse(state);
    }
    default: {
      return state;
    }
  }
};

function setCoursesData(state, payload) {
  return {
    ...state,
    coursesData: payload,
  }
}

function setCourse(state, payload) {
  return {
    ...state,
    course: payload,
  }
}

function resetNewCourse(state) {
  return {
    ...state,
    newCourseContent: [],
    newCourse: {
      name: '',
      description: '',
      coverURL: '',
      coverName: '',
      positions: [],
      city: [],
      compilation: [],
    },
  }
}

function setCourseContent(state, lessons: LessonInterface[]) {
  return {
    ...state,
    courseContent: lessons.map((lesson) => {
      // @ts-ignore
      lesson.steps = PrepareStepsToDisplay(lesson.steps);
      return lesson;
    }),
  }
}

function removeNewCourseContent(state, payload: RemoveNewCourseActionPayload) {
  const newCourseContent: AddNewCourseContentActionPayload[] = [...state.newCourseContent];
  const removeItemIndex: number = newCourseContent.findIndex((item) => item.position === payload.position);
  const itemToRemove: AddNewCourseContentActionPayload = newCourseContent[removeItemIndex];
  if (itemToRemove.type === COURSE_CONTENT_TYPES.LESSON) {
    LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_LESSON_DELETE_FINISHED, {
      content_number: payload.position,
      lesson_name: itemToRemove.lesson.name,
    });
  } else {
    LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_TEST_DELETE_FINISHED, {
      content_number: payload.position,
      test_name: itemToRemove.test.name,
    });
  }
  if (removeItemIndex !== -1) {
    newCourseContent.splice(removeItemIndex, 1);
  }

  return {
    ...state,
    newCourseContent,
  }
}

function addNewCourseContent(state, payload: AddNewCourseContentActionPayload) {
  const newContentItem: AddNewCourseContentActionPayload = CloneObject(payload);
  const position = state.newCourseContent.length + 1;
  newContentItem.position = position;
  if (newContentItem.type === COURSE_CONTENT_TYPES.LESSON) {
    newContentItem.lesson.id = position;
    if (newContentItem.isCopy) {
      LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_LESSON_COPY);
    } else {
      LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_LESSON_ADD_FINISHED, {
        content_number: position,
        lesson_name: newContentItem.lesson.name,
      });
    }
  } else {
    newContentItem.test.id = position;
    if (newContentItem.isCopy) {
      LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_TEST_COPY);
    } else {
      LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_TEST_ADD_FINISHED, {
        content_number: position,
        test_name: newContentItem.test.name,
      });
    }
  }
  delete newContentItem.isCopy;

  return {
    ...state,
    newCourseContent: [...state.newCourseContent, newContentItem],
  }
}

function updateNewCourseContent(state, payload: UpdateNewCourseContentActionPayload) {
  const newCourseContent = [...state.newCourseContent];
  newCourseContent[payload.id] = CloneObject(payload.data);
  if (payload.data.type === COURSE_CONTENT_TYPES.LESSON) {
    LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_LESSON_EDIT_FINISHED, {
      content_number: payload.data.position,
      lesson_name: payload.data.lesson.name,
    });
  } else {
    LogAmplitudeEvent(AMPLITUDE_EVENT.COURSE_CREATE_TEST_EDIT_FINISHED, {
      content_number: payload.data.position,
      test_name: payload.data.test.name,
    });
  }

  return {
    ...state,
    newCourseContent: newCourseContent,
  }
}

function updateNewCourse(state, payload) {
  return {
    ...state,
    newCourse: payload,
  }
}

function updateNewCourseContentPosition(state, payload) {
  const droppedIndex = payload.droppedIndex;
  const currentIndex = payload.currentIndex;
  const newCourseContent = [...state.newCourseContent];
  newCourseContent.splice(droppedIndex, 1);
  newCourseContent.splice(currentIndex, 0, state.newCourseContent[droppedIndex]);

  return {
    ...state,
    newCourseContent,
  }
}

function updateNewCourseContentPublished(state, payload) {
  const newContent = [...state.newCourseContent];
  const item = newContent[payload.index];
  if (item.type === COURSE_CONTENT_TYPES.LESSON) {
    item.lesson.published = payload.published;
  } else {
    item.test.published = payload.published;
  }

  return {
    ...state,
    newCourseContent: newContent,
  }
}

export default coursesReducer;