import {call, put, takeLatest} from 'redux-saga/effects';

import {CANCEL_PAYMENT, DO_PAYMENT} from '../../api/Payments';
import {PAYMENTS_CANCEL_PAYMENT_REQUESTED_ACTION, PAYMENTS_DO_PAYMENT_REQUESTED_ACTION} from '../payments/actions';
import {createNotificationAction} from '../notifications/actions';

function* doPayment() {
    try {
        const response = yield call(DO_PAYMENT);

        if (response.data?.model?.session_url) {
            window.location = response.data.model.session_url;
        }
    } catch (error) {
        console.error(error);
    }
}

function* cancelPayment(action) {
    try {
        yield call(CANCEL_PAYMENT, action.payload.pid);
        yield put(createNotificationAction({ text: 'BILLING_NOTIFICATION.PAYMENT_CANCELLED'}));
    } catch (error) {
        console.error(error);
    }
}

function* billingSaga() {
    yield takeLatest(PAYMENTS_DO_PAYMENT_REQUESTED_ACTION, doPayment);
    yield takeLatest(PAYMENTS_CANCEL_PAYMENT_REQUESTED_ACTION, cancelPayment);
}

export default billingSaga;
