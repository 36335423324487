import {CreateUserDataInterface, GetUsersParamsInterface, UpdateUserDataInterface} from "../../api/Users";
import {PayloadWithCallback} from "../../interfaces/store";
import {EmptyObjectType} from "../../interfaces/default";
import {UserInterface} from "./interface";

export const ACTION_SET_USERS = 'ACTION_SET_USERS';

export const setUsersAction = data => ({
  type: ACTION_SET_USERS,
  payload: data,
});

export const ACTION_SET_USER_PROFILE = 'ACTION_SET_USER_PROFILE';

export const setUserProfileAction = (data: EmptyObjectType<UserInterface>) => ({
  type: ACTION_SET_USER_PROFILE,
  payload: data,
});

export const ACTION_SET_ERROR_CODE = 'ACTION_SET_ERROR_CODE';

export const setErrorCodeAction = (data: number) => ({
  type: ACTION_SET_ERROR_CODE,
  payload: data,
});

export const USERS_GET_REQUESTED = 'USERS_GET_REQUESTED';

export interface UserGetRequestedPayload {
  isSearch?: boolean;
  data: GetUsersParamsInterface;
}

export const usersGetRequestedAction = (data: UserGetRequestedPayload) => ({
  type: USERS_GET_REQUESTED,
  payload: data,
});

export const USER_GET_REQUESTED = 'USER_GET_REQUESTED';

export const userGetRequestedAction = () => ({
  type: USER_GET_REQUESTED,
});

export interface UserUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: UpdateUserDataInterface;
}

export const USER_UPDATE_REQUESTED = 'USER_UPDATE_REQUESTED';

export const userUpdateRequestedAction = (data: UserUpdateRequestedPayload) => ({
  type: USER_UPDATE_REQUESTED,
  payload: data,
});

export interface UserCreateRequestedPayload extends PayloadWithCallback {
  data: CreateUserDataInterface;
}

export const USER_CREATE_REQUESTED = 'USER_CREATE_REQUESTED';

export const userCreateRequestedAction = (data: UserCreateRequestedPayload) => ({
  type: USER_CREATE_REQUESTED,
  payload: data,
});

export interface UserDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const USER_DELETE_REQUESTED = 'USER_DELETE_REQUESTED';

export const userDeleteRequestedAction = (data: UserDeleteRequestedPayload) => ({
  type: USER_DELETE_REQUESTED,
  payload: data,
});