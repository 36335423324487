import React from 'react';
import {GetModifiers} from "../../utils/classNames";

interface LoaderProps {
    isFullscreen?: boolean;
}

function Loader(props: LoaderProps) {
    const {
        isFullscreen = false,
    } = props;

    const getContainerMods = (): string => {
        return GetModifiers('loader__container', [
            {
                condition: isFullscreen,
                value: 'fullscreen',
            },
        ]);
    };

    return (
        <div className={getContainerMods()} >
            <div className="loader" />
        </div>
    );
}

export default Loader;
