export enum AMPLITUDE_EVENT {
    LOGGED_IN_SUCCESS = 'LOGGED_IN_SUCCESS',
    LOGGED_IN_FAIL = 'LOGGED_IN_FAIL',
    LOGGED_OUT = 'LOGGED_OUT',
    PAGE_VIEW = 'PAGE_VIEW',

    EMPLOYEE_CREATE_STARTED = 'EMPLOYEE_CREATE_STARTED',
    EMPLOYEE_CREATE_FINISHED_SUCCESS = 'EMPLOYEE_CREATE_FINISHED_SUCCESS',
    EMPLOYEE_CREATE_FINISHED_FAIL = 'EMPLOYEE_CREATE_FINISHED_FAIL',
    EMPLOYEE_CREATE_CANCELED = 'EMPLOYEE_CREATE_CANCELED',
    EMPLOYEE_SEARCH = 'EMPLOYEE_SEARCH',
    EMPLOYEE_EDIT_STARTED = 'EMPLOYEE_EDIT_STARTED',
    EMPLOYEE_EDIT_FINISHED = 'EMPLOYEE_EDIT_FINISHED',
    EMPLOYEE_EDIT_FINISHED_FAIL = 'EMPLOYEE_EDIT_FINISHED_FAIL',
    EMPLOYEE_EDIT_CANCELED = 'EMPLOYEE_EDIT_CANCELED',
    EMPLOYEE_DELETE_STARTED = 'EMPLOYEE_DELETE_STARTED',
    EMPLOYEE_DELETE_FINISHED = 'EMPLOYEE_DELETE_FINISHED',
    EMPLOYEE_DELETE_CANCELED = 'EMPLOYEE_DELETE_CANCELED',
    EMPLOYEE_CREATE_BACK = 'EMPLOYEE_CREATE_BACK',
    EMPLOYEE_EDIT_BACK = 'EMPLOYEE_EDIT_BACK',

    MOBILE_USER_SEARCH = 'MOBILE_USER_SEARCH',
    MOBILE_USER_POSITION_FILTER = 'MOBILE_USER_POSITION_FILTER',
    MOBILE_USER_CITY_FILTER = 'MOBILE_USER_CITY_FILTER',
    MOBILE_USER_DATE_FILTER = 'MOBILE_USER_DATE_FILTER',
    MOBILE_USER_EDIT_STARTED = 'MOBILE_USER_EDIT_STARTED',
    MOBILE_USER_EDIT_FINISHED = 'MOBILE_USER_EDIT_FINISHED',
    MOBILE_USER_EDIT_CANCELED = 'MOBILE_USER_EDIT_CANCELED',
    MOBILE_USER_DELETE_STARTED = 'MOBILE_USER_DELETE_STARTED',
    MOBILE_USER_DELETE_FINISHED = 'MOBILE_USER_DELETE_FINISHED',
    MOBILE_USER_DELETE_CANCELED = 'MOBILE_USER_DELETE_CANCELED',

    COURSE_CREATE_STARTED = 'COURSE_CREATE_STARTED',
    COURSE_CREATE_SAVED = 'COURSE_CREATE_SAVED',
    COURSE_CREATE_PUBLISHED = 'COURSE_CREATE_PUBLISHED',
    COURSE_CREATE_CANCELED = 'COURSE_CREATE_CANCELED',
    COURSE_CREATE_LESSON_ADD_STARTED = 'COURSE_CREATE_LESSON_ADD_STARTED',
    COURSE_CREATE_LESSON_ADD_FINISHED = 'COURSE_CREATE_LESSON_ADD_FINISHED',
    COURSE_CREATE_LESSON_ADD_CANCELED = 'COURSE_CREATE_LESSON_ADD_CANCELED',
    COURSE_CREATE_TEST_ADD_STARTED = 'COURSE_CREATE_TEST_ADD_STARTED',
    COURSE_CREATE_TEST_ADD_FINISHED = 'COURSE_CREATE_TEST_ADD_FINISHED',
    COURSE_CREATE_TEST_ADD_CANCELED = 'COURSE_CREATE_TEST_ADD_CANCELED',
    COURSE_CREATE_LESSON_EDIT_STARTED = 'COURSE_CREATE_LESSON_EDIT_STARTED',
    COURSE_CREATE_LESSON_EDIT_FINISHED = 'COURSE_CREATE_LESSON_EDIT_FINISHED',
    COURSE_CREATE_LESSON_EDIT_CANCELED = 'COURSE_CREATE_LESSON_EDIT_CANCELED',
    COURSE_CREATE_TEST_EDIT_STARTED = 'COURSE_CREATE_TEST_EDIT_STARTED',
    COURSE_CREATE_TEST_EDIT_FINISHED = 'COURSE_CREATE_TEST_EDIT_FINISHED',
    COURSE_CREATE_TEST_EDIT_CANCELED = 'COURSE_CREATE_TEST_EDIT_CANCELED',
    COURSE_CREATE_LESSON_COPY = 'COURSE_CREATE_LESSON_COPY',
    COURSE_CREATE_TEST_COPY = 'COURSE_CREATE_TEST_COPY',
    COURSE_CREATE_LESSON_DELETE_STARTED = 'COURSE_CREATE_LESSON_DELETE_STARTED',
    COURSE_CREATE_LESSON_DELETE_FINISHED = 'COURSE_CREATE_LESSON_DELETE_FINISHED',
    COURSE_CREATE_LESSON_DELETE_CANCELED = 'COURSE_CREATE_LESSON_DELETE_CANCELED',
    COURSE_CREATE_TEST_DELETE_STARTED = 'COURSE_CREATE_TEST_DELETE_STARTED',
    COURSE_CREATE_TEST_DELETE_FINISHED = 'COURSE_CREATE_TEST_DELETE_FINISHED',
    COURSE_CREATE_TEST_DELETE_CANCELED = 'COURSE_CREATE_TEST_DELETE_CANCELED',
    PUBLISHED_COURSE_SEARCH = 'PUBLISHED_COURSE_SEARCH',
    SAVED_COURSE_SEARCH = 'SAVED_COURSE_SEARCH',
    ARCHIVED_COURSE_SEARCH = 'ARCHIVED_COURSE_SEARCH',
    PUBLISHED_COURSE_POSITION_FILTER = 'PUBLISHED_COURSE_POSITION_FILTER',
    SAVED_COURSE_POSITION_FILTER = 'SAVED_COURSE_POSITION_FILTER',
    ARCHIVED_COURSE_POSITION_FILTER = 'ARCHIVED_COURSE_POSITION_FILTER',
    PUBLISHED_COURSE_CITY_FILTER = 'PUBLISHED_COURSE_CITY_FILTER',
    SAVED_COURSE_CITY_FILTER = 'SAVED_COURSE_CITY_FILTER',
    ARCHIVED_COURSE_CITY_FILTER = 'ARCHIVED_COURSE_CITY_FILTER',
    PUBLISHED_COURSE_DELETE = 'PUBLISHED_COURSE_DELETE',
    SAVED_COURSE_DELETE = 'SAVED_COURSE_DELETE',
    ARCHIVED_COURSE_DELETE_STARTED = 'ARCHIVED_COURSE_DELETE_STARTED',
    ARCHIVED_COURSE_DELETE_FINISHED = 'ARCHIVED_COURSE_DELETE_FINISHED',
    ARCHIVED_COURSE_DELETE_CANCELED = 'ARCHIVED_COURSE_DELETE_CANCELED',
    PUBLISHED_COURSE_COPY = 'PUBLISHED_COURSE_COPY',
    SAVED_COURSE_COPY = 'SAVED_COURSE_COPY',
    ARCHIVED_COURSE_COPY = 'ARCHIVED_COURSE_COPY',
    COURSE_EDIT_STARTED = 'COURSE_EDIT_STARTED',
    COURSE_EDIT_SAVED = 'COURSE_EDIT_SAVED',
    COURSE_EDIT_PUBLISHED = 'COURSE_EDIT_PUBLISHED',
    COURSE_EDIT_CANCELED = 'COURSE_EDIT_CANCELED',
    COURSE_EDIT_LESSON_ADD_STARTED = 'COURSE_EDIT_LESSON_ADD_STARTED',
    COURSE_EDIT_LESSON_ADD_FINISHED = 'COURSE_EDIT_LESSON_ADD_FINISHED',
    COURSE_EDIT_LESSON_ADD_CANCELED = 'COURSE_EDIT_LESSON_ADD_CANCELED',
    COURSE_EDIT_TEST_ADD_STARTED = 'COURSE_EDIT_TEST_ADD_STARTED',
    COURSE_EDIT_TEST_ADD_FINISHED = 'COURSE_EDIT_TEST_ADD_FINISHED',
    COURSE_EDIT_TEST_ADD_CANCELED = 'COURSE_EDIT_TEST_ADD_CANCELED',
    COURSE_EDIT_LESSON_EDIT_STARTED = 'COURSE_EDIT_LESSON_EDIT_STARTED',
    COURSE_EDIT_LESSON_EDIT_FINISHED = 'COURSE_EDIT_LESSON_EDIT_FINISHED',
    COURSE_EDIT_LESSON_EDIT_CANCELED = 'COURSE_EDIT_LESSON_EDIT_CANCELED',
    COURSE_EDIT_TEST_EDIT_STARTED = 'COURSE_EDIT_TEST_EDIT_STARTED',
    COURSE_EDIT_TEST_EDIT_FINISHED = 'COURSE_EDIT_TEST_EDIT_FINISHED',
    COURSE_EDIT_TEST_EDIT_CANCELED = 'COURSE_EDIT_TEST_EDIT_CANCELED',
    COURSE_EDIT_LESSON_COPY = 'COURSE_EDIT_LESSON_COPY',
    COURSE_EDIT_TEST_COPY = 'COURSE_EDIT_TEST_COPY',
    COURSE_EDIT_LESSON_DELETE_STARTED = 'COURSE_EDIT_LESSON_DELETE_STARTED',
    COURSE_EDIT_LESSON_DELETE_FINISHED = 'COURSE_EDIT_LESSON_DELETE_FINISHED',
    COURSE_EDIT_LESSON_DELETE_CANCELED = 'COURSE_EDIT_LESSON_DELETE_CANCELED',
    COURSE_EDIT_TEST_DELETE_STARTED = 'COURSE_EDIT_TEST_DELETE_STARTED',
    COURSE_EDIT_TEST_DELETE_FINISHED = 'COURSE_EDIT_TEST_DELETE_FINISHED',
    COURSE_EDIT_TEST_DELETE_CANCELED = 'COURSE_EDIT_TEST_DELETE_CANCELED',
    COURSE_CREATE_PUBLISHED_FAIL = 'COURSE_CREATE_PUBLISHED_FAIL',
    COURSE_CREATE_BACK_STARTED = 'COURSE_CREATE_BACK_STARTED',
    COURSE_CREATE_BACK_CANCELED = 'COURSE_CREATE_BACK_CANCELED',
    COURSE_CREATE_BACK_FINISHED = 'COURSE_CREATE_BACK_FINISHED',
    COURSE_EDIT_PUBLISHED_FAIL = 'COURSE_EDIT_PUBLISHED_FAIL',
    COURSE_EDIT_BACK_STARTED = 'COURSE_EDIT_BACK_STARTED',
    COURSE_EDIT_BACK_CANCELED	= 'COURSE_EDIT_BACK_CANCELED',
    COURSE_EDIT_BACK_FINISHED	= 'COURSE_EDIT_BACK_FINISHED',

    LESSON_CREATE_STARTED	= 'LESSON_CREATE_STARTED',
    LESSON_CREATE_BACK_STARTED = 'LESSON_CREATE_BACK_STARTED',
    LESSON_CREATE_BACK_CANCELED	= 'LESSON_CREATE_BACK_CANCELED',
    LESSON_CREATE_BACK_FINISHED	= 'LESSON_CREATE_BACK_FINISHED',
    LESSON_CREATE_SAVED = 'LESSON_CREATE_SAVED',
    LESSON_EDIT_STARTED	= 'LESSON_EDIT_STARTED',
    LESSON_EDIT_BACK_STARTED = 'LESSON_EDIT_BACK_STARTED',
    LESSON_EDIT_BACK_FINISHED	= 'LESSON_EDIT_BACK_FINISHED',
    LESSON_EDIT_BACK_CANCELED	= 'LESSON_EDIT_BACK_CANCELED',
    LESSON_EDIT_SAVED = 'LESSON_EDIT_SAVED',
    LESSON_EDIT_PUBLISHED = 'LESSON_EDIT_PUBLISHED',
    LESSON_DELETE_STARTED	= 'LESSON_DELETE_STARTED',
    LESSON_DELETE_FINISHED = 'LESSON_DELETE_FINISHED',
    LESSON_DELETE_CANCELED = 'LESSON_DELETE_CANCELED',
    LESSON_COPY = 'LESSON_COPY',

    SCREENPLAY_CREATE	= 'SCREENPLAY_CREATE',
    SCREENPLAY_EDIT	= 'SCREENPLAY_EDIT',
    SCREENPLAY_BACK	= 'SCREENPLAY_BACK',
    SCREENPLAY_STEP_ADD = 'SCREENPLAY_STEP_ADD',
    SCREENPLAY_STEP_COPY = 'SCREENPLAY_STEP_COPY',
    SCREENPLAY_STEP_DELETE = 'SCREENPLAY_STEP_DELETE',
    SCREENPLAY_STEP_EDIT = 'SCREENPLAY_STEP_EDIT',
    SCREENPLAY_STEP_EDIT_CLOSE = 'SCREENPLAY_STEP_EDIT_CLOSE',
    SCREENPLAY_STEP_TRANSITION_ON = 'SCREENPLAY_STEP_TRANSITION_ON',
    SCREENPLAY_STEP_TRANSITION_OFF = 'SCREENPLAY_STEP_TRANSITION_OFF',
    SCREENPLAY_STEP_BUTTON_ADD = 'SCREENPLAY_STEP_BUTTON_ADD',
    SCREENPLAY_STEP_BUTTON_DELETE = 'SCREENPLAY_STEP_BUTTON_DELETE',
    SCREENPLAY_STEP_TRANSITION_CHANGE = 'SCREENPLAY_STEP_TRANSITION_CHANGE',
    SCREENPLAY_STEP_TRANSITION_CREATE = 'SCREENPLAY_STEP_TRANSITION_CREATE',
    SCREENPLAY_STEP_TRANSITION_DELETE	= 'SCREENPLAY_STEP_TRANSITION_DELETE',
    SCREENPLAY_STEP_STARTCARD_CHANGE = 'SCREENPLAY_STEP_STARTCARD_CHANGE',

    COMPILATION_CREATE_STARTED = 'COMPILATION_CREATE_STARTED',
    COMPILATION_CREATE_SAVED = 'COMPILATION_CREATE_SAVED',
    COMPILATION_CREATE_PUBLISHED = 'COMPILATION_CREATE_PUBLISHED',
    COMPILATION_CREATE_CANCELED = 'COMPILATION_CREATE_CANCELED',
    COMPILATION_CREATE_COURSE_ADD = 'COMPILATION_CREATE_COURSE_ADD',
    COMPILATION_CREATE_COURSE_DELETE = 'COMPILATION_CREATE_COURSE_DELETE',
    COMPILATION_CREATE_COURSE_POSITION_FILTER = 'COMPILATION_CREATE_COURSE_POSITION_FILTER',
    COMPILATION_CREATE_COURSE_CITY_FILTER = 'COMPILATION_CREATE_COURSE_CITY_FILTER',
    PUBLISHED_COMPILATION_SEARCH = 'PUBLISHED_COMPILATION_SEARCH',
    SAVED_COMPILATION_SEARCH = 'SAVED_COMPILATION_SEARCH',
    ARCHIVED_COMPILATION_SEARCH = 'ARCHIVED_COMPILATION_SEARCH',
    PUBLISHED_COMPILATION_POSITION_FILTER = 'PUBLISHED_COMPILATION_POSITION_FILTER',
    SAVED_COMPILATION_POSITION_FILTER = 'SAVED_COMPILATION_POSITION_FILTER',
    ARCHIVED_COMPILATION_POSITION_FILTER = 'ARCHIVED_COMPILATION_POSITION_FILTER',
    PUBLISHED_COMPILATION_CITY_FILTER = 'PUBLISHED_COMPILATION_CITY_FILTER',
    SAVED_COMPILATION_CITY_FILTER = 'SAVED_COMPILATION_CITY_FILTER',
    ARCHIVED_COMPILATION_CITY_FILTER = 'ARCHIVED_COMPILATION_CITY_FILTER',
    PUBLISHED_COMPILATION_DELETE = 'PUBLISHED_COMPILATION_DELETE',
    SAVED_COMPILATION_DELETE = 'SAVED_COMPILATION_DELETE',
    ARCHIVED_COMPILATION_DELETE = 'ARCHIVED_COMPILATION_DELETE',
    PUBLISHED_COMPILATION_COPY = 'PUBLISHED_COMPILATION_COPY',
    SAVED_COMPILATION_COPY = 'SAVED_COMPILATION_COPY',
    ARCHIVED_COMPILATION_COPY = 'ARCHIVED_COMPILATION_COPY',
    PUBLISHED_COMPILATION_COPY_FAIL = 'PUBLISHED_COMPILATION_COPY_FAIL',
    SAVED_COMPILATION_COPY_FAIL = 'SAVED_COMPILATION_COPY_FAIL',
    ARCHIVED_COMPILATION_COPY_FAIL = 'ARCHIVED_COMPILATION_COPY_FAIL',
    COMPILATION_EDIT_STARTED = 'COMPILATION_EDIT_STARTED',
    COMPILATION_EDIT_SAVED = 'COMPILATION_EDIT_SAVED',
    COMPILATION_EDIT_PUBLISHED = 'COMPILATION_EDIT_PUBLISHED',
    COMPILATION_EDIT_CANCELED = 'COMPILATION_EDIT_CANCELED',
    COMPILATION_EDIT_COURSE_ADD = 'COMPILATION_EDIT_COURSE_ADD',
    COMPILATION_EDIT_COURSE_DELETE = 'COMPILATION_EDIT_COURSE_DELETE',
    COMPILATION_EDIT_COURSE_POSITION_FILTER = 'COMPILATION_EDIT_COURSE_POSITION_FILTER',
    COMPILATION_EDIT_COURSE_CITY_FILTER = 'COMPILATION_EDIT_COURSE_CITY_FILTER',
    COMPILATION_CREATE_BACK_STARTED = 'COMPILATION_CREATE_BACK_STARTED',
    COMPILATION_CREATE_BACK_FINISHED = 'COMPILATION_CREATE_BACK_FINISHED',
    COMPILATION_CREATE_BACK_CANCELED = 'COMPILATION_CREATE_BACK_CANCELED',
    COMPILATION_EDIT_BACK_STARTED = 'COMPILATION_EDIT_BACK_STARTED',
    COMPILATION_EDIT_BACK_CANCELED = 'COMPILATION_EDIT_BACK_CANCELED',
    COMPILATION_EDIT_BACK_FINISHED = 'COMPILATION_EDIT_BACK_FINISHED',

    PUBLISHED_FEEDBACK_SEARCH = 'PUBLISHED_FEEDBACK_SEARCH',
    ARCHIVED_FEEDBACK_SEARCH = 'ARCHIVED_FEEDBACK_SEARCH',

    DASHBOARD_ADD_STARTED = 'DASHBOARD_ADD_STARTED',
    DASHBOARD_ADD_FINISHED = 'DASHBOARD_ADD_FINISHED',
    DASHBOARD_ADD_CANCELED = 'DASHBOARD_ADD_CANCELED',
    DASHBOARD_ADD_FAIL = 'DASHBOARD_ADD_FAIL',
    DASHBOARD_DELETE_STARTED = 'DASHBOARD_DELETE_STARTED',
    DASHBOARD_DELETE_FINISHED = 'DASHBOARD_DELETE_FINISHED',
    DASHBOARD_DELETE_CANCELED = 'DASHBOARD_DELETE_CANCELED',

    SETTINGS_POSITION_CREATE_STARTED = 'SETTINGS_POSITION_CREATE_STARTED',
    SETTINGS_POSITION_CREATE_FINISHED = 'SETTINGS_POSITION_CREATE_FINISHED',
    SETTINGS_POSITION_CREATE_CANCELED = 'SETTINGS_POSITION_CREATE_CANCELED',
    SETTINGS_POSITION_CREATE_FAIL = 'SETTINGS_POSITION_CREATE_FAIL',
    SETTINGS_POSITION_SEARCH = 'SETTINGS_POSITION_SEARCH',
    SETTINGS_POSITION_EDIT_STARTED = 'SETTINGS_POSITION_EDIT_STARTED',
    SETTINGS_POSITION_EDIT_FINISHED = 'SETTINGS_POSITION_EDIT_FINISHED',
    SETTINGS_POSITION_EDIT_CANCELED = 'SETTINGS_POSITION_EDIT_CANCELED',
    SETTINGS_POSITION_DELETE = 'SETTINGS_POSITION_DELETE',
    SETTINGS_POSITION_DELETE_FAIL = 'SETTINGS_POSITION_DELETE_FAIL',
    SETTINGS_POSITION_DELETE_FAIL_CANCELED = 'SETTINGS_POSITION_DELETE_FAIL_CANCELED',
    SETTINGS_POSITION_DELETE_FAIL_OK = 'SETTINGS_POSITION_DELETE_FAIL_OK',
    SETTINGS_CITY_CREATE_STARTED = 'SETTINGS_CITY_CREATE_STARTED',
    SETTINGS_CITY_CREATE_FINISHED = 'SETTINGS_CITY_CREATE_FINISHED',
    SETTINGS_CITY_CREATE_CANCELED = 'SETTINGS_CITY_CREATE_CANCELED',
    SETTINGS_CITY_CREATE_FAIL = 'SETTINGS_CITY_CREATE_FAIL',
    SETTINGS_CITY_SEARCH = 'SETTINGS_CITY_SEARCH',
    SETTINGS_CITY_EDIT_STARTED = 'SETTINGS_CITY_EDIT_STARTED',
    SETTINGS_CITY_EDIT_FINISHED = 'SETTINGS_CITY_EDIT_FINISHED',
    SETTINGS_CITY_EDIT_CANCELED = 'SETTINGS_CITY_EDIT_CANCELED',
    SETTINGS_CITY_DELETE = 'SETTINGS_CITY_DELETE',
    SETTINGS_CITY_DELETE_FAIL = 'SETTINGS_CITY_DELETE_FAIL',
    SETTINGS_CITY_DELETE_FAIL_CANCELED = 'SETTINGS_CITY_DELETE_FAIL_CANCELED',
    SETTINGS_CITY_DELETE_FAIL_OK = 'SETTINGS_CITY_DELETE_FAIL_OK',
    SETTINGS_LEVEL_CREATE_STARTED = 'SETTINGS_LEVEL_CREATE_STARTED',
    SETTINGS_LEVEL_CREATE_FINISHED = 'SETTINGS_LEVEL_CREATE_FINISHED',
    SETTINGS_LEVEL_CREATE_CANCELED = 'SETTINGS_LEVEL_CREATE_CANCELED',
    SETTINGS_LEVEL_CREATE_FAIL = 'SETTINGS_LEVEL_CREATE_FAIL',
    SETTINGS_LEVEL_EDIT_STARTED = 'SETTINGS_LEVEL_EDIT_STARTED',
    SETTINGS_LEVEL_EDIT_FINISHED = 'SETTINGS_LEVEL_EDIT_FINISHED',
    SETTINGS_LEVEL_EDIT_CANCELED = 'SETTINGS_LEVEL_EDIT_CANCELED',
    SETTINGS_LEVEL_DELETE_STARTED = 'SETTINGS_LEVEL_DELETE_STARTED',
    SETTINGS_LEVEL_DELETE_FINISHED = 'SETTINGS_LEVEL_DELETE_FINISHED',
    SETTINGS_LEVEL_DELETE_CANCELED = 'SETTINGS_LEVEL_DELETE_CANCELED',
    SETTINGS_ELEMENT_EDIT_STARTED = 'SETTINGS_ELEMENT_EDIT_STARTED',
    SETTINGS_ELEMENT_EDIT_FINISHED = 'SETTINGS_ELEMENT_EDIT_FINISHED',
    SETTINGS_ELEMENT_EDIT_CANCELED = 'SETTINGS_ELEMENT_EDIT_CANCELED',

    PUBLICATION_CREATE_STARTED = 'PUBLICATION_CREATE_STARTED',
    PUBLICATION_CREATE_SCHEDULE_STARTED	= 'PUBLICATION_CREATE_SCHEDULE_STARTED',
    PUBLICATION_CREATE_SCHEDULE_FINISHED = 'PUBLICATION_CREATE_SCHEDULE_FINISHED',
    PUBLICATION_CREATE_SCHEDULE_CANCELED = 'PUBLICATION_CREATE_SCHEDULE_CANCELED',
    PUBLICATION_CREATE_PUBLISH_STARTED = 'PUBLICATION_CREATE_PUBLISH_STARTED',
    PUBLICATION_CREATE_PUBLISH_FINISHED = 'PUBLICATION_CREATE_PUBLISH_FINISHED',
    PUBLICATION_CREATE_PUBLISH_CANCELED	= 'PUBLICATION_CREATE_PUBLISH_CANCELED',
    PUBLICATION_CREATE_BACK_STARTED	= 'PUBLICATION_CREATE_BACK_STARTED',
    PUBLICATION_CREATE_BACK_FINISHED = 'PUBLICATION_CREATE_BACK_FINISHED',
    PUBLICATION_CREATE_BACK_CANCELED = 'PUBLICATION_CREATE_BACK_CANCELED',
    PUBLICATION_CREATE_CANCEL_STARTED	= 'PUBLICATION_CREATE_CANCEL_STARTED',
    PUBLICATION_CREATE_CANCEL_FINISHED = 'PUBLICATION_CREATE_CANCEL_FINISHED',
    PUBLICATION_CREATE_CANCEL_CANCELED = 'PUBLICATION_CREATE_CANCEL_CANCELED',
}

export const DASHBOARD_AMPLITUDE_EVENTS = {
  POSITION_FILTER: {
    MAIN: 'DASH_MAIN_POSITION_FILTER',
    USERS_ACTIVITY: 'DASH_USERS_ACTIVITY_POSITION_FILTER',
    COURSES_ACTIVITY: 'DASH_COURSES_ACTIVITY_POSITION_FILTER',
    COURSE: 'DASH_COURSE_POSITION_FILTER',
    NEWS: 'DASH_NEWS_POSITION_FILTER'
  },
  CITY_FILTER: {
    MAIN: 'DASH_MAIN_CITY_FILTER',
    USERS_ACTIVITY: 'DASH_USERS_ACTIVITY_CITY_FILTER',
    COURSES_ACTIVITY: 'DASH_COURSES_ACTIVITY_CITY_FILTER',
    COURSE: 'DASH_COURSE_CITY_FILTER',
    NEWS: 'DASH_NEWS_CITY_FILTER'
  },
  DATE_FILTER: {
    MAIN: 'DASH_MAIN_DATE_FILTER',
    USERS_ACTIVITY: 'DASH_USERS_ACTIVITY_DATE_FILTER',
    COURSES_ACTIVITY: 'DASH_COURSES_ACTIVITY_DATE_FILTER',
    COURSE: 'DASH_COURSE_DATE_FILTER',
    NEWS: 'DASH_NEWS_DATE_FILTER'
  },
  SEARCH: {
    USERS_ACTIVITY: 'DASH_USERS_ACTIVITY_SEARCH',
    USER: 'DASH_USER_SEARCH',
    COURSES_ACTIVITY: 'DASH_COURSES_ACTIVITY_SEARCH',
    COURSE: 'DASH_COURSE_SEARCH',
    NEWS: 'DASH_NEWS_SEARCH'
  },
  EXPORT: {
    USERS_ACTIVITY: 'DASH_USERS_ACTIVITY_EXPORT',
    USER: 'DASH_USER_EXPORT',
    COURSES_ACTIVITY: 'DASH_COURSES_ACTIVITY_EXPORT',
    COURSE: 'DASH_COURSE_EXPORT',
    NEWS: 'DASH_NEWS_EXPORT'
  },
  COURSE_ADD_STARTED: {
    MAIN: 'DASH_MAIN_COURSE_ADD_STARTED'
  },
  COURSE_ADD_FINISHED: {
    MAIN: 'DASH_MAIN_COURSE_ADD_FINISHED'
  },
  COURSE_ADD_CANCELED: {
    MAIN: 'DASH_MAIN_COURSE_ADD_CANCELED'
  },
  COURSE_DELETE: {
    MAIN: 'DASH_MAIN_COURSE_DELETE'
  }
}

export const MOBILE_USER_AMPLITUDE_EVENTS = {
  USER_SEARCH: {
    REGISTERED: 'REGISTERED_USER_SEARCH',
    ALL: 'ALL_USER_SEARCH'
  },
  USER_POSITION_FILTER: {
    REGISTERED: 'REGISTERED_USER_POSITION_FILTER',
    ALL: 'ALL_USER_POSITION_FILTER'
  },
  USER_CITY_FILTER: {
    REGISTERED: 'REGISTERED_USER_CITY_FILTER',
    ALL: 'ALL_USER_CITY_FILTER'
  },
  USER_DATE_FILTER: {
    REGISTERED: 'REGISTERED_USER_DATE_FILTER',
    ALL: 'ALL_USER_DATE_FILTER'
  },
  USER_EDIT_STARTED: {
    REGISTERED: 'REGISTERED_USER_EDIT_STARTED',
    ALL: 'ALL_USER_EDIT_STARTED'
  },
  USER_EDIT_FINISHED: {
    REGISTERED: 'REGISTERED_USER_EDIT_FINISHED',
    ALL: 'ALL_USER_EDIT_FINISHED'
  },
  USER_EDIT_CANCELED: {
    REGISTERED: 'REGISTERED_USER_EDIT_CANCELED',
    ALL: 'ALL_USER_EDIT_CANCELED'
  },
  USER_DELETE_STARTED: {
    REGISTERED: 'REGISTERED_USER_DELETE_STARTED',
    ALL: 'ALL_USER_DELETE_STARTED'
  },
  USER_DELETE_FINISHED: {
    REGISTERED: 'REGISTERED_USER_DELETE_FINISHED',
    ALL: 'ALL_USER_DELETE_FINISHED'
  },
  USER_DELETE_CANCELED: {
    REGISTERED: 'REGISTERED_USER_DELETE_CANCELED',
    ALL: 'ALL_USER_DELETE_CANCELED'
  },
  USER_ADD_STARTED: {
    ALL: 'ALL_USER_ADD_STARTED'
  },
  USER_ADD_PARTIAL: {
    ALL: 'ALL_USER_ADD_PARTIAL'
  },
  USER_ADD_PARTIAL_FINISHED: {
    ALL: 'ALL_USER_ADD_PARTIAL_FINISHED'
  },
  USER_ADD_BULK: {
    ALL: 'ALL_USER_ADD_BULK'
  },
  USER_ADD_BULK_ADD_FILE_STARTED: {
    ALL: 'ALL_USER_ADD_BULK_ADD_FILE_STARTED'
  },
  USER_ADD_BULK_ADD_FILE_FAIL: {
    ALL: 'ALL_USER_ADD_BULK_ADD_FILE_FAIL'
  },
  USER_ADD_BULK_FINISHED: {
    ALL: 'ALL_USER_ADD_BULK_FINISHED'
  },
  USER_ADD_CANCELED: {
    ALL: 'ALL_USER_ADD_CANCELED'
  },
  ADD_FILE_SUCCESS: {
    ALL: 'ALL_USER_ADD_BULK_ADD_FILE_SUCCESS'
  }
}

export const PUBLICATION_AMPLITUDE_EVENTS = {
  SEARCH: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_SEARCH',
    PLANNED: 'SCHEDULED_PUBLICATION_SEARCH'
  },
  POSITION_FILTER: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_POSITION_FILTER',
    PLANNED: 'SCHEDULED_PUBLICATION_POSITION_FILTER'
  },
  CITY_FILTER: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_CITY_FILTER',
    PLANNED: 'SCHEDULED_PUBLICATION_CITY_FILTER'
  },
  DATE_FILTER: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_DATE_FILTER',
    PLANNED: 'SCHEDULED_PUBLICATION_DATE_FILTER'
  },
  DELETE: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_DELETE',
    PLANNED: 'SCHEDULED_PUBLICATION_DELETE'
  },
  COPY: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_COPY',
    PLANNED: 'SCHEDULED_PUBLICATION_COPY'
  },
  EDIT_STARTED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_STARTED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_STARTED'
  },
  EDIT_SAVE_STARTED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_SAVE_STARTED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SAVE_STARTED'
  },
  EDIT_SAVE_CANCELED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_SAVE_CANCELED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SAVE_CANCELED'
  },
  EDIT_SAVE_FINISHED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_SAVE_FINISHED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SAVE_FINISHED'
  },
  CREATE_SCHEDULE_STARTED: {
    CREATE: 'PUBLICATION_CREATE_SCHEDULE_STARTED'
  },
  CREATE_SCHEDULE_CANCELED: {
    CREATE: 'PUBLICATION_CREATE_SCHEDULE_CANCELED'
  },
  CREATE_SCHEDULE_FINISHED: {
    CREATE: 'PUBLICATION_CREATE_SCHEDULE_FINISHED'
  },
  CREATE_PUBLISH_STARTED: {
    CREATE: 'PUBLICATION_CREATE_PUBLISH_STARTED'
  },
  CREATE_PUBLISH_CANCELED: {
    CREATE: 'PUBLICATION_CREATE_PUBLISH_CANCELED'
  },
  CREATE_PUBLISH_FINISHED: {
    CREATE: 'PUBLICATION_CREATE_PUBLISH_FINISHED'
  },
  CREATE_BACK_STARTED: {
    CREATE: 'PUBLICATION_CREATE_BACK_STARTED'
  },
  CREATE_BACK_CANCELED: {
    CREATE: 'PUBLICATION_CREATE_BACK_CANCELED'
  },
  CREATE_BACK_FINISHED: {
    CREATE: 'PUBLICATION_CREATE_BACK_FINISHED'
  },
  CREATE_CANCEL_STARTED: {
    CREATE: 'PUBLICATION_CREATE_CANCEL_STARTED'
  },
  CREATE_CANCEL_FINISHED: {
    CREATE: 'PUBLICATION_CREATE_CANCEL_FINISHED'
  },
  CREATE_CANCEL_CANCELED: {
    CREATE: 'PUBLICATION_CREATE_CANCEL_CANCELED'
  },
  EDIT_SCHEDULE_STARTED: {
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SCHEDULE_STARTED'
  },
  EDIT_SCHEDULE_CANCELED: {
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SCHEDULE_CANCELED'
  },
  EDIT_SCHEDULE_FINISHED: {
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_SCHEDULE_FINISHED'
  },
  EDIT_BACK_STARTED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_BACK_STARTED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_BACK_STARTED'
  },
  EDIT_BACK_FINISHED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_BACK_FINISHED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_BACK_FINISHED'
  },
  EDIT_BACK_CANCELED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_BACK_CANCELED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_BACK_CANCELED'
  },
  EDIT_CANCEL_STARTED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_CANCEL_STARTED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_CANCEL_STARTED'
  },
  EDIT_CANCEL_FINISHED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_CANCEL_FINISHED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_CANCEL_FINISHED'
  },
  EDIT_CANCEL_CANCELED: {
    PUBLISHED: 'PUBLISHED_PUBLICATION_EDIT_CANCEL_CANCELED',
    PLANNED: 'SCHEDULED_PUBLICATION_EDIT_CANCEL_CANCELED'
  },
}
