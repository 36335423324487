export interface ModifierInterface {
    condition: any;
    value: string;
}

export const GetModifiers = (
    defaultClass: string,
    modifiers: ModifierInterface[],
    separator: string = '_',
): string => {
    const classNames: string[] = [defaultClass];
    modifiers.forEach((modifier) => {
        if (modifier.condition) {
            classNames.push(`${defaultClass}${separator}${modifier.value}`);
        }
    });

    return classNames.join(' ');
};