import {GetMobileUsersParamsInterface, UpdateMobileUserDataInterface} from "../../api/MobileUsers";
import {PayloadWithCallback} from "../../interfaces/store";
import {MOBILE_USERS_CATEGORY} from "../../containers/MobileUsers/constants";
import {CreateAuthMobileUserDataInterface} from "../../api/auth/MobileUsers";

export const ACTION_SET_MOBILE_USERS = 'ACTION_SET_MOBILE_USERS';

export const setMobileUsersAction = data => ({
  type: ACTION_SET_MOBILE_USERS,
  payload: data,
});

export const MOBILE_USERS_GET_REQUESTED = 'MOBILE_USERS_GET_REQUESTED';

export interface MobileUsersGetRequestedPayload {
  dateSince?: string;
  dateTill?: string;
  isSearch?: boolean;
  activeUsersCategory?: string;
  isPositionFilter?: boolean;
  isCityFilter?: boolean;
  isDateFilter?: boolean;
  positionLabels?: string[];
  cityLabels?: string[];
  category?: MOBILE_USERS_CATEGORY;
  data: GetMobileUsersParamsInterface;
}

export const mobileUsersGetRequestedAction = (data: MobileUsersGetRequestedPayload) => ({
  type: MOBILE_USERS_GET_REQUESTED,
  payload: data,
});

export interface MobileUserUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: UpdateMobileUserDataInterface;
  category?: MOBILE_USERS_CATEGORY;
}

export const MOBILE_USER_UPDATE_REQUESTED = 'MOBILE_USER_UPDATE_REQUESTED';

export const mobileUserUpdateRequestedAction = (data: MobileUserUpdateRequestedPayload) => ({
  type: MOBILE_USER_UPDATE_REQUESTED,
  payload: data,
});

export interface MobileUserDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
  category?: MOBILE_USERS_CATEGORY;
}

export const MOBILE_USER_DELETE_REQUESTED = 'MOBILE_USER_DELETE_REQUESTED';

export const mobileUserDeleteRequestedAction = (data: MobileUserDeleteRequestedPayload) => ({
  type: MOBILE_USER_DELETE_REQUESTED,
  payload: data,
});

export const AUTH_MOBILE_USER_CREATE_REQUESTED = 'AUTH_MOBILE_USER_CREATE_REQUESTED';

export interface AuthMobileUserCreateRequestedPayload extends PayloadWithCallback {
  data: CreateAuthMobileUserDataInterface,
}

export const authMobileUserCreateRequestedAction = (data: AuthMobileUserCreateRequestedPayload) => ({
  type: AUTH_MOBILE_USER_CREATE_REQUESTED,
  payload: data,
});
