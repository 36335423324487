import {call, put, takeLatest} from 'redux-saga/effects';

import {BILLING_SUBSCRIBE_REQUESTED, BILLING_INFO_GET_REQUESTED, setBillingInfoAction} from '../billing/actions';
import {createNotificationAction} from '../notifications/actions';
import {BillingInfoInterface, GET_BILLING_INFO, SUBSCRIBE_BILLING} from '../../api/Billing';

function* getBillingInfo() {
    try {
        const data: BillingInfoInterface = (yield call(GET_BILLING_INFO)).data;

        yield put(setBillingInfoAction(data));
    } catch (error) {
        console.error(error);
    }
}

function* subscribeBilling(action) {
    try {
        const response = yield call(SUBSCRIBE_BILLING, action.payload.body);

        if (response.data.success) {
            if (action.payload.callback) {
                action.payload.callback();
            }
        } else {
            yield put(createNotificationAction({ text: 'BILLING_NOTIFICATION.CHANGE_PLAN_FAILED'}));
        }
    } catch (error) {
        console.error(error);
    }
}

function* billingSaga() {
    yield takeLatest(BILLING_INFO_GET_REQUESTED, getBillingInfo);
    yield takeLatest(BILLING_SUBSCRIBE_REQUESTED, subscribeBilling);
}


export default billingSaga;
