import {UPLOAD_FILE, UploadFileResponse} from "../../api/Files";

export const PrepareNewsToUpload = async (formData: FormData): Promise<FormData> => {
    const attachment: string = formData.get('attachment') as string;
    if (attachment) {
        const attachmentFormData: FormData = new FormData();
        attachmentFormData.append('file', attachment);
        const imageData: UploadFileResponse = (await UPLOAD_FILE(attachmentFormData)).data;
        const imageId: string = imageData.model.id;
        formData.append('attachment_id', imageId);
        formData.delete('attachment');
    }
    return formData;
};