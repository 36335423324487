import { TokensInterface } from "./Auth";
import { DefaultApi } from "./index";

export interface AuthRegistrationParams {
    display_name: String;
    locale: String;
    email: String;
    password: String;
    name: String;
}

export const REGISTRATION_API = (display_name: String, locale: String, email: String, password: String, name: String) => {
    return DefaultApi.post<TokensInterface>("/registrations", {
        display_name,
        locale,
        email,
        password,
        name,
    });
};
