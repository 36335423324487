import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './users/reducer';
import authReducer from "./auth/reducer";
import billingReducer from "./billing/reducer";
import coursesReducer from "./courses/reducer";
import compilationsReducer from "./compilations/reducer";
import colorsReducer from "./colors/reducer";
import citiesReducer from "./cities/reducer";
import dashboardsReducer from "./dashboards/reducer";
import positionsReducer from "./positions/reducer";
import lessonsReducer from "./lessons/reducer";
import testsReducer from "./tests/reducer";
import filesReducer from "./files/reducer";
import feedbackReducer from "./feedback/reducer";
import notificationsReducer from "./notifications/reducer";
import metaBaseReducer from "./metaBase/reducer";
import mobileUserReducer from "./mobileUsers/reducer";
import mobileUsersImportReducer from "./mobileUsersImport/reducer";
import levelsReducer from "./levels/reducer";
import tenantReducer from "./tenant/reducer";
import newsReducer from "./news/reducer";
import layoutReducer from "./layout/reducer";
import unsplashReducer from "./unsplash/reducer";
import fileUploadsReducer from "./fileUploads/reducer";
import registrationReducer from "./authRegistration/reducer";

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users: userReducer,
  mobileUsers: mobileUserReducer,
  mobileUsersImport: mobileUsersImportReducer,
  auth: authReducer,
  billing: billingReducer,
  courses: coursesReducer,
  compilations: compilationsReducer,
  colors: colorsReducer,
  cities: citiesReducer,
  dashboards: dashboardsReducer,
  positions: positionsReducer,
  lessons: lessonsReducer,
  tests: testsReducer,
  files: filesReducer,
  feedback: feedbackReducer,
  notifications: notificationsReducer,
  metaBase: metaBaseReducer,
  levels: levelsReducer,
  tenant: tenantReducer,
  news: newsReducer,
  layout: layoutReducer,
  unsplash: unsplashReducer,
  fileUploads: fileUploadsReducer,
  registration: registrationReducer
});

export default rootReducer;

