import {AuthApi} from "./index";

const LEVELS_API_URL = 'tenant';

export type TenantAppConfigType = {
    [key: string]: string;
    awards: string;
    profile: string;
    ratings: string;
}

export type TenantScoreConfigType = {
    [key: string]: number;
}

export interface TenantInterface {
    id: string;
    display_name: string;
    app_config: TenantAppConfigType;
    score_config: TenantScoreConfigType;
    locale?: string;
}

export const GET_TENANT = () => {
    return AuthApi.get<TenantInterface>(LEVELS_API_URL);
};

export const UPDATE_TENANT = (body: TenantInterface) => {
    return AuthApi.patch(LEVELS_API_URL, body);
};
