import {
  ACTION_SET_COLORS,
} from './actions';
import {ColorInterface} from "../../api/Colors";

interface StateInterface {
  colors: ColorInterface[];
}

const defaultState: StateInterface = {
  colors: [],
};

const colorsReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_COLORS: {
      return setColors(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setColors(state, payload: ColorInterface[]) {
  return {
    ...state,
    colors: payload,
  }
}

export default colorsReducer;