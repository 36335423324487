import {LevelInterface} from "../../api/Levels";
import {TenantInterface} from "../../api/Tenant";
import {ACTION_SET_TENANT} from "./actions";

interface StateInterface {
  tenant: TenantInterface;
}

const defaultState: StateInterface = {
  tenant: null,
};

const tenantReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_TENANT: {
      return setTenant(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setTenant(state, payload: LevelInterface[]) {
  return {
    ...state,
    tenant: payload,
  }
}

export default tenantReducer;