import {PayloadWithCallback} from "../../interfaces/store";
import {LongreadSectionInterface, LongreadSectionToPrepareInterface} from "../../components/Longread/constants";

export interface SectionCreateRequestedPayload extends PayloadWithCallback {
  stepId: string;
  data: LongreadSectionInterface;
}

export const SECTION_CREATE_REQUESTED = 'SECTION_CREATE_REQUESTED';

export const sectionCreateRequestedAction = (data: SectionCreateRequestedPayload) => ({
  type: SECTION_CREATE_REQUESTED,
  payload: data,
});

export const SECTION_UPDATE_REQUESTED = 'SECTION_UPDATE_REQUESTED';

export interface SectionUpdateRequestedPayload extends PayloadWithCallback {
  data: LongreadSectionToPrepareInterface;
}

export const sectionUpdateRequestedAction = (data: SectionUpdateRequestedPayload) => ({
  type: SECTION_UPDATE_REQUESTED,
  payload: data,
});

export interface SectionDeleteRequestedPayload {
  id: string;
}

export const SECTION_DELETE_REQUESTED = 'SECTION_DELETE_REQUESTED';

export const sectionDeleteRequestedAction = (data: SectionDeleteRequestedPayload) => ({
  type: SECTION_DELETE_REQUESTED,
  payload: data,
});