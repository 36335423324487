import {
  ACTION_SET_CITIES, ACTION_SET_CITIES_META,
  ACTION_SET_CITY,
} from './actions';
import {CityInterface} from "../../api/Cities";
import {EmptyObjectType} from "../../interfaces/default";
import {PaginationMetaInterface} from "../../api/interface";
import {PAGES_DEFAULT_META} from "../../constants/pages";

interface StateInterface {
  cities: CityInterface[];
  meta: PaginationMetaInterface;
  city: EmptyObjectType<CityInterface>;
}

const defaultState: StateInterface = {
  cities: [],
  meta: PAGES_DEFAULT_META,
  city: {},
};

const citiesReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_CITIES: {
      return setCities(state, action.payload);
    }
    case ACTION_SET_CITIES_META: {
      return setMeta(state, action.payload);
    }
    case ACTION_SET_CITY: {
      return setCity(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setCities(state, payload: CityInterface[]) {
  return {
    ...state,
    cities: payload,
  }
}

function setMeta(state, payload: PaginationMetaInterface) {
  return {
    ...state,
    meta: payload,
  }
}

function setCity(state, payload: CityInterface) {
  return {
    ...state,
    city: payload,
  }
}

export default citiesReducer;