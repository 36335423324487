import {LessonInterface} from "../../api/Lessons";
import {PrepareScreenplayDate} from "../../utils/date";

export const PrepareCourseContentPositionsToUpload = (content: LessonInterface[]): FormData => {
    const formData = new FormData();
    content.forEach((lesson, index) => {
        formData.append('lessons_attributes[][id]', lesson.id);
        formData.append('lessons_attributes[][position]', JSON.stringify(index + 1));
    });
    return formData;
};

export const PrepareLessonToDisplay = (lesson: LessonInterface): any => {
    return {
        ...lesson,
        lastUpdateDate: PrepareScreenplayDate(lesson.script_updated_at || lesson.updated_at),
    };
};