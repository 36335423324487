import {PayloadWithCallback} from "../../interfaces/store";
import {CreateLevelBody, LevelInterface, UpdateLevelBody} from "../../api/Levels";

export const ACTION_SET_LEVELS: string = 'ACTION_SET_LEVELS';

export const setLevelsAction = (data: LevelInterface[]) => ({
  type: ACTION_SET_LEVELS,
  payload: data,
});

export const LEVELS_GET_REQUESTED: string = 'LEVELS_GET_REQUESTED';

export const levelsGetRequestedAction = () => ({
  type: LEVELS_GET_REQUESTED,
});

export interface LevelCreateRequestedInterface extends PayloadWithCallback {
  body: CreateLevelBody;
}

export const LEVEL_CREATE_REQUESTED = 'LEVEL_CREATE_REQUESTED';

export const levelCreateRequestedAction = (data: LevelCreateRequestedInterface) => ({
  type: LEVEL_CREATE_REQUESTED,
  payload: data,
});

export interface LevelUpdateRequestedInterface extends PayloadWithCallback {
  id: string;
  body: UpdateLevelBody;
}

export const LEVEL_UPDATE_REQUESTED = 'LEVEL_UPDATE_REQUESTED';

export const levelUpdateRequestedAction = (data: LevelUpdateRequestedInterface) => ({
  type: LEVEL_UPDATE_REQUESTED,
  payload: data,
});

export interface LevelDeleteRequestedInterface extends PayloadWithCallback {
  id: string;
}

export const LEVEL_DELETE_REQUESTED = 'LEVEL_DELETE_REQUESTED';

export const levelDeleteRequestedAction = (data: LevelDeleteRequestedInterface) => ({
  type: LEVEL_DELETE_REQUESTED,
  payload: data,
});