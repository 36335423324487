import {AuthApi} from "./index";
import {PaginationMetaInterface} from "./interface";

const CITIES_API_URL = 'cities';

export enum CITIES_VIEW_PARAMS {
    WITH_COURSES = 'with_courses',
}

export interface GetCitiesParams {
    view?: CITIES_VIEW_PARAMS;
    display_name?: string;
    paginate?: boolean;
    limit?: number;
    offset?: number;

}

export interface CityInterface {
    id: string;
    display_name: string;
    courses_count?: number;
}

export interface GetCitiesDataInterface {
    cities: CityInterface[];
    meta?: PaginationMetaInterface;
}

export const GET_CITIES = (params: GetCitiesParams) => {
    return AuthApi.get<GetCitiesDataInterface>(`${CITIES_API_URL}`, {
        params: {
            ...params,
        }
    });
};

export const GET_CITY = (id: string) => {
    return AuthApi.get<CityInterface>(`${CITIES_API_URL}/${id}`);
};

export interface AddCityBody {
    display_name: string;
}

export const ADD_CITY = (body: AddCityBody) => {
    return AuthApi.post(`${CITIES_API_URL}`, body);
};

export interface UpdateCityBody {
    display_name: string;
}

export const UPDATE_CITY = (id: string, body: UpdateCityBody) => {
    return AuthApi.patch(`${CITIES_API_URL}/${id}`, body);
};

export const DELETE_CITY = (id: string) => {
    return AuthApi.delete(`${CITIES_API_URL}/${id}`);
};