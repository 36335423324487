import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CompilationInterface,
    CREATE_COMPILATION,
    DELETE_COMPILATION,
    GET_ALL_COMPILATIONS,
    GET_COMPILATION,
    CompilationsDataInterface,
    UPDATE_COMPILATION, CreateCompilationResponse, UpdateCompilationResponse,
} from "../../api/Compilations";
import {
    COMPILATION_CREATE_REQUESTED,
    COMPILATION_COPY_REQUESTED,
    COMPILATION_DELETE_REQUESTED,
    COMPILATION_GET_REQUESTED,
    COMPILATION_UPDATE_REQUESTED,
    COMPILATIONS_GET_REQUESTED,
    setCompilationAction,
    setCompilationsAction,
    CompilationsGetRequestedPayload,
    CompilationCopyRequestedPayload,
    CompilationUpdateRequestedPayload,
} from "../compilations/actions";
import {createNotificationAction} from "../notifications/actions";
import {DefaultAction} from "../interface";
import {AmplitudeEventDataInterface, LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";

function* getAllCompilations(action: DefaultAction<CompilationsGetRequestedPayload>) {
    try {
        const params = action.payload.data;
        const responseData: CompilationsDataInterface = (yield call(GET_ALL_COMPILATIONS, params)).data;
        const totalResultsParam: AmplitudeEventDataInterface = {
            number_of_results_returned: responseData.meta && responseData.meta.total,
        };
        if (action.payload.isSearch) {
            LogAmplitudeEvent(params.published
                ? AMPLITUDE_EVENT.PUBLISHED_COMPILATION_SEARCH
                : params.archived
                    ? AMPLITUDE_EVENT.ARCHIVED_COMPILATION_SEARCH
                    : AMPLITUDE_EVENT.SAVED_COMPILATION_SEARCH, {
                keywords: params.name,
                ...totalResultsParam,
            });
        }
        if (action.payload.isPositionFilter) {
            LogAmplitudeEvent(params.published
                ? AMPLITUDE_EVENT.PUBLISHED_COMPILATION_POSITION_FILTER
                : params.archived
                    ? AMPLITUDE_EVENT.ARCHIVED_COMPILATION_POSITION_FILTER
                    : AMPLITUDE_EVENT.SAVED_COMPILATION_POSITION_FILTER, {
                positions: action.payload.positionLabels,
                ...totalResultsParam,
            });
        }
        if (action.payload.isCityFilter) {
            LogAmplitudeEvent(params.published
                ? AMPLITUDE_EVENT.PUBLISHED_COMPILATION_CITY_FILTER
                : params.archived
                    ? AMPLITUDE_EVENT.ARCHIVED_COMPILATION_CITY_FILTER
                    : AMPLITUDE_EVENT.SAVED_COMPILATION_CITY_FILTER, {
                city: action.payload.cityLabels,
                ...totalResultsParam,
            });
        }
        yield put(setCompilationsAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* getCompilation(action) {
    try {
        const responseData: CompilationInterface = (yield call(GET_COMPILATION, action.payload.id)).data;
        yield put(setCompilationAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* createCompilation(action) {
    try {
        const responseData: CreateCompilationResponse = (yield call(CREATE_COMPILATION, action.payload.data)).data;
        LogAmplitudeEvent(responseData.model.published
            ? AMPLITUDE_EVENT.COMPILATION_CREATE_PUBLISHED
            : AMPLITUDE_EVENT.COMPILATION_CREATE_SAVED, {
            compilation_id: responseData.model.id,
            compilation_name: responseData.model.name,
        });

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* copyCompilation(action: DefaultAction<CompilationCopyRequestedPayload>) {
    try {
        const preparedCompilationData: CompilationInterface = (yield call(GET_COMPILATION, action.payload.id)).data;
        const formData: FormData = new FormData();
        formData.append('name', `${action.payload.t('DEFAULT_WORDS.COPY')} ${preparedCompilationData.name}`);
        formData.append('color_id', preparedCompilationData.color.id);
        formData.append('published', JSON.stringify(preparedCompilationData.published));
        formData.append('archived', JSON.stringify(preparedCompilationData.archived));
        formData.append('attachment_id', preparedCompilationData.attachment_id);
        preparedCompilationData.courses.forEach((course) => {
            formData.append('compilations_courses_attributes[][course_id]', course.id);
            formData.append('compilations_courses_attributes[][position]', (course.position).toString());
        });

        const responseData: CreateCompilationResponse = (yield call(CREATE_COMPILATION, formData)).data;
        LogAmplitudeEvent(preparedCompilationData.published
            ? AMPLITUDE_EVENT.PUBLISHED_COMPILATION_COPY
            : preparedCompilationData.archived
                ? AMPLITUDE_EVENT.ARCHIVED_COMPILATION_COPY
                : AMPLITUDE_EVENT.SAVED_COMPILATION_COPY, {
            compilation_id: action.payload.id,
            compilation_name: preparedCompilationData.name,
            compilation_copy_id: responseData.model.id,
            compilation_copy_name: responseData.model.name,
        });
        yield put(createNotificationAction({ text: 'COMPILATIONS.SUCCESS_COPY_NOTIFICATION'}));
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        yield put(createNotificationAction({ text: 'COMPILATIONS.FAILED_COPY_NOTIFICATION'}));
        LogAmplitudeEvent(action.payload.published
            ? AMPLITUDE_EVENT.PUBLISHED_COMPILATION_COPY_FAIL
            : action.payload.archived
                ? AMPLITUDE_EVENT.ARCHIVED_COMPILATION_COPY_FAIL
                : AMPLITUDE_EVENT.SAVED_COMPILATION_COPY_FAIL, {
            compilation_id: action.payload.id,
            compilation_name: action.payload.name,
            error_type: error.toString(),
        });
        console.log(error);
    }
}

function* updateCompilation(action: DefaultAction<CompilationUpdateRequestedPayload>) {
    try {
        const responseData: UpdateCompilationResponse = (yield call(UPDATE_COMPILATION, action.payload.id, action.payload.data)).data;

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteCompilation(action) {
    try {
        yield call(DELETE_COMPILATION, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* compilationsSaga() {
    yield takeLatest(COMPILATIONS_GET_REQUESTED, getAllCompilations);
    yield takeLatest(COMPILATION_GET_REQUESTED, getCompilation);
    yield takeLatest(COMPILATION_CREATE_REQUESTED, createCompilation);
    yield takeLatest(COMPILATION_COPY_REQUESTED, copyCompilation);
    yield takeLatest(COMPILATION_UPDATE_REQUESTED, updateCompilation);
    yield takeLatest(COMPILATION_DELETE_REQUESTED, deleteCompilation);
}

export default compilationsSaga;