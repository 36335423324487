import {AuthApi} from "./index";
import {ColorInterface} from "./Colors";
import {PositionInterface} from "./Positions";
import {PaginationMetaInterface} from "./interface";
import {CityInterface} from "./Cities";
import {LessonContentType} from "../containers/LessonForm/constants";
import {GlossaryCardInterface} from "../containers/Screenplay/constants";

const COURSES_API_URL: string = 'courses';

export interface GetAllCoursesParamsInterface {
    name?: string;
    page?: number;
    per_page?: number;
    sort?: string;
    order?: string;
    published?: boolean;
    archived?: boolean;
    limit?: number;
    offset?: number;
    position?: string;
    city?: string;
}

export interface CoursesInterface {
    archived: boolean;
    attachment_id: string;
    cities: CityInterface[];
    compilation_names: string[];
    cover_url: string;
    created_at: string;
    description: string;
    id: string;
    lessons_count: number;
    name: string;
    positions: PositionInterface[];
    published: boolean;
    tests_count: number;
}

export interface CoursesDataInterface {
    courses: CoursesInterface[],
    meta: PaginationMetaInterface;
}

export const GET_ALL_COURSES = (params: GetAllCoursesParamsInterface = {}) => {
    return AuthApi.get<CoursesDataInterface>(`${COURSES_API_URL}`, {
        params,
    });
};

interface CourseCompilationsResponse {
    archived: boolean;
    attachment_id: string;
    color: ColorInterface;
    courses_count: number;
    created_at: string;
    icon_url: string;
    id: string;
    name: string;
    published: boolean;
}

export interface CourseInterface {
    archived: boolean;
    attachment_id: string;
    cities: CityInterface[];
    compilations: CourseCompilationsResponse[];
    cover_url: string;
    created_at: string;
    description: string;
    id: string;
    lessons_count: number;
    name: string;
    positions: PositionInterface[];
    published: boolean;
    tests_count: number;
}

export const GET_COURSE = (id: string) => {
    return AuthApi.get<CourseInterface>(`${COURSES_API_URL}/${id}`);
};

export interface LessonSectionAttachmentInterface {
    created_at: string;
    display_name: string;
    download_thumbnail_url: string;
    download_url: string;
    duration: number;
    id: string;
    tenant_id: string;
    updated_at: string;
}

export interface LessonSectionAttachmentAttributesInterface {
    display_name: string;
    url: string;
    file: File;
    duration?: number;
    _destroy?: boolean;
}

export interface LessonGlossaryCardInterface {
    title: string;
    text: string;
}

export interface LessonSectionInterface {
    content: null | {
        text?: string;
        title?: string;
        cards?: GlossaryCardInterface[];
    };
    kind: LessonContentType;
    position?: number;
    id?: string;
    attachment?: LessonSectionAttachmentInterface;
    attachment_id?: string;
    attachment_url?: string;
    attachment_attributes: LessonSectionAttachmentAttributesInterface;
    new?: boolean;
    _destroy?: boolean;
}

export const GET_COURSE_CONTENT = (id: string) => {
    return AuthApi.get(`${COURSES_API_URL}/${id}/content`);
};

interface CreateCourseDataInterface {
    name: string;
    description: string;
    attachment: any;
    city_ids: string[];
    position_ids: string[];
}

export interface CreateCourseDataResponse {
    success: boolean;
    model: {
        attachment_id: string;
        continuous_lessons?: boolean;
        blocked_lessons?: boolean;
        cover_url: string;
        created_at: string;
        description: string;
        id: string;
        lessons_count: number;
        name: string;
        tests_count: number;
        thumbnail_cover_url: string;
    },
    errors: any;
}

export const CREATE_COURSE = (data: CreateCourseDataInterface) => {
    return AuthApi.post<CreateCourseDataResponse>(`${COURSES_API_URL}`, data);
};

export interface CopyCourseDataResponse {
    success: boolean;
    model: {
        id: string;
        name: string;
    },
    errors: any;
}

export const COPY_COURSE = (id: string) => {
    return AuthApi.post<CopyCourseDataResponse>(`${COURSES_API_URL}/${id}/copy`);
};

interface UpdateCourseDataInterface {
    name: string;
    description: string;
    attachment: any;
    archived: boolean;
    published: boolean;
}

export const UPDATE_COURSE = (id: string, data: UpdateCourseDataInterface | FormData, ) => {
    return AuthApi.patch(`${COURSES_API_URL}/${id}`, data);
};

export const DELETE_COURSE = (id: string) => {
    return AuthApi.delete(`${COURSES_API_URL}/${id}`);
};
