import {AuthApi} from "./index";
import {PositionInterface} from "./Positions";
import {CityInterface} from "./Cities";
import {PaginationMetaInterface} from "./interface";
import {CoursesInterface} from "./Courses";

const NEWS_API_URL = 'publications';

export interface GetNewsParamsInterface {
    name?: string;
    position?: string;
    city?: string;
    published_at_since?: string;
    published_at_till?: string;
    limit?: number;
    offset?: number;
    published?: boolean;
    paginate?: boolean;
}

export interface NewsInterface {
    publish_at: string;
    cover_url: string;
    attachment_id: string;
    id: string;
    name: string;
    text: string;
    user: {
        name: string;
        email: string;
    };
    published: boolean;
    positions: PositionInterface[];
    cities: CityInterface[];
    courses: CoursesInterface[];
}


export class NewsClass implements NewsInterface {
    publish_at = '';
    cover_url = '';
    attachment_id = '';
    id = '';
    name = '';
    text = '';
    user = {
        name: '',
        email: '',
    };
    published = false;
    positions = [];
    cities = [];
    courses = [];
}

export interface NewsDataInterface {
    publications: NewsInterface[],
    meta: PaginationMetaInterface;
}

export const GET_NEWS = (params: GetNewsParamsInterface = {}) => {
    return AuthApi.get<NewsDataInterface>(NEWS_API_URL, {
        params,
    });
};

export const GET_ONE_NEWS = (id: string) => {
    return AuthApi.get<NewsInterface>(`${NEWS_API_URL}/${id}`);
};

export interface CreateNewsResponse {
    success: boolean;
    model: NewsInterface,
    errors: any;
}

export const CREATE_NEWS = (data: FormData) => {
    return AuthApi.post<CreateNewsResponse>(NEWS_API_URL, data);
};

export interface UpdateNewsResponse {
    success: boolean;
    model: NewsInterface,
    errors: any;
}

export const UPDATE_NEWS = (id: string, data: FormData, ) => {
    return AuthApi.patch<UpdateNewsResponse>(`${NEWS_API_URL}/${id}`, data);
};

export const PUBLISH_NEWS = (id: string) => {
    return AuthApi.patch<UpdateNewsResponse>(`${NEWS_API_URL}/${id}/publish`);
};

export interface DeleteNewsResponse {
    success: boolean;
    model: NewsInterface,
    errors: any;
}

export const DELETE_NEWS = (id: string) => {
    return AuthApi.delete<DeleteNewsResponse>(`${NEWS_API_URL}/${id}`);
};