import axios from "axios";
import {API_URL, AUTH_API_URL} from "./constants";
import {getLocalStorageItem, removeLocalStorageItem} from "../utils/localStorage";
import {LOCAL_STORAGE_KEYS} from "../constants/localStorage";
import { ROUTE_404, ROUTE_500, ROUTE_AUTH, ROUTE_AUTH_REGISTRATION } from "../constants/routes";

const DefaultResponseInterceptor = (error) => {
    if ([521,].includes(error.response.status)) {
        return Promise.reject(error);
    }
    if ((!error.response
        || ((error.response.status >= 500) && (error.response.status < 600)))
        && window.location.pathname !== ROUTE_500) {
        window.location.replace(ROUTE_500);
    }
    if ([404,].includes(error.response.status) && window.location.pathname !== ROUTE_404) {
        window.location.replace(ROUTE_404);
    }
    return Promise.reject(error);
};

const DefaultApi = axios.create({
    baseURL: API_URL,
});

DefaultApi.interceptors.response.use(function (response) {
    return response;
}, DefaultResponseInterceptor);

const AuthorizedResponseInterceptor = (error) => {
    if ((!error.response
        || ((error.response.status >= 500) && (error.response.status < 600)))
        && window.location.pathname !== ROUTE_500) {
        window.location.replace(ROUTE_500);
    }
    if ([401,].includes(error.response.status) && window.location.pathname !== ROUTE_AUTH && window.location.pathname !== ROUTE_AUTH_REGISTRATION) {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.EMAIL);
        window.location.replace(ROUTE_AUTH);
    }
    if ([404,].includes(error.response.status) && window.location.pathname !== ROUTE_404) {
        window.location.replace(ROUTE_404);
    }
    return Promise.reject(error);
};

const AuthApi = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: `Bearer ${getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)}`,
    },
});

AuthApi.interceptors.response.use(function (response) {
    return response;
}, AuthorizedResponseInterceptor);

const AuthServiceApi = axios.create({
    baseURL: AUTH_API_URL,
    headers: {
        Authorization: `Bearer ${getLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)}`,
    },
});

AuthServiceApi.interceptors.response.use(function (response) {
    return response;
}, AuthorizedResponseInterceptor);

export {
    AuthApi,
    DefaultApi,
    AuthServiceApi,
};
