import {AuthApi} from "./index";
import {UsersDataInterface} from "../store/users/interface";
import {SORT_ORDER} from "../constants/sort";

const MOBILE_USERS_API_URL = 'mobile_users';

export enum GetMobileUsersParamsSort {
    POSITION = 'position',
    CREATED_AT = 'created_at',
    CITY = 'city',
    NAME = 'name',
    PHONE = 'phone',
}

export interface GetMobileUsersParamsInterface {
    [GetMobileUsersParamsSort.NAME]?: string;
    [GetMobileUsersParamsSort.PHONE]?: string;
    [GetMobileUsersParamsSort.CITY]?: string;
    [GetMobileUsersParamsSort.POSITION]?: string;
    since?: string;
    till?: string;
    limit?: number;
    offset?: number;
    paginate?: boolean;
    order?: SORT_ORDER;
    sort?: GetMobileUsersParamsSort;
}

export const GET_MOBILE_USERS = (params: GetMobileUsersParamsInterface = {}) => {
    return AuthApi.get<UsersDataInterface>(MOBILE_USERS_API_URL, {
        params,
    });
};

export interface UpdateMobileUserDataInterface {
    name: string;
    city_id: string;
    position_id: string;
    phone?: string;
    external_id?: string;
}

export const UPDATE_MOBILE_USER = (id: string, data: UpdateMobileUserDataInterface) => {
    return AuthApi.patch(`${MOBILE_USERS_API_URL}/${id}`, data);
};

export const DELETE_MOBILE_USER = (id: string) => {
    return AuthApi.delete(`${MOBILE_USERS_API_URL}/${id}`);
};