import {AuthApi} from "./index";
import {ApiDefaultResponse} from "../interfaces/api";

export interface TestInterface {
    id: string
    step_id: string;
    name: string;
    description: string;
    success_text: string;
    fail_text: string;
    right_answers_count: number;
    show_right_answers: boolean;
    show_hints: boolean;
    answer_time: number;
    cover_url: string;
    published: boolean;
    questions: any[];
}

const TESTS_API_URL: string = 'tests';

export const GET_TEST = (id: string) => {
    return AuthApi.get<TestInterface>(`${TESTS_API_URL}/${id}`);
};

export const CREATE_TEST = (data: FormData) => {
    return AuthApi.post<ApiDefaultResponse<TestInterface>>(`${TESTS_API_URL}`, data);
};

export const UPDATE_TEST = (id: string, data: FormData) => {
    return AuthApi.patch<ApiDefaultResponse<TestInterface>>(`${TESTS_API_URL}/${id}`, data);
};

export const DELETE_TEST = (id: string) => {
    return AuthApi.delete(`${TESTS_API_URL}/${id}`);
};