import {
    ConnectionInterface, ConnectionToUpdateInterface,
} from "../../containers/Screenplay/constants";
import {TransitionInterface} from "../../api/Transitions";

export const PrepareTransitionToUpload = (connection: ConnectionInterface
    | ConnectionToUpdateInterface): TransitionInterface => {
    const preparedTransition: TransitionInterface = {};
    if (typeof connection.text === 'string') {
        preparedTransition.button_text = connection.text;
    }
    if (connection.start?.hasOwnProperty('id')) {
        preparedTransition.step_id = connection.start.id;
    }
    if (connection.end?.hasOwnProperty('id')) {
        preparedTransition.next_step_id = connection.end.id;
    }
    if (connection.kind) {
        preparedTransition.kind = connection.kind;
    }
    if (connection.position) {
        preparedTransition.position = connection.position;
    }
    return preparedTransition;
};

export const PrepareTransitionToDisplay = (transition: TransitionInterface): ConnectionInterface => {
    return {
        position: transition.position,
        kind: transition.kind,
        start: {
            id: transition.step_id,
        },
        end: {
            id: transition.next_step_id,
        },
        id: transition.id,
        text: transition.button_text,
    };
};