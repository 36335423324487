import {AuthApi} from "./index";
import {ColorInterface} from "./Colors";
import {PositionInterface} from "./Positions";
import {CityInterface} from "./Cities";
import {PaginationMetaInterface} from "./interface";

export interface GetAllCompilationsParamsInterface {
    name?: string;
    position?: string;
    city?: string;
    limit?: number;
    offset?: number;
    published?: boolean;
    archived?: boolean;
    paginate?: boolean;
}

export interface CompilationInterface {
    attachment_id: string;
    created_at: string;
    icon_url: string;
    id: string;
    name: string;
    courses_count: number;
    published: boolean;
    archived: boolean;
    color: ColorInterface;
    positions: PositionInterface[];
}

export interface CompilationsDataInterface {
    compilations: CompilationInterface[],
    meta: PaginationMetaInterface;
}

export const GET_ALL_COMPILATIONS = (params: GetAllCompilationsParamsInterface = {}) => {
    return AuthApi.get<CompilationsDataInterface>(`compilations`, {
        params,
    });
};

interface CompilationCoursesPositions extends PositionInterface {
    created_at: string;
    tenant_id: string;
    update_id: string;
}

interface CompilationCoursesCities extends CityInterface {
    created_at: string;
    tenant_id: string;
    update_id: string;
}

export interface CompilationCoursesResponse {
    attachment_id: string;
    compilation_course_id: string;
    cover_url: string;
    created_at: string;
    description: string;
    id: string;
    name: string;
    thumbnail_cover_url: string;
    lessons_count: number;
    position: number;
    tests_count: number;
    published: boolean;
    archived: boolean;
    continuous_lessons?: boolean;
    blocked_lessons?: boolean;
    cities: CompilationCoursesCities[];
    positions: CompilationCoursesPositions[];
}

export interface CompilationInterface {
    attachment_id: string;
    created_at: string;
    icon_url: string;
    id: string;
    name: string;
    courses_count: number;
    archived: boolean;
    published: boolean;
    cities: CityInterface[];
    color: ColorInterface;
    courses: CompilationCoursesResponse[];
}

export const GET_COMPILATION = (id: string) => {
    return AuthApi.get<CompilationInterface>(`compilations/${id}`);
};

export interface CreateCompilationResponse {
    success: boolean;
    model: {
        id: string;
        attachment_id: string;
        icon_url: string;
        name: string;
        created_at: string;
        published: boolean;
        archived: boolean;
        courses_count: number;
        color: ColorInterface;
        cities: any[];
        courses: any[];
    },
    errors: any;
}

export const CREATE_COMPILATION = (data: FormData) => {
    return AuthApi.post<CreateCompilationResponse>(`compilations`, data);
};

export interface UpdateCompilationResponse {
    success: boolean;
    model: {
        id: string;
        color: ColorInterface;
        icon_path: string;
        name: string;
    },
    errors: any;
}

export const UPDATE_COMPILATION = (id: string, data: FormData | CompilationInterface, ) => {
    return AuthApi.patch<UpdateCompilationResponse>(`compilations/${id}`, data);
};

export interface DeleteCompilationResponse {
    success: boolean;
    model: {
        id: string;
        color: ColorInterface;
        icon_path: string;
        name: string;
    },
    errors: any;
}

export const DELETE_COMPILATION = (id: string) => {
    return AuthApi.delete<DeleteCompilationResponse>(`compilations/${id}`);
};
