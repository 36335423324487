import {CityInterface, GetCitiesParams, UpdateCityBody} from "../../api/Cities";
import {PayloadWithCallback} from "../../interfaces/store";
import {PaginationMetaInterface} from "../../api/interface";
import {EmptyObjectType} from "../../interfaces/default";

export const ACTION_SET_CITIES: string = 'ACTION_SET_CITIES';

export const setCitiesAction = (data: CityInterface[]) => ({
  type: ACTION_SET_CITIES,
  payload: data,
});

export const ACTION_SET_CITIES_META: string = 'ACTION_SET_CITIES_META';

export const setCitiesMetaAction = (data: PaginationMetaInterface) => ({
  type: ACTION_SET_CITIES_META,
  payload: data,
});

export const ACTION_SET_CITY = 'ACTION_SET_CITY';

export const setCityAction = data => ({
  type: ACTION_SET_CITY,
  payload: data,
});

export const CITIES_GET_REQUESTED: string = 'CITIES_GET_REQUESTED';

export interface CitiesGetRequestedPayload {
  data?: GetCitiesParams;
  isSearch?: boolean;
}

export const citiesGetRequestedAction = (data: CitiesGetRequestedPayload = {}) => ({
  type: CITIES_GET_REQUESTED,
  payload: data,
});

export const CITY_GET_REQUESTED = 'CITY_GET_REQUESTED';

export const cityGetRequestedAction = (data = {}) => ({
  type: CITY_GET_REQUESTED,
  payload: data,
});

export const CITY_ADD_REQUESTED = 'CITY_ADD_REQUESTED';

export const cityAddRequestedAction = (data = {}) => ({
  type: CITY_ADD_REQUESTED,
  payload: data,
});

export interface CityUpdateRequestedInterface extends PayloadWithCallback {
  id: string;
  body: UpdateCityBody;
}

export const CITY_UPDATE_REQUESTED = 'CITY_UPDATE_REQUESTED';

export const cityUpdateRequestedAction = (data: CityUpdateRequestedInterface) => ({
  type: CITY_UPDATE_REQUESTED,
  payload: data,
});

export interface CityDeleteRequestedInterface extends PayloadWithCallback {
  id: string;
}

export const CITY_DELETE_REQUESTED = 'CITY_DELETE_REQUESTED';

export const cityDeleteRequestedAction = (data: CityDeleteRequestedInterface) => ({
  type: CITY_DELETE_REQUESTED,
  payload: data,
});