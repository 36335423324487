import {ColorInterface} from "../../api/Colors";

export const ACTION_SET_COLORS = 'ACTION_SET_COLORS';

export const setColorsAction = (data: ColorInterface[]) => ({
  type: ACTION_SET_COLORS,
  payload: data,
});

export const COLORS_GET_REQUESTED = 'COLORS_GET_REQUESTED';

export const colorsGetRequestedAction = () => ({
  type: COLORS_GET_REQUESTED,
});