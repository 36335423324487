import {CoursesDataInterface, GetAllCoursesParamsInterface, LessonSectionInterface} from "../../api/Courses";
import {PayloadWithCallback} from "../../interfaces/store";
import {COURSE_CONTENT_TYPES} from "../../containers/CourseContent/constants";
import {LessonInterface} from "../../api/Lessons";

export const ACTION_SET_COURSES = 'ACTION_SET_COURSES';

export const setCoursesAction = (data: CoursesDataInterface) => ({
  type: ACTION_SET_COURSES,
  payload: data,
});

export const ACTION_SET_COURSE = 'ACTION_SET_COURSE';

export const setCourseAction = (data) => ({
  type: ACTION_SET_COURSE,
  payload: data,
});

export const ACTION_SET_COURSE_CONTENT = 'ACTION_SET_COURSE_CONTENT';

export const setCourseContentAction = data => ({
  type: ACTION_SET_COURSE_CONTENT,
  payload: data,
});

export const ACTION_UPDATE_NEW_COURSE = 'ACTION_UPDATE_NEW_COURSE';

export const updateNewCourseAction = data => ({
  type: ACTION_UPDATE_NEW_COURSE,
  payload: data,
});

export const ACTION_ADD_NEW_COURSE_CONTENT = 'ACTION_ADD_NEW_COURSE_CONTENT';

export interface AddNewCourseContentActionPayload {
  type: COURSE_CONTENT_TYPES;
  formData: {
    name: string;
    description: string;
    published: boolean;
    dataContent: LessonSectionInterface[];
    cover: string;
    coverName: string;
  };
  lesson: {
    name: string;
    published: boolean;
    id?: string;
  };
  test: {
    name: string;
    published: boolean;
    id?: string;
  };
  position?: number;
  isCopy?: boolean;
}

export const addNewCourseContentAction = (data: AddNewCourseContentActionPayload) => ({
  type: ACTION_ADD_NEW_COURSE_CONTENT,
  payload: data,
});

export const ACTION_UPDATE_NEW_COURSE_CONTENT = 'ACTION_UPDATE_NEW_COURSE_CONTENT';

export interface UpdateNewCourseContentActionPayload {
  data: AddNewCourseContentActionPayload,
  id: string;
}

export const updateNewCourseContentAction = (data: UpdateNewCourseContentActionPayload) => ({
  type: ACTION_UPDATE_NEW_COURSE_CONTENT,
  payload: data,
});

export const ACTION_REMOVE_NEW_COURSE_CONTENT = 'ACTION_REMOVE_NEW_COURSE_CONTENT';

export interface RemoveNewCourseActionPayload {
  position: number;
}

export const removeNewCourseContentAction = (data: RemoveNewCourseActionPayload) => ({
  type: ACTION_REMOVE_NEW_COURSE_CONTENT,
  payload: data,
});

export const ACTION_UPDATE_NEW_COURSE_CONTENT_PUBLISHED = 'ACTION_UPDATE_NEW_COURSE_CONTENT_PUBLISHED';

export const updateNewCourseContentPublishedAction = data => ({
  type: ACTION_UPDATE_NEW_COURSE_CONTENT_PUBLISHED,
  payload: data,
});

export const ACTION_UPDATE_NEW_COURSE_CONTENT_POSITION = 'ACTION_UPDATE_NEW_COURSE_CONTENT_POSITION';

export const updateNewCourseContentPositionAction = data => ({
  type: ACTION_UPDATE_NEW_COURSE_CONTENT_POSITION,
  payload: data,
});

export const ACTION_RESET_NEW_COURSE = 'ACTION_RESET_NEW_COURSE';

export const resetNewCourseAction = () => ({
  type: ACTION_RESET_NEW_COURSE,
});

export const COURSES_GET_REQUESTED = 'COURSES_GET_REQUESTED';

export interface CoursesGetRequestedActionPayload {
  data: GetAllCoursesParamsInterface,
  isSearch?: boolean;
  isPositionFilter?: boolean;
  isCityFilter?: boolean;
  isCompilationFilter?: boolean;
  isCompilationEdit?: boolean;
  positionLabels?: string[];
  cityLabels?: string[];
}

export const coursesGetRequestedAction = (data: CoursesGetRequestedActionPayload) => ({
  type: COURSES_GET_REQUESTED,
  payload: data,
});

export const COURSE_GET_REQUESTED = 'COURSE_GET_REQUESTED';

export const courseGetRequestedAction = data => ({
  type: COURSE_GET_REQUESTED,
  payload: data,
});

export const COURSE_CONTENT_GET_REQUESTED = 'COURSE_CONTENT_GET_REQUESTED';

export const courseContentGetRequestedAction = data => ({
  type: COURSE_CONTENT_GET_REQUESTED,
  payload: data,
});

export const COURSE_CREATE_REQUESTED = 'COURSE_CREATE_REQUESTED';

export interface CourseCreateRequestedPayload extends PayloadWithCallback {
  data: any;
  isPublish?: boolean;
}

export const coursesCreateRequestedAction = (data: CourseCreateRequestedPayload) => ({
  type: COURSE_CREATE_REQUESTED,
  payload: data,
});

export const COURSE_COPY_REQUESTED = 'COURSE_COPY_REQUESTED';

export interface CoursesCopyRequestedPayload extends PayloadWithCallback {
  id: string;
  name?: string;
  isCourses?: boolean;
  published?: boolean;
  archived?: boolean;
}

export const coursesCopyRequestedAction = (data: CoursesCopyRequestedPayload) => ({
  type: COURSE_COPY_REQUESTED,
  payload: data,
});

export const COURSE_UPDATE_REQUESTED = 'COURSE_UPDATE_REQUESTED';

export interface CourseUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: FormData;
}

export const coursesUpdateRequestedAction = data => ({
  type: COURSE_UPDATE_REQUESTED,
  payload: data,
});

export const COURSE_CONTENT_POSITION_UPDATE_REQUESTED = 'COURSE_CONTENT_POSITION_UPDATE_REQUESTED';

export interface CourseContentPositionUpdateRequestedPayload {
  courseId: string;
  data: LessonInterface[];
}

export const courseContentPositionUpdateRequestedAction = (data: CourseContentPositionUpdateRequestedPayload) => ({
  type: COURSE_CONTENT_POSITION_UPDATE_REQUESTED,
  payload: data,
});

export const COURSE_DELETE_REQUESTED = 'COURSE_DELETE_REQUESTED';

export interface CourseDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const coursesDeleteRequestedAction = (data: CourseDeleteRequestedPayload) => ({
  type: COURSE_DELETE_REQUESTED,
  payload: data,
});