import {
  ACTION_GET_COMMON_PAGE_DATA_REQUESTED,
  ACTION_SET_COMMON_PAGE_DATA,
  ACTION_SET_USERS_ACTIVITY_DATA,
  ACTION_SET_USER_ACTIVITY_DATA,
  ACTION_RESET_USER_ACTIVITY_DATA,
  ACTION_SET_COURSES_DATA,
  ACTION_SET_COURSE_ACTIVITY_DATA,
  ACTION_RESET_COURSE_ACTIVITY_DATA,
  ACTION_SET_NEWS_DATA
} from './actions';

const defaultState = {
  commonPage: {
    publications: {},
    courses_statistic: {},
    courses_activity: {},
    courses: [],
    users: {}
  },
  usersActivity: {
    gist: {},
    meta: {},
    mobile_users: [],
    statistic: {}
  },
  userActivity: {
    courses: [],
    user: {}
  },
  courseActivity: {
    course: {},
    users: [],
    meta: {}
  },
  coursesData: {
    courses: [],
    meta: {},
    percent: null,
    total: null
  },
  newsData: {
    meta: {},
    percent: null,
    publications: [],
    total: null
  }
}

const dashboardsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_SET_COMMON_PAGE_DATA: {
      return {...state, commonPage: action.payload}
    }
    case ACTION_SET_USERS_ACTIVITY_DATA: {
      return {...state, usersActivity: action.payload}
    }
    case ACTION_SET_USER_ACTIVITY_DATA: {
      return {...state, userActivity: action.payload}
    }
    case ACTION_SET_COURSES_DATA: {
      return {...state, coursesData: action.payload}
    }
    case ACTION_RESET_USER_ACTIVITY_DATA: {
      return {...state, userActivity: {}}
    }
    case ACTION_SET_COURSE_ACTIVITY_DATA: {
      return {...state, courseActivity: {
        course: (({name, passage, percent, total}) => ({name, passage, percent, total}))(action.payload),
        users: action.payload.course_users,
        meta: action.payload.meta
      }}
    }
    case ACTION_RESET_COURSE_ACTIVITY_DATA: {
      return {...state, courseActivity: {}}
    }
    case ACTION_SET_NEWS_DATA: {
      return {...state, newsData: action.payload}
    }
    default: {
      return state;
    }
  }
}

export default dashboardsReducer;
