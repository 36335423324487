import { put, takeEvery, delay, select } from 'redux-saga/effects';
import {ACTION_CREATE_NOTIFICATION, addNotificationAction, deleteNotificationAction} from "../notifications/actions";
import {NotificationsIdCounterSelector, NotificationsSelector} from "../notifications/selectors";
import {NOTIFICATION_TYPE} from "../../containers/Notifier/constants";
import {NotificationInterface} from "../notifications/interface";

function* createNotification(action) {
    try {
        let shouldAddNotification = true;
        const notificationId: number = yield select(NotificationsIdCounterSelector);
        if (action.payload.preventDuplication) {
            const notifications: NotificationInterface[] = yield select(NotificationsSelector);
            const duplicateNotificationIndex: number = notifications
                .findIndex((notification) => notification.text === action.payload.text);
            shouldAddNotification = duplicateNotificationIndex === -1;
        }

        if (shouldAddNotification) {
            yield put(addNotificationAction({
                text: action.payload.text,
                type: action.payload.type || NOTIFICATION_TYPE.DEFAULT,
                id: notificationId,
            }));
            yield delay(action.payload.delay || 5000);
            yield put(deleteNotificationAction({ id: notificationId }));
        }
    } catch (e) {
        console.log(e);
    }
}

function* notificationsSaga() {
    yield takeEvery(ACTION_CREATE_NOTIFICATION, createNotification);
}

export default notificationsSaga;