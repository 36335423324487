import {UnsplashPaginationInterface} from "./interface";
import {UnsplashPhotoInterface} from "../../api/Unsplash";
import {Photos} from "unsplash-js/dist/methods/search/types/response";
import {UNSPLASH_SET_PHOTOS} from "./actions";

interface StateInterface {
  photos: UnsplashPhotoInterface[];
  pagination: UnsplashPaginationInterface;
}

const defaultState: StateInterface = {
  photos: [],
  pagination: {
    total: 0,
    totalPages: 1,
  }
};

const unsplashReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case UNSPLASH_SET_PHOTOS: {
      return setPhotos(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setPhotos(state, payload: Photos): StateInterface {
  return {
    ...state,
    photos: payload.results,
    pagination: {
      total: payload.total,
      totalPages: payload.total_pages,
    }
  }
}

export default unsplashReducer;