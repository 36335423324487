import {
  ACTION_REMOVE_TOKENS,
  ACTION_SET_EMAIL,
  ACTION_SET_TOKENS, SET_MAINTENANCE,
} from './actions';
import {removeLocalStorageItem, setLocalStorageItem} from "../../utils/localStorage";
import {LOCAL_STORAGE_KEYS} from "../../constants/localStorage";
import {MaintenanceInterface, TokensInterface} from "../../api/Auth";
import {ROUTE_ROOT} from "../../constants/routes";

interface StateInterface {
  email: string;
  access_token: string;
  refresh_token: string;
  maintenance: MaintenanceInterface;
}

const defaultState: StateInterface = {
  email: '',
  access_token: '',
  refresh_token: '',
  maintenance: null,
};

const authReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_TOKENS: {
      return setTokens(state, action.payload);
    }
    case ACTION_SET_EMAIL: {
      return setEmail(state, action.payload);
    }
    case ACTION_REMOVE_TOKENS: {
      return removeTokens(state);
    }
    case SET_MAINTENANCE: {
      return setMaintenance(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setMaintenance(state, payload: MaintenanceInterface) {
  return {
    ...state,
    maintenance: payload,
  }
}

function setTokens(state, payload: TokensInterface) {
  setLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, payload.access_token);
  setLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, payload.refresh_token);
  window.location.replace(ROUTE_ROOT);

  return {
    ...state,
    access_token: payload.access_token,
    refresh_token: payload.refresh_token,
  }
}

function setEmail(state, email: string) {
  setLocalStorageItem(LOCAL_STORAGE_KEYS.EMAIL, email);

  return {
    ...state,
    email,
  }
}

function removeTokens(state) {
  removeLocalStorageItem(LOCAL_STORAGE_KEYS.EMAIL);
  removeLocalStorageItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  removeLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

  return {
    ...state,
    access_token: defaultState.access_token,
    refresh_token: defaultState.refresh_token,
  }
}

export default authReducer;
