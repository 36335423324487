export const PARAM_EMAIL = 'email';
export const PARAM_LESSON_ID = 'lessonId';
export const PARAM_COURSE_ID = 'courseId';
export const PARAM_COMPILATION_ID = 'compilationId';
export const PARAM_POSITION_ID = 'positionId';
export const PARAM_CITY_ID = 'cityId';
export const PARAM_NEWS_ID = 'newsId';
export const PARAM_DASHBOARD_USER_ID = 'userId';
export const PARAM_DASHBOARD_COURSE_ID = 'courseId';

export const ROUTE_AUTH = '/auth';
export const ROUTE_AUTH_REGISTRATION = '/auth/registration';
export const ROUTE_ROOT = '/';
export const ROUTE_STANDBY = '/standby';
export const ROUTE_500 = '/500';
export const ROUTE_404 = '/404';
export const ROUTE_REST = '*';

export const ROUTE_EMPLOYEES = '/employees';
export const ROUTE_EMPLOYEES_EDIT = `${ROUTE_EMPLOYEES}/edit/:${PARAM_EMAIL}`;
export const ROUTE_EMPLOYEES_ADD = `${ROUTE_EMPLOYEES}/add`;

export const ROUTE_MOBILE_USERS = '/mobile-users';
export const ROUTE_MOBILE_USERS_IMPORT = `${ROUTE_MOBILE_USERS}/import`;

export const ROUTE_COURSES = '/courses';
export const ROUTE_COURSES_ADD = `${ROUTE_COURSES}/add`;
export const ROUTE_COURSES_ADD_CONTENT = `${ROUTE_COURSES_ADD}/content`;
export const ROUTE_COURSES_ADD_LESSON = `${ROUTE_COURSES_ADD}/lesson`;
export const ROUTE_COURSES_ADD_LESSON_EDIT = `${ROUTE_COURSES_ADD_LESSON}/:${PARAM_LESSON_ID}`;
export const ROUTE_COURSES_ADD_SCREENPLAY = `${ROUTE_COURSES_ADD_LESSON}/screenplay`;
export const ROUTE_COURSES_ADD_SCREENPLAY_EDIT = `${ROUTE_COURSES_ADD_LESSON_EDIT}/screenplay`;
export const ROUTE_COURSES_EDIT = `${ROUTE_COURSES}/edit/:${PARAM_COURSE_ID}`;
export const ROUTE_COURSES_EDIT_CONTENT = `${ROUTE_COURSES_EDIT}/content`;
export const ROUTE_COURSES_EDIT_LESSON_ADD = `${ROUTE_COURSES_EDIT}/lesson/add`;
export const ROUTE_COURSES_EDIT_LESSON_EDIT = `${ROUTE_COURSES_EDIT}/lesson/:${PARAM_LESSON_ID}`;
export const ROUTE_COURSES_EDIT_SCREENPLAY_ADD = `${ROUTE_COURSES_EDIT_LESSON_ADD}/screenplay`;
export const ROUTE_COURSES_EDIT_SCREENPLAY_EDIT = `${ROUTE_COURSES_EDIT_LESSON_EDIT}/screenplay`;

export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_DASHBOARD_USERS_ACTIVITY = `${ROUTE_DASHBOARD}/users_activity`;
export const ROUTE_DASHBOARD_USER = `${ROUTE_DASHBOARD_USERS_ACTIVITY}/:${PARAM_DASHBOARD_USER_ID}`;
export const ROUTE_DASHBOARD_COURSES_ACTIVITY = `${ROUTE_DASHBOARD}/courses_activity`;
export const ROUTE_DASHBOARD_COURSE = `${ROUTE_DASHBOARD_COURSES_ACTIVITY}/:${PARAM_DASHBOARD_COURSE_ID}`;
export const ROUTE_DASHBOARD_NEWS = `${ROUTE_DASHBOARD}/news`

export const ROUTE_COMPILATIONS = '/compilations';
export const ROUTE_COMPILATIONS_ADD = `${ROUTE_COMPILATIONS}/add`;
export const ROUTE_COMPILATIONS_ADD_CONTENT = `${ROUTE_COMPILATIONS_ADD}/content`;
export const ROUTE_COMPILATIONS_EDIT = `${ROUTE_COMPILATIONS}/:${PARAM_COMPILATION_ID}`;
export const ROUTE_COMPILATIONS_EDIT_CONTENT = `${ROUTE_COMPILATIONS_EDIT}/content`;

export const ROUTE_FEEDBACK = '/feedback';
export const ROUTE_FEEDBACK_REVIEWS = 'reviews';
export const ROUTE_FEEDBACK_EDIT = `${ROUTE_FEEDBACK}/:${PARAM_COURSE_ID}`;
export const ROUTE_FEEDBACK_EDIT_REVIEWS = `${ROUTE_FEEDBACK_EDIT}/${ROUTE_FEEDBACK_REVIEWS}`;

export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_POSITION = `${ROUTE_SETTINGS}/position`;
export const ROUTE_SETTINGS_POSITION_EDIT = `${ROUTE_SETTINGS_POSITION}/:${PARAM_POSITION_ID}`;
export const ROUTE_SETTINGS_CITY = `${ROUTE_SETTINGS}/city`;
export const ROUTE_SETTINGS_RATING = `${ROUTE_SETTINGS}/rating`;
export const ROUTE_SETTINGS_CITY_EDIT = `${ROUTE_SETTINGS_CITY}/:${PARAM_CITY_ID}`;

export const ROUTE_BILLING = '/billing'
export const ROUTE_BILLING_CANCEL = '/billing/cancel'

export const ROUTE_NEWS = '/news';
export const ROUTE_NEWS_ADD = `${ROUTE_NEWS}/add`;
export const ROUTE_NEWS_EDIT = `${ROUTE_NEWS}/:${PARAM_NEWS_ID}`;
export const ROUTE_NEWS_COPY = (newsId: string = `:${PARAM_NEWS_ID}`) => `${ROUTE_NEWS}/${newsId}/copy`;
