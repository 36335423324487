import {
    LocalTestQuestionInterface,
    LocalTestQuestionToUpdateInterface,
    LocalTestVariantInterface
} from "../../components/TestQuestion/constants";
import {TestQuestionInterface} from "../../api/TestQuestions";
import {PrepareVariantToDisplay} from "../variants/helpers";

export const PrepareQuestionToDisplay = (question: TestQuestionInterface): LocalTestQuestionInterface => {
    return {
        id: question.id,
        text: question.text,
        hint: question.hint,
        kind: question.kind,
        position: question.position,
        audio: {
            name: question.audio?.display_name,
            duration: question.audio?.duration,
            url: question.audio?.download_url,
        },
        image: {
            url: question.image?.download_url,
        },
        variants: question.variants.map(PrepareVariantToDisplay),
    }
};

export const PrepareQuestionToUpload = (question: LocalTestQuestionInterface
    | LocalTestQuestionToUpdateInterface, testId?: string): FormData => {
    const formData: FormData = new FormData();
    if (testId) {
        formData.append('test_id', testId);
    }
    if (question.hasOwnProperty('text')) {
        formData.append('text', question.text);
    }
    if (question.hasOwnProperty('hint')) {
        formData.append('hint', question.hint);
    }
    if (question.hasOwnProperty('kind')) {
        formData.append('kind', question.kind);
    }
    if (question.hasOwnProperty('position')) {
        formData.append('position', JSON.stringify(question.position));
    }
    if (question.audio?.hasOwnProperty('name')) {
        formData.append('audio_attributes[display_name]', question.audio.name);
    }
    if (question.audio?.hasOwnProperty('file')) {
        formData.append('audio_attributes[file]', question.audio.file);
    }
    if (question.audio?.hasOwnProperty('name')) {
        formData.append('audio_attributes[duration]', JSON.stringify(question.audio.duration));
    }
    if (question.image?.hasOwnProperty('file')) {
        formData.append('image_attributes[file]', question.image.file);
    }

    return formData;
};

export const PrepareQuestionVariantPositionsToUpload = (variants: LocalTestVariantInterface[]): FormData => {
    const formData = new FormData();
    variants.forEach((variant, index) => {
        formData.append('variants_attributes[][id]', variant.id);
        formData.append('variants_attributes[][position]', JSON.stringify(index + 1));
    });
    return formData;
};