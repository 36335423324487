import {
    LessonContentType,
    LongreadSectionInterface,
    LongreadSectionToPrepareInterface
} from "../../components/Longread/constants";
import {SectionInterface} from "../../api/Sections";

export const PrepareSectionToUpload = (section: LongreadSectionInterface | LongreadSectionToPrepareInterface,
                                       stepId?: string): FormData => {
    const formData: FormData = new FormData();
    if (stepId) {
        formData.append('step_id', stepId);
    }
    if (section.kind) {
        formData.append('kind', section.kind);
    }
    if (section.position) {
        formData.append('position', JSON.stringify(section.position));
    }
    switch (section.kind) {
        case LessonContentType.TEXT:
            if (section.content?.hasOwnProperty('text')) {
                formData.append('content[text]', section.content.text);
            }
            break;
        case LessonContentType.GLOSSARY:
            section.content?.cards?.forEach((card) => {
                formData.append('content[cards[][title]]', card.title);
                formData.append('content[cards[][text]]', card.text);
            });
            break;
        case LessonContentType.IMAGE:
            if (section.attachment_attributes?.id) {
                formData.append('attachment_attributes[id]', section.attachment_attributes.id);
            }
            if (section.attachment_attributes?.hasOwnProperty('file')) {
                formData.append('attachment_attributes[file]', section.attachment_attributes.file);
            }
            break;
        case LessonContentType.VIDEO:
            if (section.attachment_attributes?.id) {
                formData.append('attachment_attributes[id]', section.attachment_attributes.id);
            }
            if (section.attachment_attributes?.hasOwnProperty('file')) {
                formData.append('attachment_attributes[file]', section.attachment_attributes.file);
            }
            break;
        case LessonContentType.AUDIO:
            if (section.attachment_attributes?.id) {
                formData.append('attachment_attributes[id]', section.attachment_attributes.id);
            }
            if (section.attachment_attributes?.hasOwnProperty('file')) {
                formData.append('attachment_attributes[file]', section.attachment_attributes.file);
            }
            if (section.attachment_attributes?.hasOwnProperty('duration')) {
                formData.append('attachment_attributes[duration]',
                    JSON.stringify(section.attachment_attributes.duration));
            }
            if (section.attachment_attributes?.hasOwnProperty('display_name')) {
                formData.append('attachment_attributes[display_name]',
                    section.attachment_attributes.display_name);
            }
            break;
    }
    return formData;
};

export const PrepareSectionsToDisplay = (sections: SectionInterface[]): LongreadSectionInterface[] => {
    return sections?.map((section) => {
        const preparedSection: LongreadSectionInterface = {
            id: section.id,
            position: section.position,
            kind: section.kind,
            content: {},
            attachment_attributes: {},
        };
        if ([LessonContentType.TEXT, LessonContentType.GLOSSARY].includes(section.kind)) {
            if (section.kind === LessonContentType.GLOSSARY) {
                preparedSection.content.cards = section.content.cards.map((item, index) => {
                    return {
                        ...item,
                        id: String(index),
                    }
                });
                preparedSection.content.idCounter = section.content.cards.length;
            } else {
                preparedSection.content = section.content;
            }
        } else {
            preparedSection.attachment_attributes.url = section.attachment?.download_url;
            preparedSection.attachment_attributes.id = section.attachment?.id;

            if (section.kind === LessonContentType.AUDIO) {
                preparedSection.attachment_attributes.duration = section.attachment?.duration;
                preparedSection.attachment_attributes.display_name = section.attachment?.display_name;
            }
        }
        return preparedSection;
    })
};