import {BillingInfoInterface, BillingSubscribeBodyInterface} from "../../api/Billing";
import {PayloadWithCallback} from '../../interfaces/store';

export const BILLING_INFO_GET_REQUESTED: string = 'BILLING_GET_REQUESTED';

export const billingInfoGetRequestedAction = () => ({
    type: BILLING_INFO_GET_REQUESTED,
});

export const BILLING_SUBSCRIBE_REQUESTED: string = 'BILLING_SUBSCRIBE_REQUESTED';

export interface BillingSubscribeRequestedInterface extends PayloadWithCallback {
    body: BillingSubscribeBodyInterface;
}

export const billingSubscribeRequestedAction = (data: BillingSubscribeRequestedInterface) => ({
    payload: data,
    type: BILLING_SUBSCRIBE_REQUESTED,
});

export const ACTION_SET_BILLING_INFO: string = 'ACTION_SET_BILLING_INFO';

export const setBillingInfoAction = (data: BillingInfoInterface) => ({
    payload: data,
    type: ACTION_SET_BILLING_INFO,
});
