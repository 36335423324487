import { call, put, takeLatest } from 'redux-saga/effects';
import {
    AUTH_MOBILE_USER_CREATE_REQUESTED,
    AuthMobileUserCreateRequestedPayload,
    MOBILE_USER_DELETE_REQUESTED,
    MOBILE_USER_UPDATE_REQUESTED,
    MOBILE_USERS_GET_REQUESTED,
    setMobileUsersAction,
} from "../mobileUsers/actions";
import {
    DELETE_MOBILE_USER,
    GET_MOBILE_USERS,
    GetMobileUsersParamsInterface,
    UPDATE_MOBILE_USER, UpdateMobileUserDataInterface
} from "../../api/MobileUsers";
import {MobileUsersDataInterface} from "../mobileUsers/interface";
import {createNotificationAction} from "../notifications/actions";
import {AmplitudeEventDataInterface, LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT, MOBILE_USER_AMPLITUDE_EVENTS } from "../../constants/amplitude";
import {MOBILE_USERS_CATEGORY} from "../../containers/MobileUsers/constants";
import {
    CREATE_AUTH_MOBILE_USER, DELETE_AUTH_MOBILE_USER, GET_AUTH_MOBILE_USERS,
    UPDATE_AUTH_MOBILE_USER,
} from "../../api/auth/MobileUsers";
import {UsersDataInterface} from "../users/interface";
import {AxiosResponse} from "axios";
import {DefaultAction} from "../interface";

function* getMobileUsers(action) {
    try {
        let apiFunction: (params: GetMobileUsersParamsInterface) => Promise<AxiosResponse<UsersDataInterface>>;
        switch (action.payload.category) {
            case MOBILE_USERS_CATEGORY.ALL:
                apiFunction = GET_AUTH_MOBILE_USERS;
                break;
            default:
                apiFunction = GET_MOBILE_USERS;
                break;
        }
        const data: MobileUsersDataInterface = (yield call(apiFunction, action.payload.data)).data;
        const totalResultsParam = {
            number_of_results_returned: data.meta && data.meta.total,
        };
        const categoryKey: string = action?.payload?.activeUsersCategory?.toUpperCase();

        if (action.payload.isSearch) {
            LogAmplitudeEvent(MOBILE_USER_AMPLITUDE_EVENTS.USER_SEARCH[categoryKey], {
                keywords: action.payload.data.name,
                ...totalResultsParam,
            });
        }
        if (action.payload.isPositionFilter) {
            LogAmplitudeEvent(MOBILE_USER_AMPLITUDE_EVENTS.USER_POSITION_FILTER[categoryKey], {
                positions: action.payload.positionLabels,
                ...totalResultsParam,
            });
        }
        if (action.payload.isCityFilter) {
            LogAmplitudeEvent(MOBILE_USER_AMPLITUDE_EVENTS.USER_CITY_FILTER[categoryKey], {
                city: action.payload.cityLabels,
                ...totalResultsParam,
            });
        }
        if (action.payload.isDateFilter) {
            const dateFilterParams: AmplitudeEventDataInterface = {
                ...totalResultsParam,
            };
            if (action.payload.dateSince) {
                dateFilterParams.date_since = action.payload.dateSince
            }
            if (action.payload.dateTill) {
                dateFilterParams.date_till = action.payload.dateTill
            }
            LogAmplitudeEvent(MOBILE_USER_AMPLITUDE_EVENTS.USER_DATE_FILTER[categoryKey], dateFilterParams);
        }
        yield put(setMobileUsersAction(data));
    } catch (e) {
        console.log(e);
    }
}

function* updateMobileUser(action) {
    try {
        let apiFunction: (id: string, data: UpdateMobileUserDataInterface) => Promise<any>;
        switch (action.payload.category) {
            case MOBILE_USERS_CATEGORY.ALL:
                apiFunction = UPDATE_AUTH_MOBILE_USER;
                break;
            default:
                apiFunction = UPDATE_MOBILE_USER;
                break;
        }
        yield call(apiFunction, action.payload.id, action.payload.data);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        if (error.response.status === 406) {
            yield put(createNotificationAction({ text: 'MOBILE_USERS.FAILED_UPDATE_USER_406'}));
        } else {
            console.log(error);
        }
    }
}

function* deleteMobileUser(action) {
    try {
        let apiFunction: (id: string) => Promise<any>;
        switch (action.payload.category) {
            case MOBILE_USERS_CATEGORY.ALL:
                apiFunction = DELETE_AUTH_MOBILE_USER;
                break;
            default:
                apiFunction = DELETE_MOBILE_USER;
                break;
        }
        yield call(apiFunction, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(createNotificationAction({ text: 'MOBILE_USERS.SUCCESS_DELETE_USER'}));
    } catch (e) {
        yield put(createNotificationAction({ text: 'MOBILE_USERS.FAILED_DELETE_USER'}));
        console.log(e);
    }
}

function* createAuthMobileUser(action: DefaultAction<AuthMobileUserCreateRequestedPayload>) {
    try {
        yield call(CREATE_AUTH_MOBILE_USER, action.payload.data);

        if (action.payload.callback) {
            action.payload.callback();
        }
        yield put(createNotificationAction({ text: 'MOBILE_USERS.SUCCESS_CREATE_USER'}));
    } catch (error) {
        if (error.response.status === 406) {
            yield put(createNotificationAction({ text: 'MOBILE_USERS.FAILED_CREATE_USER_406'}));
        } else {
            yield put(createNotificationAction({ text: 'MOBILE_USERS.FAILED_CREATE_USER'}));
            console.log(error);
        }
    }
}


function* mobileUsersSaga() {
    yield takeLatest(MOBILE_USERS_GET_REQUESTED, getMobileUsers);
    yield takeLatest(MOBILE_USER_UPDATE_REQUESTED, updateMobileUser);
    yield takeLatest(MOBILE_USER_DELETE_REQUESTED, deleteMobileUser);
    yield takeLatest(AUTH_MOBILE_USER_CREATE_REQUESTED, createAuthMobileUser);
}

export default mobileUsersSaga;
