export const MOBILE_USERS_PARTIAL_UPLOAD_REQUESTED = 'MOBILE_USERS_PARTIAL_UPLOAD_REQUESTED';

export const mobileUsersPartialUploadRequestedAction = (data) => ({
  type: MOBILE_USERS_PARTIAL_UPLOAD_REQUESTED,
  payload: data,
});

export const MOBILE_USERS_BULK_UPLOAD_REQUESTED = 'MOBILE_USERS_BULK_UPLOAD_REQUESTED';

export const mobileUsersBulkUploadRequestedAction = (data) => ({
  type: MOBILE_USERS_BULK_UPLOAD_REQUESTED,
  payload: data,
});

export const MOBILE_USERS_IMPORT_STATUS_REQUESTED = 'MOBILE_USERS_IMPORT_STATUS_REQUESTED';

export const mobileUsersImportStatusRequestedAction = (data) => ({
  type: MOBILE_USERS_IMPORT_STATUS_REQUESTED,
  payload: data,
});

export const SET_MOBILE_USERS_PARTIAL_IMPORT_STATUS_REQUESTED = 'SET_MOBILE_USERS_PARTIAL_IMPORT_STATUS_REQUESTED';

export const setMobileUsersPartialImportStatusRequestedAction = (data) => ({
  type: SET_MOBILE_USERS_PARTIAL_IMPORT_STATUS_REQUESTED,
  payload: data,
});

export const SET_MOBILE_USERS_BULK_IMPORT_STATUS_REQUESTED = 'SET_MOBILE_USERS_BULK_IMPORT_STATUS_REQUESTED';

export const setMobileUsersBulkImportStatusRequestedAction = (data) => ({
  type: SET_MOBILE_USERS_BULK_IMPORT_STATUS_REQUESTED,
  payload: data,
});

export const CLEAR_MOBILE_USERS_PARTIAL_IMPORT_STATUS = 'CLEAR_MOBILE_USERS_PARTIAL_IMPORT_STATUS';

export const clearMobileUsersPartialImportStatusAction = () => ({
  type: CLEAR_MOBILE_USERS_PARTIAL_IMPORT_STATUS
});

export const CLEAR_MOBILE_USERS_BULK_IMPORT_STATUS = 'CLEAR_MOBILE_USERS_BULK_IMPORT_STATUS';

export const clearMobileUsersBulkImportStatusAction = () => ({
  type: CLEAR_MOBILE_USERS_BULK_IMPORT_STATUS
});

export const START_MOBILE_USERS_IMPORT_REQUESTED = 'START_MOBILE_USERS_IMPORT_REQUESTED';

export const startMobileUsersImportRequestedAction = () => ({
  type: START_MOBILE_USERS_IMPORT_REQUESTED
});

export const FINISH_MOBILE_USERS_IMPORT_REQUESTED = 'FINISH_MOBILE_USERS_IMPORT_REQUESTED';

export const finishMobileUsersImportRequestedAction = () => ({
  type: FINISH_MOBILE_USERS_IMPORT_REQUESTED
});

export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';

export const setUploadProgressAction = (data) => ({
  type: SET_UPLOAD_PROGRESS,
  payload: data
});

