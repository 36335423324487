import {AuthApi} from "./index";
import {ApiDefaultResponse} from "../interfaces/api";
import {TEST_QUESTION_KIND} from "../components/TestQuestion/constants";
import {TestVariantInterface} from "./TestVariants";

export interface TestQuestionInterface {
    id: string
    text: string;
    hint: string;
    kind: TEST_QUESTION_KIND;
    position: number;
    variants: TestVariantInterface[];
    audio?: {
        created_at: string;
        display_name: string;
        download_thumbnail_url: string;
        download_url: string;
        duration: number;
        id: string;
        tenant_id: string;
        updated_at: string;
    };
    image?: {
        created_at: string;
        display_name: string;
        download_thumbnail_url: string;
        download_url: string;
        id: string;
        tenant_id: string;
        updated_at: string;
    };
}

const TESTS_QUESTIONS_API_URL: string = 'questions';

export const CREATE_QUESTION = (data: FormData) => {
    return AuthApi.post<ApiDefaultResponse<TestQuestionInterface>>(`${TESTS_QUESTIONS_API_URL}`, data);
};

export const UPDATE_QUESTION = (id: string, data: FormData) => {
    return AuthApi.patch<ApiDefaultResponse<TestQuestionInterface>>(`${TESTS_QUESTIONS_API_URL}/${id}`, data);
};

export const UPDATE_QUESTION_VARIANT_POSITIONS = (id: string, data: FormData) => {
    return AuthApi.patch<ApiDefaultResponse<TestQuestionInterface>>(`${TESTS_QUESTIONS_API_URL}/${id}`, data);
};

export const DELETE_QUESTION = (id: string) => {
    return AuthApi.delete<ApiDefaultResponse<TestQuestionInterface>>(`${TESTS_QUESTIONS_API_URL}/${id}`);
};