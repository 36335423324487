import {PayloadWithCallback} from "../../interfaces/store";
import {GetNewsParamsInterface, NewsDataInterface, NewsInterface} from "../../api/News";

export const SET_NEWS_DATA = 'SET_NEWS_DATA';

export const setNewsDataAction = (data: NewsDataInterface) => ({
  type: SET_NEWS_DATA,
  payload: data,
});

export const SET_NEWS_FORM = 'SET_NEWS_FORM';

export const setNewsFormAction = (data: NewsInterface) => ({
  type: SET_NEWS_FORM,
  payload: data,
});

export const NEWS_GET_REQUESTED = 'NEWS_GET_REQUESTED';

export interface NewsGetRequestedPayload {
  params: GetNewsParamsInterface;
  callback?: (response?: NewsDataInterface) => void;
}

export const newsGetRequestedAction = (data: NewsGetRequestedPayload) => ({
  type: NEWS_GET_REQUESTED,
  payload: data,
});

export const ONE_NEWS_GET_REQUESTED = 'ONE_NEWS_GET_REQUESTED';

export interface OneNewsGetRequestedPayload {
  id: string;
}

export const oneNewsGetRequestedAction = (data: OneNewsGetRequestedPayload) => ({
  type: ONE_NEWS_GET_REQUESTED,
  payload: data,
});

export const NEWS_CREATE_REQUESTED = 'NEWS_CREATE_REQUESTED';

export interface NewsCreateRequestedPayload extends PayloadWithCallback {
  data: FormData;
  publish: boolean;
}

export const newsCreateRequestedAction = (data: NewsCreateRequestedPayload) => ({
  type: NEWS_CREATE_REQUESTED,
  payload: data,
});

export const NEWS_UPDATE_REQUESTED: string = 'NEWS_UPDATE_REQUESTED';

export interface NewsUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: FormData;
}

export const newsUpdateRequestedAction = (data: NewsUpdateRequestedPayload) => ({
  type: NEWS_UPDATE_REQUESTED,
  payload: data,
});

export const NEWS_DELETE_REQUESTED = 'NEWS_DELETE_REQUESTED';

export interface NewsDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const newsDeleteRequestedAction = (data: NewsDeleteRequestedPayload) => ({
  type: NEWS_DELETE_REQUESTED,
  payload: data,
});
