import {call, put, takeLatest} from 'redux-saga/effects';
import {CREATE_TOKEN, TokensInterface} from "../../api/Auth";
import {setEmail, setMaintenanceAction, setTokens, TOKEN_CREATE_REQUESTED} from "../auth/actions";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent, SetAmplitudeUserId} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {ROUTE_AUTH, ROUTE_STANDBY} from "../../constants/routes";
import {history} from "../configureStore";

export function* createToken(action) {
    const email: string = action.payload.email;
    const password: string = action.payload.password;
    try {
        const responseData: TokensInterface = (yield call(CREATE_TOKEN, email, password)).data;
        yield put(setEmail(action.payload.email));
        yield put(setTokens(responseData));
        SetAmplitudeUserId(email);
        LogAmplitudeEvent(AMPLITUDE_EVENT.LOGGED_IN_SUCCESS);
    } catch (error) {
        const isUnauthorizedError = error.response.status === 401;
        const isServiceModeError = error.response.status === 521;
        if (window.location.pathname === ROUTE_STANDBY) {
            if (isServiceModeError) {
                yield put(setMaintenanceAction(error.response.data));
            } else if (!email && !password) {
                yield call(history.push, ROUTE_AUTH);
            }
        } else if (isServiceModeError) {
            yield put(setMaintenanceAction(error.response.data));
            yield call(history.push, ROUTE_STANDBY);
        } else if (isUnauthorizedError) {
            yield put(createNotificationAction({
                text: 'AUTH.FAILED_LOGIN_NOTIFICATION_401',
                preventDuplication: true,
            }));
        } else {
            console.log(error);
        }
        if (email && password) {
            LogAmplitudeEvent(AMPLITUDE_EVENT.LOGGED_IN_FAIL, {
                error_type: isUnauthorizedError ? error.response.data : error.toString(),
            });
        }
    }
}


function* authSaga() {
    yield takeLatest(TOKEN_CREATE_REQUESTED, createToken);
}

export default authSaga;