import { call, put, takeLatest } from 'redux-saga/effects';
import {COMPILATION_COVERS_GET_REQUESTED, setCompilationCoversAction} from "../files/actions";
import {GET_COMPILATION_COVERS, GetCompilationCoversInterface} from "../../api/Files";

function* getCompilationCovers() {
    try {
        const responseData: GetCompilationCoversInterface = (yield call(GET_COMPILATION_COVERS)).data;
        yield put(setCompilationCoversAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* filesSaga() {
    yield takeLatest(COMPILATION_COVERS_GET_REQUESTED, getCompilationCovers);
}

export default filesSaga;