import {TEST_VARIANT_KIND} from "../../api/TestVariants";

export enum TEST_QUESTION_KIND {
    GENERAL = 'general',
}

export interface LocalTestQuestionToUpdateInterface {
    id: string;
    text?: string;
    hint?: string;
    kind?: TEST_QUESTION_KIND;
    position?: number;
    variants?: LocalTestVariantInterface[];
    audio?: {
        name?: string;
        duration?: number;
        file?: File;
        url?: string;
    }
    image?: {
        file?: File;
        url?: string;
    }
}

export interface LocalTestQuestionInterface {
    id: string;
    text: string;
    hint: string;
    kind: TEST_QUESTION_KIND;
    position: number;
    variants: LocalTestVariantInterface[];
    audio?: {
        name: string;
        duration?: number;
        file?: File;
        url: string;
    }
    image?: {
        file?: File;
        url: string;
    }
}

export class LocalTestQuestionClass implements LocalTestQuestionInterface {
    constructor(data) {
        const {
            id = 0,
            position,
        } = data;
        this.id = String(id);
        this.position = position + 1;
    }

    id;
    position;
    text = '';
    hint = '';
    kind = TEST_QUESTION_KIND.GENERAL;
    variants = [];
    image = {
        file: null,
        url: '',
    };
    audio = {
        name: '',
        duration: null,
        file: null,
        url: '',
    };
}

export interface LocalTestVariantToUpdateInterface {
    id: string;
    image?: {
        file?: File;
        url?: string;
    };
    kind?: TEST_VARIANT_KIND;
    text?: string;
    position?: number;
    right?: boolean;
}

export interface LocalTestVariantInterface {
    id: string;
    image?: {
        file?: File;
        url: string;
    };
    kind: TEST_VARIANT_KIND;
    text: string;
    position: number;
    right: boolean;
}

export class LocalTestVariantClass implements LocalTestVariantInterface {
    constructor(data) {
        const {
            id = 0,
            position,
            kind,
        } = data;
        this.id = String(id);
        this.position = position + 1;
        if (kind) {
            this.kind = kind;
        }
        if (kind === TEST_VARIANT_KIND.IMAGE) {
            this.image = {
                file: null,
                url: '',
            };
        }
    }

    id;
    position;
    image;
    kind = TEST_VARIANT_KIND.TEXT;
    right = false;
    text = '';
}

export const ValidateTestQuestion = (question: LocalTestQuestionInterface,
                                     initialErrorKey: string = '',
                                     requiredErrorKey: string = 'SCREENPLAY.ERROR_REQUIRED_FIELDS'): string => {
    let validationErrorKey: string = initialErrorKey;
    if (!(question.text || question.image?.url || question.audio?.url)
        || !question.variants || !question.variants.length) {
        validationErrorKey = requiredErrorKey;
    }
    if (question.variants?.length) {
        let hasRightAnswer: boolean = false;
        question.variants.forEach((variant) => {
            if (!variant.text && !variant.image?.url) {
                validationErrorKey = requiredErrorKey;
            }
            if (variant.right) {
                hasRightAnswer = true;
            }
        });
        if (!hasRightAnswer) {
            validationErrorKey = requiredErrorKey;
        }
    }
    return validationErrorKey;
}
