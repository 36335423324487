import {AuthServiceApi} from "../index";
import {GetMobileUsersParamsInterface, UpdateMobileUserDataInterface} from "../MobileUsers";
import {UsersDataInterface} from "../../store/users/interface";

const AUTH_MOBILE_USERS_API_URL = 'mobile_users';
const AUTH_MOBILE_USERS_IMPORTS_STATUS_API_URL = 'mobile_user_imports'

export const GET_AUTH_MOBILE_USERS = (params: GetMobileUsersParamsInterface = {}) => {
    return AuthServiceApi.get<UsersDataInterface>(AUTH_MOBILE_USERS_API_URL, {
        params,
    });
};

export interface CreateAuthMobileUserDataInterface {
    name: string;
    phone: string;
    external_id: string;
    city_id: string;
    position_id: string;
}

export const CREATE_AUTH_MOBILE_USER = (data: CreateAuthMobileUserDataInterface) => {
    return AuthServiceApi.post(`${AUTH_MOBILE_USERS_API_URL}`, data);
};

export const UPDATE_AUTH_MOBILE_USER = (id: string, data: UpdateMobileUserDataInterface) => {
    return AuthServiceApi.patch(`${AUTH_MOBILE_USERS_API_URL}/${id}`, data);
};

export const DELETE_AUTH_MOBILE_USER = (id: string) => {
    return AuthServiceApi.delete(`${AUTH_MOBILE_USERS_API_URL}/${id}`);
};

export const PARTIAL_UPLOAD_MOBILE_USERS = (data, limit_users_count) => {
  return AuthServiceApi.post(`${AUTH_MOBILE_USERS_API_URL}/mass_upload?limit_users_count=${limit_users_count}`, data)
}

export const BULK_UPLOAD_MOBILE_USERS = (data, limit_users_count, config = {}) => {
  return AuthServiceApi.post(`${AUTH_MOBILE_USERS_API_URL}/upload?limit_users_count=${limit_users_count}`, data, config)
}

export const GET_MOBILE_USERS_IMPORT_STATUS = (id) => {
  return AuthServiceApi.get(`${AUTH_MOBILE_USERS_IMPORTS_STATUS_API_URL}/${id}`)
}
