import { ACTION_SET_VALIDATION_ERRORS } from './actions'

interface IState {
  validationErrors: Record<string, string>
}

const defaultState = {
  validationErrors: {}
}

const registrationReducer = (state: IState = defaultState, action): IState => {
  switch (action.type) {
    case ACTION_SET_VALIDATION_ERRORS: {
        return {
            ...state,
            validationErrors: action.payload
        }
    }
      default: {
          return state;
      }
  }
}

export default registrationReducer;
