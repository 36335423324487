import {AuthApi} from "./index";

const DASHBOARDS_API_URL = 'dashboards';

export const DOWNLOAD_URLS = {
  USERS_ACTIVITY: `${DASHBOARDS_API_URL}/users_activity`,
  USER_ACTIVITY: `${DASHBOARDS_API_URL}/user_activity`,
  COURSES_ACTIVITY: `${DASHBOARDS_API_URL}/courses`,
  PUBLICATIONS: `${DASHBOARDS_API_URL}/publications`
}

export const GET_COMMON_PAGE_DATA = (params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/common`, {
    params: { ...params }
  });
};

export const GET_USERS_ACTIVITY_DATA = (params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/users_activity`, {
    params: { ...params }
  })
}

export const GET_USER_ACTIVITY_DATA = (id, params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/user_activity/${id}`, {
    params: { ...params }
  })
}

export const GET_COURSE_ACTIVITY_DATA = (id, params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/courses/${id}`, {
    params: { ...params }
  })
}

export const GET_COURSES_DATA = (params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/courses`, {
    params: { ...params }
  })
}

export const GET_NEWS_DATA = (params = {}) => {
  return AuthApi.get(`${DASHBOARDS_API_URL}/publications`, {
    params: { ...params }
  })
}
