export enum COLORS {
    GREY_BLUE_LIGHT = '#839098',
    DARK_GREY_BLUE = '#343B4C',
    DARK = '#0B1F35',
    GREY = '#9A9A9A',
    WHITE = '#FFFFFF',
    LIGHT_GREY_7 = '#F2F5FA',
    GREEN = '#59B27E',
    GREEN_LIGHT = '#E7F4ED',
    PINK = '#D053A6',
    PINK_LIGHT = '#F8E7F3',
    BLUE = '#0091BF',
    BLUE_LIGHT = '#DBF0F6',
    PURPLE = '#9F54B4',
    PURPLE_LIGHT = '#F2E7F5',
    NAVY = '#597DB2',
    NAVY_LIGHT = '#E8EDF4',
    LIME = '#13ADAF',
    LIME_LIGHT = '#DEF4F4',
    ORANGE = '#E99A17',
    ORANGE_LIGHT = '#FBF1DF',
    DEEP_DARK = '#191C1F',
    POLAR = '#F0F4F9',
    ERROR_RED = '#FF6258',
    DARK_GREY = '#6D7986',
    BLACK = '#000000',
    TRANSPARENT = 'transparent',
    MOUNTAIN_MEADOW = '#10A375'
}
