import { call, put, takeLatest, take } from 'redux-saga/effects';
import { uploadFileChannel } from './channels/uploadFile';
import {
  MOBILE_USERS_PARTIAL_UPLOAD_REQUESTED,
  MOBILE_USERS_BULK_UPLOAD_REQUESTED,
  MOBILE_USERS_IMPORT_STATUS_REQUESTED,
  setMobileUsersPartialImportStatusRequestedAction,
  setMobileUsersBulkImportStatusRequestedAction,
  startMobileUsersImportRequestedAction,
  setUploadProgressAction
} from "../mobileUsersImport/actions";
import {
  fileUploadsProgressUpdateAction
} from '../fileUploads/actions';
import {
    DELETE_MOBILE_USER,
    GET_MOBILE_USERS,
    GetMobileUsersParamsInterface,
    UPDATE_MOBILE_USER, UpdateMobileUserDataInterface
} from "../../api/MobileUsers";
import {MobileUsersDataInterface} from "../mobileUsers/interface";
import {createNotificationAction} from "../notifications/actions";
import {AmplitudeEventDataInterface, LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {MOBILE_USERS_CATEGORY} from "../../containers/MobileUsers/constants";
import {
    CREATE_AUTH_MOBILE_USER, DELETE_AUTH_MOBILE_USER, GET_AUTH_MOBILE_USERS,
    UPDATE_AUTH_MOBILE_USER, PARTIAL_UPLOAD_MOBILE_USERS, GET_MOBILE_USERS_IMPORT_STATUS,
    BULK_UPLOAD_MOBILE_USERS
} from "../../api/auth/MobileUsers";
import {UsersDataInterface} from "../users/interface";
import {AxiosResponse} from "axios";
import {DefaultAction} from "../interface";

function* partialUploadMobileUsers(action) {
  try {
    yield put(startMobileUsersImportRequestedAction())
    const response = (yield call(PARTIAL_UPLOAD_MOBILE_USERS, action.payload.body, action.payload.limitUsersCount ))

    if (action.payload.callback) {
      action.payload.callback(response)
    }
  } catch (e) {
    console.log(e)
  }
}

function* bulkUploadMobileUsers(action) {
  try {
    const { formData, fileId, callback } = action.payload.body
    yield put(startMobileUsersImportRequestedAction())
    const channel = yield call(uploadFileChannel, BULK_UPLOAD_MOBILE_USERS, formData, action.payload.limitUsersCount)
    while (true) {
      const { progress, response, success } = yield take(channel)
      if (success) {
        if (action.payload.callback) {
          action.payload.callback(response)
          return
        }
      }

      yield put(fileUploadsProgressUpdateAction({
        file: formData.get('file'),
        progress,
        fileId,
        callback
      }))
    }
  } catch (e) {
    console.log(e)
  } finally {}
}

function* getImportStatus(action) {
  const response = (yield call(GET_MOBILE_USERS_IMPORT_STATUS, action.payload.id))
  if (response.status === 200) {
    switch (action.payload.importType) {
      case 'partial':
        yield put(setMobileUsersPartialImportStatusRequestedAction(response.data))
        break;
      case 'bulk':
        yield put(setMobileUsersBulkImportStatusRequestedAction(response.data))
        break;
    }

  }
}

function* mobileUsersImportSaga() {
    yield takeLatest(MOBILE_USERS_PARTIAL_UPLOAD_REQUESTED, partialUploadMobileUsers);
    yield takeLatest(MOBILE_USERS_BULK_UPLOAD_REQUESTED, bulkUploadMobileUsers);
    yield takeLatest(MOBILE_USERS_IMPORT_STATUS_REQUESTED, getImportStatus);
}

export default mobileUsersImportSaga;
