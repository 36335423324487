import {AuthApi} from "./index";
import {UserPermissionsType, UsersDataInterface} from "../store/users/interface";
import {EmptyObjectType} from "../interfaces/default";

export interface GetUsersParamsInterface {
    name?: string;
    email?: string;
    limit?: number;
    offset?: number;
}

export const GET_USERS = (params: GetUsersParamsInterface = {}) => {
    return AuthApi.get<UsersDataInterface>(`users`, {
        params,
    });
};

export interface CreateUserDataInterface {
    name: string;
    email: string;
    permissions: EmptyObjectType<UserPermissionsType>;
    password?: string;
}

export const CREATE_USER = (data: CreateUserDataInterface) => {
    return AuthApi.post(`users`, data);
};

export interface UpdateUserDataInterface extends CreateUserDataInterface {}

export const UPDATE_USER = (id: string, data: UpdateUserDataInterface) => {
    return AuthApi.patch(`users/${id}`, data);
};

export const DELETE_USER = (id: string) => {
    return AuthApi.delete(`users/${id}`);
};