import { call, put, takeLatest } from 'redux-saga/effects';
import {createNotificationAction} from "../notifications/actions";
import {
    LEVEL_CREATE_REQUESTED,
    LEVEL_DELETE_REQUESTED,
    LEVEL_UPDATE_REQUESTED,
    LEVELS_GET_REQUESTED, setLevelsAction
} from "../levels/actions";
import {CREATE_LEVEL, DELETE_LEVEL, GET_LEVELS, LevelInterface, UPDATE_LEVEL} from "../../api/Levels";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";

function* getLevels() {
    try {
        const responseData: LevelInterface[] = (yield call(GET_LEVELS)).data.levels;
        yield put(setLevelsAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* createLevel(action) {
    try {
        yield call(CREATE_LEVEL, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        LogAmplitudeEvent(AMPLITUDE_EVENT.SETTINGS_LEVEL_CREATE_FAIL, {
            error_type: error.toString(),
        });
        console.log(error);
    }
}

function* updateLevel(action) {
    try {
        yield call(UPDATE_LEVEL, action.payload.id, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        console.log(error);
    }
}

function* deleteLevel(action) {
    try {
        yield call(DELETE_LEVEL, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* levelsSaga() {
    yield takeLatest(LEVELS_GET_REQUESTED, getLevels);
    yield takeLatest(LEVEL_CREATE_REQUESTED, createLevel);
    yield takeLatest(LEVEL_UPDATE_REQUESTED, updateLevel);
    yield takeLatest(LEVEL_DELETE_REQUESTED, deleteLevel);
}

export default levelsSaga;