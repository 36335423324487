import {DefaultApi} from "./index";

export interface TokensInterface {
    access_token: string;
    refresh_token: string;
}

export interface MaintenanceInterface {
    id: number;
    finished_at: string;
    started_at: string;
}

export interface CreateTokenParams {
    email: string;
    password: string;
}

export const CREATE_TOKEN = (email: string, password: string,) => {
    return DefaultApi.post<TokensInterface | MaintenanceInterface>(`tokens/create`, {
        email,
        password,
    });
};