import {
  ACTION_SET_FEEDBACK_COURSE,
  ACTION_SET_FEEDBACK_COURSES,
  ACTION_SET_COURSE_FEEDBACK,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {CourseFeedbackData, FeedbackCourseInterface, FeedbackCoursesDataInterface} from "../../api/Feedback";
import {EmptyObjectType} from "../../interfaces/default";

interface StateInterface {
  feedbackData: FeedbackCoursesDataInterface;
  feedbackCourse: EmptyObjectType<FeedbackCourseInterface>;
  reviewsData: CourseFeedbackData;
}

const defaultState: StateInterface = {
  feedbackData: {
    courses: null,
    meta: PAGES_DEFAULT_META,
  },
  feedbackCourse: {},
  reviewsData: {
    feedbacks: [],
    meta: PAGES_DEFAULT_META,
  },
};

const feedbackReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_FEEDBACK_COURSES: {
      return setFeedbackCoursesData(state, action.payload);
    }
    case ACTION_SET_FEEDBACK_COURSE: {
      return setFeedbackCourse(state, action.payload);
    }
    case ACTION_SET_COURSE_FEEDBACK: {
      return setCourseFeedbackData(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setFeedbackCoursesData(state, payload: FeedbackCoursesDataInterface) {
  return {
    ...state,
    feedbackData: payload,
  }
}

function setFeedbackCourse(state, payload: EmptyObjectType<FeedbackCourseInterface>) {
  return {
    ...state,
    feedbackCourse: payload,
  }
}

function setCourseFeedbackData(state, payload: CourseFeedbackData) {
  return {
    ...state,
    reviewsData: payload,
  }
}

export default feedbackReducer;