import {PayloadWithCallback} from "../../interfaces/store";
import {LessonInterface} from "../../api/Lessons";

export const ACTION_RESET_LESSONS = 'ACTION_RESET_LESSONS';

export const resetLessonsAction = () => ({
  type: ACTION_RESET_LESSONS,
});

export const ACTION_SET_LESSON = 'ACTION_SET_LESSON';

export interface SetLessonPayload {
  lesson: LessonInterface;
}

export const setLessonAction = (data: SetLessonPayload) => ({
  type: ACTION_SET_LESSON,
  payload: data,
});

export interface LessonGetRequestedPayload {
  id: string;
}

export const LESSON_GET_REQUESTED = 'LESSON_GET_REQUESTED';

export const lessonGetRequestedAction = (data: LessonGetRequestedPayload) => ({
  type: LESSON_GET_REQUESTED,
  payload: data,
});

export const LESSON_CREATE_REQUESTED = 'LESSON_CREATE_REQUESTED';

export interface LessonCreateRequestedPayload extends PayloadWithCallback {
  data: FormData;
  isManualCreation?: boolean;
}

export const lessonCreateRequestedAction = data => ({
  type: LESSON_CREATE_REQUESTED,
  payload: data,
});

export const LESSON_COPY_REQUESTED = 'LESSON_COPY_REQUESTED';

export const lessonCopyRequestedAction = data => ({
  type: LESSON_COPY_REQUESTED,
  payload: data,
});

export const LESSON_UPDATE_REQUESTED = 'LESSON_UPDATE_REQUESTED';

export interface LessonUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: FormData;
}

export const lessonUpdateRequestedAction = (data: LessonUpdateRequestedPayload) => ({
  type: LESSON_UPDATE_REQUESTED,
  payload: data,
});

export const LESSON_UPDATE_PUBLISHED_REQUESTED = 'LESSON_UPDATE_PUBLISHED_REQUESTED';

export const lessonUpdatePublishedAction = data => ({
  type: LESSON_UPDATE_PUBLISHED_REQUESTED,
  payload: data,
});


export const LESSON_DELETE_REQUESTED = 'LESSON_DELETE_REQUESTED';

export const lessonDeleteRequestedAction = data => ({
  type: LESSON_DELETE_REQUESTED,
  payload: data,
});