import {
    CONNECTION_KIND,
    ConnectionInterface,
    SCREENPLAY_TYPE,
    StepInterface as LocalStepInterface,
    StepToPrepareInterface
} from "../../containers/Screenplay/constants";
import {
    StepInterface,
    StepSectionPositionsRequestData,
    StepTransitionsPositionRequestData
} from "../../api/Steps";
import {PrepareTransitionToDisplay} from "../transitions/helpers";
import {PrepareSectionsToDisplay} from "../sections/helpers";
import {PrepareTestToDisplay} from "../tests/helpers";
import {LessonContentType, LongreadSectionInterface} from "../../components/Longread/constants";
import {LocalTestInterface} from "../../containers/TestForm/constants";
import {ValidateTestQuestion} from "../../components/TestQuestion/constants";
import {HintInterface} from "../../components/Hints/constants";

export const PrepareStepToUpload = (step: LocalStepInterface | StepToPrepareInterface, lessonId?: string): FormData => {
    const formData: FormData = new FormData();
    if (lessonId) {
        formData.append('lesson_id', lessonId);
    }
    if (typeof step.title === 'string') {
        formData.append('name', step.title);
    }
    if (step.type) {
        formData.append('kind', step.type);
    }
    if (typeof step.x === 'number') {
        formData.append('position_x', JSON.stringify(step.x));
    }
    if (typeof step.y === 'number') {
        formData.append('position_y', JSON.stringify(step.y));
    }
    if (typeof step.isFirst === 'boolean') {
        formData.append('first_step', JSON.stringify(step.isFirst));
    }
    if (typeof step.isLast === 'boolean') {
        formData.append('last_step', JSON.stringify(step.isLast));
    }
    if ([SCREENPLAY_TYPE.IMAGE, SCREENPLAY_TYPE.VIDEO, SCREENPLAY_TYPE.AUDIO].includes(step.type)) {
        if (step.content?.fileId) {
            formData.append('attachment_attributes[id]', step.content.fileId);
        }
        if (step.content?.hasOwnProperty('file')) {
            formData.append('attachment_attributes[file]', step.content.file);
        }
        if (step.type === SCREENPLAY_TYPE.AUDIO && typeof step.content.filename === 'string') {
            formData.append('attachment_attributes[display_name]', step.content.filename);
        }
        if (step.type === SCREENPLAY_TYPE.IMAGE && step.hasOwnProperty('hints')) {
          formData.append('hints', JSON.stringify(step.hints));
        }
        if (step.type === SCREENPLAY_TYPE.IMAGE && step.content?.hasOwnProperty('hints')) {
          if (step.content?.hints?.length) {
            step.content.hints?.forEach((hint) => {
                formData.append('content[hints[][position_x]]', JSON.stringify(hint.positionX));
                formData.append('content[hints[][position_y]]', JSON.stringify(hint.positionY));
                formData.append('content[hints[][aspect_ratio]]', JSON.stringify(hint.aspectRatio));
                formData.append('content[hints[][text]]', hint.text);
            });
          } else {
            formData.append('content[hints[]]', JSON.stringify([]))
          }
        }
        if (step.content?.hasOwnProperty('duration')) {
            formData.append('attachment_attributes[duration]', JSON.stringify(step.content.duration));
        }
    } else if (step.type === SCREENPLAY_TYPE.TEXT) {
        const text: string = step.content.text;
        if (typeof text === 'string') {
            formData.append('content[text]', text)
        }
    } else if (step.type === SCREENPLAY_TYPE.GLOSSARY) {
        step.content.cards?.forEach((card) => {
            formData.append('content[cards[][title]]', card.title);
            formData.append('content[cards[][text]]', card.text);
        });
    }
    new Response(formData).text().then(console.log)
    return formData;
};

export const PrepareStepTransitionsPositionToUpload = (connections: ConnectionInterface[])
    : StepTransitionsPositionRequestData => {
    return {
        transitions: connections.map((connection, index) => ({
            id: connection.id,
            position: index + 1,
        }))
    }
};

export const ValidateStep = (step: LocalStepInterface): string => {
    let validationErrorKey: string = '';
    step.connections.forEach((connection) => {
       if (connection.kind === CONNECTION_KIND.BUTTON && !connection.text) {
           validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
       }
    });
    switch (step.type) {
        case SCREENPLAY_TYPE.TEXT:
            if (!step.content?.text) {
                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
            }
            break;
        case SCREENPLAY_TYPE.IMAGE:
            if (!step.content?.fileUrl) {
                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
            }
            break;
        case SCREENPLAY_TYPE.VIDEO:
            if (!step.content?.fileUrl) {
                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
            }
            break;
        case SCREENPLAY_TYPE.AUDIO:
            if (!step.content?.fileUrl || !step.content?.filename) {
                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
            }
            break;
        case SCREENPLAY_TYPE.GLOSSARY:
            step.content?.cards.forEach((card) => {
                if (!card.title || !card.text) {
                    validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                }
            });
            break;
        case SCREENPLAY_TYPE.LONGREAD:
            step.content?.sections.forEach((section) => {
                switch (section.kind) {
                    case LessonContentType.TEXT:
                        if (!section.content.text) {
                            validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                        }
                        break;
                    case LessonContentType.GLOSSARY:
                        section.content.cards.forEach((card) => {
                            if (!card.title || !card.text) {
                                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                            }
                        });
                        break;
                    case LessonContentType.IMAGE:
                        if (!section.attachment_attributes.url) {
                            validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                        }
                        break;
                    case LessonContentType.VIDEO:
                        if (!section.attachment_attributes.url) {
                            validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                        }
                        break;
                    case LessonContentType.AUDIO:
                        if (!section.attachment_attributes.url || !section.attachment_attributes.display_name) {
                            validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
                        }
                        break;
                }
            });
            break;
        case SCREENPLAY_TYPE.TEST:
            const test: LocalTestInterface = step.content.test;
            if (!test.name || !test.fail_text || !test.success_text
                || !(parseInt(test.right_answers_count) >= 0) || !test.questions.length) {
                validationErrorKey = 'SCREENPLAY.ERROR_REQUIRED_FIELDS';
            }
            test.questions.forEach((item) => {
                validationErrorKey = ValidateTestQuestion(item, validationErrorKey);
            });
            break;
        default:
            break;
    }
    return validationErrorKey;
};

export const ValidateStepConnections = (step: LocalStepInterface, steps: LocalStepInterface[]): string => {
    let validationErrorKey: string = '';
    if (!step.isFirst) {
        const isPreviousConnectionFulfilled: boolean = steps.some((item) => {
            return !!item.connections
                .find((connection) => connection.end?.id === step.id);
        });
        if (!isPreviousConnectionFulfilled) {
            validationErrorKey = 'SCREENPLAY.ERROR_NO_CONNECTIONS';
        }
    }
    if (!step.isLast) {
        step.connections.forEach((connection) => {
            const isFulfilled: boolean = !!(connection.end?.id);
            if (!isFulfilled) {
                validationErrorKey = 'SCREENPLAY.ERROR_NO_CONNECTIONS';
            }
        })
    }

    return validationErrorKey;
};

export const PrepareStepsToDisplay = (steps: StepInterface[]): LocalStepInterface[] => {
    const preparedSteps: LocalStepInterface[] = steps?.map((step) => {
        const preparedStep: LocalStepInterface = {
            id: step.id,
            title: step.name,
            x: step.position_x,
            y: step.position_y,
            type: step.kind,
            isFirst: step.first_step,
            isLast: step.last_step,
            hints: step.hints,
            content: {
                filename: '',
            },
            connections: step.transitions.map((transition) => PrepareTransitionToDisplay(transition)),
        };
        switch (step.kind) {
            case SCREENPLAY_TYPE.TEXT:
                preparedStep.content = step.content || {
                    text: '',
                };
                break;
            case SCREENPLAY_TYPE.IMAGE:
              if (step.content?.hints) {
                let preparedHints = step.content?.hints?.map((hint, index) => PrepareHintFromResponse(hint, index));
                if (preparedHints.length) preparedStep.content.hints = preparedHints;
              }
              break;
            case SCREENPLAY_TYPE.GLOSSARY:
                if (step.content) {
                    preparedStep.content.cards = step.content.cards.map((item, index) => {
                        return {
                            ...item,
                            id: String(index),
                        }
                    });
                    preparedStep.content.idCounter = preparedStep.content.cards.length;
                } else {
                    preparedStep.content = {
                        cards: [],
                    };
                }
                break;
            case SCREENPLAY_TYPE.LONGREAD:
                preparedStep.content = {
                    sections: PrepareSectionsToDisplay(step.sections) || [],
                };
                break;
            case SCREENPLAY_TYPE.TEST:
                preparedStep.content = {
                    test: PrepareTestToDisplay(step.test),
                };
                break;
        }
        if (step.attachment) {
            preparedStep.content.fileId = step.attachment.id;
            preparedStep.content.fileUrl = step.attachment.download_url;
            preparedStep.content.filename = step.attachment.display_name;
            preparedStep.content.duration = step.attachment.duration;
        }
        preparedStep.content.validationErrorKey = ValidateStep(preparedStep);
        return preparedStep;
    });
    preparedSteps?.forEach((step) => {
        if (!step.content.validationErrorKey) {
            step.content.validationErrorKey = ValidateStepConnections(step, preparedSteps);
        }
    });

    return preparedSteps;
};

export const GetScreenplayIsInvalid = (steps: LocalStepInterface[]): boolean => {
    if (steps?.length) {
        return !!steps.find((step) => step.content.validationErrorKey);
    } else {
        return true;
    }
};

export const PrepareStepSectionPositionsToUpload = (sections: LongreadSectionInterface[])
    : StepSectionPositionsRequestData => {
    return {
        sections: sections.map((connection, index) => ({
            id: connection.id,
            position: index + 1,
        }))
    }
};

export const PrepareHintFromResponse = (hint, index?: number): HintInterface => {
  return {
    positionX: Number(hint.position_x),
    positionY: Number(hint.position_y),
    aspectRatio: Number(hint.aspect_ratio),
    text: hint.text,
    id: (index && String(index))
  }
}
