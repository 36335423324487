import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_CITIES,
    GET_CITY,
    ADD_CITY,
    UPDATE_CITY,
    DELETE_CITY, GetCitiesDataInterface, GetCitiesParams,
} from "../../api/Cities";
import {
    CITIES_GET_REQUESTED, CitiesGetRequestedPayload,
    CITY_ADD_REQUESTED,
    CITY_DELETE_REQUESTED,
    CITY_GET_REQUESTED,
    CITY_UPDATE_REQUESTED,
    setCitiesAction, setCitiesMetaAction,
    setCityAction,
} from "../cities/actions";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {DefaultAction} from "../interface";

function* getCities(action: DefaultAction<CitiesGetRequestedPayload>) {
    try {
        const params: GetCitiesParams = action.payload.data;
        const responseData: GetCitiesDataInterface = (yield call(GET_CITIES, params)).data;
        yield put(setCitiesAction(responseData.cities));
        if (params && params.paginate) {
            yield put(setCitiesMetaAction(responseData.meta));
            if (action.payload.isSearch) {
                LogAmplitudeEvent(AMPLITUDE_EVENT.SETTINGS_CITY_SEARCH, {
                    keywords: params.display_name,
                    numbers_of_results_returned: responseData.meta.total,
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
}

function* getCity(action) {
    try {
        const response = yield call(GET_CITY, action.payload.id);
        yield put(setCityAction(response.data));
    } catch (e) {
        console.log(e);
    }
}

function* addCity(action) {
    try {
        yield call(ADD_CITY, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        const isError406 = error.response.status === 406;
        if (isError406) {
            yield put(createNotificationAction({ text: 'SETTINGS.CITY_FAILED_ADD_NOTIFICATION_406'}));
        }
        LogAmplitudeEvent(AMPLITUDE_EVENT.SETTINGS_CITY_CREATE_FAIL, {
            error_type: isError406 ? error.response.data : error.toString(),
        });
        console.log(error);
    }
}

function* updateCity(action) {
    try {
        yield call(UPDATE_CITY, action.payload.id, action.payload.body);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        if (error.response.status === 406) {
            yield put(createNotificationAction({ text: 'SETTINGS.CITY_FAILED_UPDATE_NOTIFICATION_406'}));
        } else {
            console.log(error);
        }
    }
}

function* deleteCity(action) {
    try {
        yield call(DELETE_CITY, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* citiesSaga() {
    yield takeLatest(CITIES_GET_REQUESTED, getCities);
    yield takeLatest(CITY_GET_REQUESTED, getCity);
    yield takeLatest(CITY_ADD_REQUESTED, addCity);
    yield takeLatest(CITY_UPDATE_REQUESTED, updateCity);
    yield takeLatest(CITY_DELETE_REQUESTED, deleteCity);
}

export default citiesSaga;