import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CREATE_COURSE,
    DELETE_COURSE,
    GET_ALL_COURSES,
    GET_COURSE,
    GET_COURSE_CONTENT,
    UPDATE_COURSE,
    CoursesDataInterface, COPY_COURSE, CreateCourseDataResponse, CopyCourseDataResponse,
} from "../../api/Courses";
import {
    COURSE_COPY_REQUESTED,
    COURSE_CONTENT_GET_REQUESTED,
    COURSE_CONTENT_POSITION_UPDATE_REQUESTED,
    COURSE_CREATE_REQUESTED,
    COURSE_DELETE_REQUESTED,
    COURSE_GET_REQUESTED,
    COURSE_UPDATE_REQUESTED,
    COURSES_GET_REQUESTED,
    setCourseAction,
    setCourseContentAction,
    setCoursesAction,
    resetNewCourseAction,
    CoursesGetRequestedActionPayload,
    CoursesCopyRequestedPayload,
    CourseUpdateRequestedPayload,
    CourseContentPositionUpdateRequestedPayload,
} from "../courses/actions";
import {DefaultAction} from "../interface";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {PrepareCourseContentPositionsToUpload} from "../lessons/helpers";

function* getAllCourses(action: DefaultAction<CoursesGetRequestedActionPayload>) {
    try {
        const params = action.payload.data;
        const responseData: CoursesDataInterface = (yield call(GET_ALL_COURSES, params)).data;
        const totalResultsParam = {
            number_of_results_returned: responseData.meta && responseData.meta.total,
        };
        if (action.payload.isSearch) {
            LogAmplitudeEvent(params.published
                ? AMPLITUDE_EVENT.PUBLISHED_COURSE_SEARCH
                : params.archived
                    ? AMPLITUDE_EVENT.ARCHIVED_COURSE_SEARCH
                    : AMPLITUDE_EVENT.SAVED_COURSE_SEARCH, {
                keywords: params.name,
                ...totalResultsParam,
            });
        }
        if (action.payload.isPositionFilter) {
            let event: AMPLITUDE_EVENT;
            if (action.payload.isCompilationFilter) {
                event = action.payload.isCompilationEdit
                    ? AMPLITUDE_EVENT.COMPILATION_EDIT_COURSE_POSITION_FILTER
                    : AMPLITUDE_EVENT.COMPILATION_CREATE_COURSE_POSITION_FILTER;
            } else {
                event = params.published
                    ? AMPLITUDE_EVENT.PUBLISHED_COURSE_POSITION_FILTER
                    : params.archived
                        ? AMPLITUDE_EVENT.ARCHIVED_COURSE_POSITION_FILTER
                        : AMPLITUDE_EVENT.SAVED_COURSE_POSITION_FILTER;
            }
            LogAmplitudeEvent(event, {
                positions: action.payload.positionLabels,
                ...totalResultsParam,
            });
        }
        if (action.payload.isCityFilter) {
            let event: AMPLITUDE_EVENT;
            if (action.payload.isCompilationFilter) {
                event = action.payload.isCompilationEdit
                    ? AMPLITUDE_EVENT.COMPILATION_EDIT_COURSE_CITY_FILTER
                    : AMPLITUDE_EVENT.COMPILATION_CREATE_COURSE_CITY_FILTER;
            } else {
                event = params.published
                    ? AMPLITUDE_EVENT.PUBLISHED_COURSE_CITY_FILTER
                    : params.archived
                        ? AMPLITUDE_EVENT.ARCHIVED_COURSE_CITY_FILTER
                        : AMPLITUDE_EVENT.SAVED_COURSE_CITY_FILTER;
            }
            LogAmplitudeEvent(event, {
                city: action.payload.cityLabels,
                ...totalResultsParam,
            });
        }
        yield put(setCoursesAction(responseData));
    } catch (e) {
        console.log(e);
    }
}

function* getCourse(action) {
    try {
        const response = yield call(GET_COURSE, action.payload.id);
        yield put(setCourseAction(response.data));
    } catch (e) {
        console.log(e);
    }
}

function* getCourseContent(action) {
    try {
        const response = yield call(GET_COURSE_CONTENT, action.payload.id);
        yield put(setCourseContentAction(response.data));
    } catch (e) {
        console.log(e);
    }
}

function* createCourse(action) {
    try {
        const response: CreateCourseDataResponse = (yield call(CREATE_COURSE, action.payload.data)).data;
        const courseId: string = response.model.id;

        LogAmplitudeEvent(action.payload.isPublish
            ? AMPLITUDE_EVENT.COURSE_CREATE_PUBLISHED
            : AMPLITUDE_EVENT.COURSE_CREATE_SAVED, {
            course_id: courseId,
            course_name: response.model.name,
        });
        yield put(resetNewCourseAction());
        if (action.payload.callback) {
            action.payload.callback(response.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* copyCourse(action: DefaultAction<CoursesCopyRequestedPayload>) {
    try {
        const responseData: CopyCourseDataResponse = (yield call(COPY_COURSE, action.payload.id)).data;
        if (action.payload.isCourses) {
            LogAmplitudeEvent(action.payload.published
                ? AMPLITUDE_EVENT.PUBLISHED_COURSE_COPY
                : action.payload.archived
                    ? AMPLITUDE_EVENT.ARCHIVED_COURSE_COPY
                    : AMPLITUDE_EVENT.SAVED_COURSE_COPY, {
                course_id: action.payload.id,
                course_name: action.payload.name,
                course_copy_id: responseData.model.id,
                course_copy_name: responseData.model.name,
            });
        }
        yield put(createNotificationAction({ text: 'COURSES.SUCCESS_COPY_NOTIFICATION'}));
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'COURSES.FAILED_COPY_NOTIFICATION'}));
        console.log(e);
    }
}

function* updateCourse(action: DefaultAction<CourseUpdateRequestedPayload>) {
    try {
        yield call(UPDATE_COURSE, action.payload.id, action.payload.data);
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateCourseContentPosition(action: DefaultAction<CourseContentPositionUpdateRequestedPayload>) {
    try {
        yield call(UPDATE_COURSE, action.payload.courseId,
            PrepareCourseContentPositionsToUpload(action.payload.data));
    } catch (e) {
        console.log(e);
    }
}

function* deleteCourse(action) {
    try {
        yield call(DELETE_COURSE, action.payload.id);
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* coursesSaga() {
    yield takeLatest(COURSES_GET_REQUESTED, getAllCourses);
    yield takeLatest(COURSE_GET_REQUESTED, getCourse);
    yield takeLatest(COURSE_CONTENT_GET_REQUESTED, getCourseContent);
    yield takeLatest(COURSE_CREATE_REQUESTED, createCourse);
    yield takeLatest(COURSE_COPY_REQUESTED, copyCourse);
    yield takeLatest(COURSE_UPDATE_REQUESTED, updateCourse);
    yield takeLatest(COURSE_CONTENT_POSITION_UPDATE_REQUESTED, updateCourseContentPosition);
    yield takeLatest(COURSE_DELETE_REQUESTED, deleteCourse);
}

export default coursesSaga;