import {DefaultAction} from "../interface";
import {
  CompilationInterface,
  CompilationsDataInterface,
  GetAllCompilationsParamsInterface
} from "../../api/Compilations";
import {PayloadWithCallback} from "../../interfaces/store";

export const ACTION_SET_COMPILATIONS = 'ACTION_SET_COMPILATIONS';

export const setCompilationsAction = (data: CompilationsDataInterface) => ({
  type: ACTION_SET_COMPILATIONS,
  payload: data,
});

export const ACTION_SET_COMPILATION = 'ACTION_SET_COMPILATION';

export const setCompilationAction = (data: CompilationInterface) => ({
  type: ACTION_SET_COMPILATION,
  payload: data,
});

export const COMPILATIONS_GET_REQUESTED = 'COMPILATIONS_GET_REQUESTED';

export interface CompilationsGetRequestedPayload {
  data: GetAllCompilationsParamsInterface;
  isSearch?: boolean;
  isPositionFilter?: boolean;
  isCityFilter?: boolean;
  positionLabels?: string[];
  cityLabels?: string[];
}

export const compilationsGetRequestedAction = (data: CompilationsGetRequestedPayload) => ({
  type: COMPILATIONS_GET_REQUESTED,
  payload: data,
});

export const COMPILATION_GET_REQUESTED = 'COMPILATION_GET_REQUESTED';

export interface CompilationGetRequestedPayload {
  id: string;
}

export const compilationGetRequestedAction = (data: CompilationGetRequestedPayload) => ({
  type: COMPILATION_GET_REQUESTED,
  payload: data,
});

export const COMPILATION_CREATE_REQUESTED = 'COMPILATION_CREATE_REQUESTED';

export interface CompilationCreateRequestedPayload {
  data: FormData;
  callback?: () => void;
}

export const compilationCreateRequestedAction = (data: CompilationCreateRequestedPayload) => ({
  type: COMPILATION_CREATE_REQUESTED,
  payload: data,
});

export const COMPILATION_COPY_REQUESTED = 'COMPILATION_COPY_REQUESTED';

export interface CompilationCopyRequestedPayload extends PayloadWithCallback {
  id: string;
  name: string;
  published: boolean;
  archived: boolean;
  t: (key: string) => string;
}

export const compilationCopyRequestedAction = (data: CompilationCopyRequestedPayload) => ({
  type: COMPILATION_COPY_REQUESTED,
  payload: data,
});

export const COMPILATION_UPDATE_REQUESTED: string = 'COMPILATION_UPDATE_REQUESTED';

export interface CompilationUpdateRequestedPayload extends PayloadWithCallback {
  id: string;
  data: FormData | CompilationInterface;
}

export const compilationUpdateRequestedAction = (data: CompilationUpdateRequestedPayload): DefaultAction<CompilationUpdateRequestedPayload> => ({
  type: COMPILATION_UPDATE_REQUESTED,
  payload: data,
});

export const COMPILATION_DELETE_REQUESTED = 'COMPILATION_DELETE_REQUESTED';

export interface CompilationDeleteRequestedPayload extends PayloadWithCallback {
  id: string;
}

export const compilationDeleteRequestedAction = (data: CompilationDeleteRequestedPayload) => ({
  type: COMPILATION_DELETE_REQUESTED,
  payload: data,
});