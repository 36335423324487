import {BillingInfoInterface} from "../../api/Billing";
import {EmptyObjectType} from "../../interfaces/default";
import {ACTION_SET_BILLING_INFO} from "./actions";

interface StateInterface {
    info: EmptyObjectType<BillingInfoInterface>;
}

const defaultState: StateInterface = {
    info: {
        account: {
            amount_cents: 0,
        },
        current_subscription_period: {
            active_users_count: 0,
            since: 0,
            till: 0,
            used_amount_cents: 0,
        },
        plan: {
            amount_cents_per_user: 0,
            limit_users_count: 0,
            tag: '',
        },
        tenant_name: '',
        total_users_count: 0,
    },
};

function setBillingInfo(state, payload: BillingInfoInterface) {
    return {
        ...state,
        info: payload,
    }
}

const billingReducer = (state = defaultState, action): StateInterface => {
    switch (action.type) {
        case ACTION_SET_BILLING_INFO: {
            return setBillingInfo(state, action.payload);
        }
        default: {
            return state;
        }
    }
}

export default billingReducer;
