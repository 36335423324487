import { call, takeLatest } from 'redux-saga/effects';
import {DefaultAction} from "../interface";
import {
    CREATE_SECTION,
    CreateSectionResponse,
    DELETE_SECTION,
    UPDATE_SECTION,
    UpdateSectionResponse
} from "../../api/Sections";
import {PrepareSectionToUpload} from "./helpers";
import {
    SECTION_CREATE_REQUESTED,
    SECTION_DELETE_REQUESTED, SECTION_UPDATE_REQUESTED,
    SectionCreateRequestedPayload,
    SectionDeleteRequestedPayload,
    SectionUpdateRequestedPayload
} from "./actions";

function* createSection(action: DefaultAction<SectionCreateRequestedPayload>) {
    try {
        const responseData: CreateSectionResponse = (yield call(CREATE_SECTION,
            PrepareSectionToUpload(action.payload.data, action.payload.stepId))).data;
        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateSection(action: DefaultAction<SectionUpdateRequestedPayload>) {
    try {
        const responseData: UpdateSectionResponse = (yield call(UPDATE_SECTION, action.payload.data.id,
            PrepareSectionToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteSection(action: DefaultAction<SectionDeleteRequestedPayload>) {
    try {
        yield call(DELETE_SECTION, action.payload.id);
    } catch (e) {
        console.log(e);
    }
}

function* sectionsSaga() {
    yield takeLatest(SECTION_CREATE_REQUESTED, createSection);
    yield takeLatest(SECTION_UPDATE_REQUESTED, updateSection);
    yield takeLatest(SECTION_DELETE_REQUESTED, deleteSection);
}

export default sectionsSaga;