import {
  SET_NEWS_DATA,
  SET_NEWS_FORM,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {CompilationInterface, CompilationsDataInterface} from "../../api/Compilations";
import {NewsDataInterface, NewsInterface} from "../../api/News";

interface StateInterface {
  newsData: NewsDataInterface;
  newsForm: NewsInterface;
}

const defaultState: StateInterface = {
  newsData: {
    publications: null,
    meta: PAGES_DEFAULT_META,
  },
  newsForm: null,
};

const newsReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case SET_NEWS_DATA: {
      return setNewsData(state, action.payload);
    }
    case SET_NEWS_FORM: {
      return setNewsForm(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setNewsData(state, payload: CompilationsDataInterface) {
  return {
    ...state,
    newsData: payload,
  }
}

function setNewsForm(state, payload: CompilationInterface) {
  return {
    ...state,
    newsForm: payload,
  }
}

export default newsReducer;