import {AuthApi} from "./index";
import {ApiDefaultResponse} from "../interfaces/api";

const TESTS_VARIANTS_API_URL: string = 'variants';

export enum TEST_VARIANT_KIND {
    TEXT = 'text',
    IMAGE = 'image',
}

export interface TestVariantInterface {
    id: string;
    image_id: string;
    image_url: string;
    kind: TEST_VARIANT_KIND;
    right: boolean;
    position: number;
    text: string;
}

export const CREATE_VARIANT = (data: FormData) => {
    return AuthApi.post<ApiDefaultResponse<TestVariantInterface>>(`${TESTS_VARIANTS_API_URL}`, data);
};

export const UPDATE_VARIANT = (id: string, data: FormData) => {
    return AuthApi.patch<ApiDefaultResponse<TestVariantInterface>>(`${TESTS_VARIANTS_API_URL}/${id}`, data);
};

export const DELETE_VARIANT = (id: string) => {
    return AuthApi.delete<ApiDefaultResponse<TestVariantInterface>>(`${TESTS_VARIANTS_API_URL}/${id}`);
};