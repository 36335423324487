import {PayloadWithCallback} from "../../interfaces/store";
import {TenantInterface} from "../../api/Tenant";

export const ACTION_SET_TENANT: string = 'ACTION_SET_TENANT';

export const setTenantAction = (data: TenantInterface) => ({
  type: ACTION_SET_TENANT,
  payload: data,
});

export const TENANT_GET_REQUESTED: string = 'TENANT_GET_REQUESTED';

export interface TenantGetRequestedPayload {
  isInitial?: boolean;
}

export const tenantGetRequestedAction = (payload: TenantGetRequestedPayload = {}) => ({
  type: TENANT_GET_REQUESTED,
  payload,
});

export interface TenantUpdateRequestedInterface extends PayloadWithCallback {
  data: TenantInterface;
}

export const TENANT_UPDATE_REQUESTED = 'TENANT_UPDATE_REQUESTED';

export const tenantUpdateRequestedAction = (data: TenantUpdateRequestedInterface) => ({
  type: TENANT_UPDATE_REQUESTED,
  payload: data,
});