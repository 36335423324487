import { call, put, takeLatest } from 'redux-saga/effects'
import {CREATE_USER, DELETE_USER, GET_USERS, UPDATE_USER} from "../../api/Users";
import {
    setErrorCodeAction, setUserProfileAction,
    setUsersAction,
    USER_CREATE_REQUESTED,
    USER_DELETE_REQUESTED, USER_GET_REQUESTED,
    USER_UPDATE_REQUESTED,
    USERS_GET_REQUESTED,
} from "../users/actions";
import {getLocalStorageItem} from "../../utils/localStorage";
import {LOCAL_STORAGE_KEYS} from "../../constants/localStorage";
import {UserInterface, UsersDataInterface} from "../users/interface";
import {LogAmplitudeEvent, SetAmplitudeUserId, SetAmplitudeUserProperties} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";

function* getUsers(action) {
    try {
        const data: UsersDataInterface = (yield call(GET_USERS, action.payload.data)).data;
        if (action.payload.isSearch) {
            LogAmplitudeEvent(AMPLITUDE_EVENT.EMPLOYEE_SEARCH, {
                keywords: action.payload.data.name,
                number_of_results_returned: data.meta.total,
            });
        }
        yield put(setUsersAction(data));
    } catch (e) {
        console.log(e);
    }
}

function* getUser() {
    try {
        const email: string = getLocalStorageItem(LOCAL_STORAGE_KEYS.EMAIL);
        if (email) {
            SetAmplitudeUserId(email);
            const data: UsersDataInterface = (yield call(GET_USERS, { email, })).data;
            const userProfile: UserInterface = data.users.length && data.users[0];
            yield put(setUserProfileAction(userProfile));
        } else {
            SetAmplitudeUserId();
            SetAmplitudeUserProperties();
            yield put(setUserProfileAction({}));
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateUser(action) {
    try {
        yield call(UPDATE_USER, action.payload.id, action.payload.data);
        LogAmplitudeEvent(AMPLITUDE_EVENT.EMPLOYEE_EDIT_FINISHED, {
          email: action.payload?.data?.email
        });
        yield call(getUser);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        if (error.response) {
            yield put(setErrorCodeAction(error.response.status));
        } else {
            console.log(error);
        }
        LogAmplitudeEvent(AMPLITUDE_EVENT.EMPLOYEE_EDIT_FINISHED_FAIL, {
            error_type: error.response.status === 406 ? error.response.data : error.toString(),
        });
    }
}

function* createUser(action) {
    try {
        yield call(CREATE_USER, action.payload.data);
        LogAmplitudeEvent(AMPLITUDE_EVENT.EMPLOYEE_CREATE_FINISHED_SUCCESS, {
            email: action.payload.data.email,
        });
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        if (error.response) {
            yield put(setErrorCodeAction(error.response.status));
        } else {
            console.log(error);
        }
        LogAmplitudeEvent(AMPLITUDE_EVENT.EMPLOYEE_CREATE_FINISHED_FAIL, {
            error_type: error.response.status === 406 ? error.response.data : error.toString(),
        });
    }
}

function* deleteUser(action) {
    try {
        yield call(DELETE_USER, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}


function* usersSaga() {
    yield takeLatest(USERS_GET_REQUESTED, getUsers);
    yield takeLatest(USER_GET_REQUESTED, getUser);
    yield takeLatest(USER_UPDATE_REQUESTED, updateUser);
    yield takeLatest(USER_CREATE_REQUESTED, createUser);
    yield takeLatest(USER_DELETE_REQUESTED, deleteUser);
}

export default usersSaga;
