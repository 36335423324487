import { buffers, eventChannel, END } from 'redux-saga';
import { call } from 'redux-saga/effects';

export function uploadFileChannel(endpoint, data, queryParams) {
  return eventChannel(emitter => {
    endpoint(data, queryParams, {
      onUploadProgress: progressEvent => {
        let progress = (progressEvent.loaded / progressEvent.total) * 100;
        emitter({ progress })
      }
    }).then((response) => {
      emitter({ response, success: true })
      emitter(END)
    })

    return () => {}
  })
}
