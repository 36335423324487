import { call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    QUESTION_CREATE_REQUESTED,
    QUESTION_DELETE_REQUESTED,
    QUESTION_UPDATE_REQUESTED,
    QUESTION_VARIANT_POSITIONS_UPDATE_REQUESTED, QuestionVariantPositionsUpdateRequestedPayload,
} from "./actions";
import {PrepareQuestionToUpload, PrepareQuestionVariantPositionsToUpload} from "./helpers";
import {CREATE_QUESTION, DELETE_QUESTION, UPDATE_QUESTION} from "../../api/TestQuestions";
import {DefaultAction} from "../interface";

function* createQuestion(action) {
    try {
        const responseData = (yield call(CREATE_QUESTION,
            PrepareQuestionToUpload(action.payload.data, action.payload.testId))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateQuestion(action) {
    try {
        const responseData = (yield call(UPDATE_QUESTION, action.payload.data.id,
            PrepareQuestionToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateQuestionVariantPositions(action: DefaultAction<QuestionVariantPositionsUpdateRequestedPayload>) {
    try {
        const responseData = (yield call(UPDATE_QUESTION, action.payload.questionId,
            PrepareQuestionVariantPositionsToUpload(action.payload.data))).data;

        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteQuestion(action) {
    try {
        yield call(DELETE_QUESTION, action.payload.id);

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* testQuestionsSaga() {
    yield takeEvery(QUESTION_CREATE_REQUESTED, createQuestion);
    yield takeLatest(QUESTION_UPDATE_REQUESTED, updateQuestion);
    yield takeLatest(QUESTION_VARIANT_POSITIONS_UPDATE_REQUESTED, updateQuestionVariantPositions);
    yield takeLatest(QUESTION_DELETE_REQUESTED, deleteQuestion);
}

export default testQuestionsSaga;