import {AuthApi} from "./index";
import {PaginationMetaInterface} from "./interface";

export enum POSITIONS_VIEW_PARAMS {
    WITH_COURSES = 'with_courses',
}

export interface GetPositionsParams {
    display_name?: string;
    limit?: number;
    offset?: number;
    paginate?: boolean;
    view?: POSITIONS_VIEW_PARAMS;
}

export interface PositionInterface {
    display_name: string;
    id: string;
    courses_count?: number;
}

export interface GetPositionsDataInterface {
    positions: PositionInterface[];
    meta?: PaginationMetaInterface;
}

export const GET_POSITIONS = (params: GetPositionsParams = {}) => {
    return AuthApi.get<GetPositionsDataInterface>(`positions`, {
        params: {
            ...params,
        }
    });
};

export const GET_POSITION = (id: string) => {
    return AuthApi.get<PositionInterface>(`positions/${id}`);
};

export interface AddPositionBody {
    display_name: string;
}

export const ADD_POSITION = (body: AddPositionBody) => {
    return AuthApi.post(`positions`, body);
};

export interface UpdatePositionBody {
    display_name: string;
}

export const UPDATE_POSITION = (id: string, body: UpdatePositionBody) => {
    return AuthApi.patch(`positions/${id}`, body);
};

export const DELETE_POSITION = (id: string) => {
    return AuthApi.delete(`positions/${id}`);
};