import {
    ACTION_SET_MOBILE_USERS,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {MobileUsersDataInterface} from "./interface";

interface StateInterface {
    mobileUsersData: MobileUsersDataInterface;
}

const defaultState: StateInterface = {
    mobileUsersData: {
        mobile_users: null,
        meta: PAGES_DEFAULT_META,
    },
};

const mobileUserReducer = (state = defaultState, action): StateInterface => {
    switch (action.type) {
        case ACTION_SET_MOBILE_USERS: {
            return setMobileUsersData(state, action.payload);
        }
        default: {
            return state;
        }
    }
};

function setMobileUsersData(state, payload: MobileUsersDataInterface) {
    return {
        ...state,
        mobileUsersData: payload,
    }
}

export default mobileUserReducer;