import {TabInterface} from "../../components/Tabs/interface";

export enum MOBILE_USERS_CATEGORY {
    REGISTERED = 'registered',
    ALL = 'all',
}

export const MOBILE_USERS_TABS: TabInterface[] = [
    {value: MOBILE_USERS_CATEGORY.REGISTERED, name: 'MOBILE_USERS.CATEGORY_REGISTERED',},
    {value: MOBILE_USERS_CATEGORY.ALL, name: 'MOBILE_USERS.CATEGORY_ALL_USERS',},
];