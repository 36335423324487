import {
  ACTION_SET_COMPILATIONS,
  ACTION_SET_COMPILATION,
} from './actions';
import {PAGES_DEFAULT_META} from "../../constants/pages";
import {CompilationInterface, CompilationsDataInterface} from "../../api/Compilations";

interface StateInterface {
  compilationsData: CompilationsDataInterface;
  compilation: CompilationInterface;
}

const defaultState: StateInterface = {
  compilationsData: {
    compilations: null,
    meta: PAGES_DEFAULT_META,
  },
  compilation: null,
};

const compilationsReducer = (state = defaultState, action): StateInterface => {
  switch (action.type) {
    case ACTION_SET_COMPILATIONS: {
      return setCompilationsData(state, action.payload);
    }
    case ACTION_SET_COMPILATION: {
      return setCompilation(state, action.payload);
    }
    default: {
      return state;
    }
  }
};

function setCompilationsData(state, payload: CompilationsDataInterface) {
  return {
    ...state,
    compilationsData: payload,
  }
}

function setCompilation(state, payload: CompilationInterface) {
  return {
    ...state,
    compilation: payload,
  }
}

export default compilationsReducer;