import { call, put, takeLatest } from 'redux-saga/effects';
import {
    LESSON_COPY_REQUESTED,
    LESSON_CREATE_REQUESTED,
    LESSON_DELETE_REQUESTED,
    LESSON_GET_REQUESTED,
    LESSON_UPDATE_PUBLISHED_REQUESTED,
    LESSON_UPDATE_REQUESTED, LessonCreateRequestedPayload, LessonUpdateRequestedPayload,
    setLessonAction,
} from "../lessons/actions";
import {
    COPY_LESSON,
    CREATE_LESSON,
    CreateLessonResponseData,
    DELETE_LESSON,
    GET_LESSON, LessonInterface,
    UPDATE_LESSON, UpdateLessonResponseData,
} from "../../api/Lessons";
import {createNotificationAction} from "../notifications/actions";
import {LogAmplitudeEvent} from "../../utils/amplitude";
import {AMPLITUDE_EVENT} from "../../constants/amplitude";
import {DefaultAction} from "../interface";

function* getLesson(action) {
    try {
        const lesson: LessonInterface = (yield call(GET_LESSON, action.payload.id)).data;
        yield put(setLessonAction({lesson}));
    } catch (e) {
        console.log(e);
    }
}

function* createLesson(action: DefaultAction<LessonCreateRequestedPayload>) {
    try {
        const responseData: CreateLessonResponseData = (yield call(CREATE_LESSON, action.payload.data)).data;
        if (action.payload.isManualCreation) {
            LogAmplitudeEvent(AMPLITUDE_EVENT.LESSON_CREATE_SAVED, {
                content_number: responseData.model.position,
                lesson_name: responseData.model.name,
            });
        }
        yield put(createNotificationAction({ text: 'LESSON_FORM.SUCCESS_SAVE_NOTIFICATION'}));
        if (action.payload.callback) {
            action.payload.callback(responseData.model);
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'LESSON_FORM.FAILED_SAVE_NOTIFICATION'}));
        console.log(e);
    }
}

function* copyLesson(action) {
    try {
        const responseData = (yield call(COPY_LESSON, action.payload.id)).data;

        if (action.payload.callback) {
            action.payload.callback(responseData);
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateLesson(action: DefaultAction<LessonUpdateRequestedPayload>) {
    try {
        const responseData: UpdateLessonResponseData = (yield call(UPDATE_LESSON, action.payload.id, action.payload.data)).data;
        LogAmplitudeEvent(AMPLITUDE_EVENT.LESSON_EDIT_SAVED, {
            content_number: responseData.model.position,
            lesson_name: responseData.model.name,
        });
        yield put(createNotificationAction({ text: 'LESSON_FORM.SUCCESS_SAVE_NOTIFICATION'}));
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        yield put(createNotificationAction({ text: 'LESSON_FORM.FAILED_SAVE_NOTIFICATION'}));
        console.log(e);
    }
}

function* updateLessonPublished(action) {
    try {
        const preparedLessonData = (yield call(GET_LESSON, action.payload.id)).data;
        preparedLessonData.published = action.payload.published;
        preparedLessonData.course_id = action.payload.courseId;
        delete preparedLessonData.attachment_id;
        delete preparedLessonData.id;
        delete preparedLessonData.cover_url;

        yield call(UPDATE_LESSON, action.payload.id, preparedLessonData);
    } catch (e) {
        console.log(e);
    }
}

function* deleteLesson(action) {
    try {
        const responseData = (yield call(DELETE_LESSON, action.payload.id)).data;
        LogAmplitudeEvent(AMPLITUDE_EVENT.LESSON_DELETE_FINISHED, {
          lesson_id: responseData.model.id,
          lesson_name: responseData.model.name
        });
        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (e) {
        console.log(e);
    }
}

function* lessonsSaga() {
    yield takeLatest(LESSON_GET_REQUESTED, getLesson);
    yield takeLatest(LESSON_CREATE_REQUESTED, createLesson);
    yield takeLatest(LESSON_COPY_REQUESTED, copyLesson);
    yield takeLatest(LESSON_UPDATE_REQUESTED, updateLesson);
    yield takeLatest(LESSON_UPDATE_PUBLISHED_REQUESTED, updateLessonPublished);
    yield takeLatest(LESSON_DELETE_REQUESTED, deleteLesson);
}

export default lessonsSaga;
