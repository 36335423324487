export const FILE_UPLOADS_PROGRESS_RESET = 'FILE_UPLOADS_PROGRESS_RESET';

export const fileUploadsProgressResetAction = () => ({
  type: FILE_UPLOADS_PROGRESS_RESET
})

export const FILE_UPLOADS_PROGRESS_UPDATE = 'FILE_UPLOADS_PROGRESS_UPDATE';

export const fileUploadsProgressUpdateAction = (data) => ({
  type: FILE_UPLOADS_PROGRESS_UPDATE,
  payload: data
})
