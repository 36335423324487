import {all, } from 'redux-saga/effects'
import usersSaga from "./users";
import authSaga from "./auth";
import authRegistrationSaga from "./authRegistration";
import billingSaga from "./billing";
import coursesSaga from "./courses";
import compilationsSaga from "./compilations";
import colorsSaga from "./colors";
import citiesSaga from "./cities";
import dashboardsSaga from "./dashboards";
import positionsSaga from "./positions";
import lessonsSaga from "./lessons";
import testsSaga from "../tests/saga";
import filesSaga from "./files";
import feedbackSaga from "./feedback";
import notificationsSaga from "./notifications";
import payments from './payments';
import metaBaseSaga from "./metaBase";
import mobileUsersSaga from "./mobileUsers";
import mobileUsersImportSaga from "./mobileUsersImport";
import levelsSaga from "./levels";
import tenantSaga from "./tenant";
import newsSaga from "./news";
import stepsSaga from "../steps/saga";
import transitionsSaga from "../transitions/saga";
import sectionsSaga from "../sections/saga";
import testQuestionsSaga from "../questions/saga";
import testVariantsSaga from "../variants/saga";
import unsplashSaga from "../unsplash/saga";
import downloadsSaga from "./downloads";

function* rootSaga() {
    yield all([
        authSaga(),
        authRegistrationSaga(),
        billingSaga(),
        usersSaga(),
        mobileUsersSaga(),
        mobileUsersImportSaga(),
        coursesSaga(),
        compilationsSaga(),
        colorsSaga(),
        citiesSaga(),
        dashboardsSaga(),
        positionsSaga(),
        lessonsSaga(),
        testsSaga(),
        testQuestionsSaga(),
        testVariantsSaga(),
        filesSaga(),
        feedbackSaga(),
        notificationsSaga(),
        payments(),
        metaBaseSaga(),
        levelsSaga(),
        tenantSaga(),
        newsSaga(),
        stepsSaga(),
        transitionsSaga(),
        sectionsSaga(),
        unsplashSaga(),
        downloadsSaga()
    ])
}

export default rootSaga;
