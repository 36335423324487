import {GetCompilationCoversInterface} from "../../api/Files";

export const ACTION_SET_COMPILATION_COVERS: string = 'ACTION_SET_COMPILATION_COVERS';

export const setCompilationCoversAction = (compilationCovers: GetCompilationCoversInterface) => ({
  type: ACTION_SET_COMPILATION_COVERS,
  payload: compilationCovers,
});

export const COMPILATION_COVERS_GET_REQUESTED: string = 'COMPILATION_COVERS_GET_REQUESTED';

export const compilationCoversGetRequestedAction = () => ({
  type: COMPILATION_COVERS_GET_REQUESTED,
});