import {
  FILE_UPLOADS_PROGRESS_UPDATE,
  FILE_UPLOADS_PROGRESS_RESET
} from './actions';

interface FileUploadsInterface {
  file: File;
  fileId: string;
  progress: number;
  callback?: (params?: object | string | number) => void;
}

interface StateInterface {
  fileUploads: FileUploadsInterface[],
}

const defaultState: StateInterface = {
  fileUploads: []
}

const fileUploadsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FILE_UPLOADS_PROGRESS_UPDATE:
      const newFileUploads: FileUploadsInterface[] = [...state.fileUploads]
      let currentProgressIndex = newFileUploads.findIndex((fileUpload) => fileUpload.fileId === action.payload.fileId)
      if (currentProgressIndex !== - 1) {
        newFileUploads[currentProgressIndex] = action.payload
      } else {
        newFileUploads.push(action.payload)
      }
      return {
        ...state,
        fileUploads: newFileUploads
      }
    case FILE_UPLOADS_PROGRESS_RESET:
      return {
        ...state,
        fileUploads: []
      }
    default:
      return state
  }
}

export default fileUploadsReducer;
