export const ACTION_GET_COMMON_PAGE_DATA_REQUESTED = "ACTION_GET_COMMON_PAGE_DATA_REQUESTED";

export const getCommonPageDataRequestedAction = (data) => ({
  type: ACTION_GET_COMMON_PAGE_DATA_REQUESTED,
  payload: data
})

export const ACTION_SET_COMMON_PAGE_DATA = "ACTION_SET_COMMON_PAGE_DATA";

export const setCommonPageDataAction = (data) => ({
  type: ACTION_SET_COMMON_PAGE_DATA,
  payload: data
})

export const ACTION_GET_USERS_ACTIVITY_DATA_REQUESTED = "ACTION_GET_USERS_ACTIVITY_DATA_REQUESTED";

export const getUsersActivityDataRequestedAction = (data) => ({
  type: ACTION_GET_USERS_ACTIVITY_DATA_REQUESTED,
  payload: data
})

export const ACTION_SET_USERS_ACTIVITY_DATA = "ACTION_SET_USERS_ACTIVITY_DATA";

export const setUsersActivityDataAction = (data) => ({
  type: ACTION_SET_USERS_ACTIVITY_DATA,
  payload: data
})

export const ACTION_GET_COURSE_ACTIVITY_DATA_REQUESTED = "ACTION_GET_COURSE_ACTIVITY_DATA_REQUESTED";

export const getCourseActivityDataRequestedAction = (data) => ({
  type: ACTION_GET_COURSE_ACTIVITY_DATA_REQUESTED,
  payload: data
})

export const ACTION_SET_COURSE_ACTIVITY_DATA = "ACTION_SET_COURSE_ACTIVITY_DATA";

export const setCourseActivityDataAction = (data) => ({
  type: ACTION_SET_COURSE_ACTIVITY_DATA,
  payload: data
})

export const ACTION_RESET_COURSE_ACTIVITY_DATA = "ACTION_RESET_COURSE_ACTIVITY_DATA";

export const resetCourseActivityDataAction = () => ({
  type: ACTION_RESET_COURSE_ACTIVITY_DATA
})

export const ACTION_GET_USER_ACTIVITY_DATA_REQUESTED = "ACTION_GET_USER_ACTIVITY_DATA_REQUESTED";

export const getUserActivityDataRequestedAction = (data) => ({
  type: ACTION_GET_USER_ACTIVITY_DATA_REQUESTED,
  payload: data
})

export const ACTION_SET_USER_ACTIVITY_DATA = "ACTION_SET_USER_ACTIVITY_DATA";

export const setUserActivityDataAction = (data) => ({
  type: ACTION_SET_USER_ACTIVITY_DATA,
  payload: data
})

export const ACTION_RESET_USER_ACTIVITY_DATA = "ACTION_RESET_USER_ACTIVITY_DATA";

export const resetUserActivityDataAction = () => ({
  type: ACTION_RESET_USER_ACTIVITY_DATA
})

export const ACTION_GET_COURSES_REQUESTED = "ACTION_GET_COURSES_REQUESTED";

export const getCoursesRequestedAction = (data) => ({
  type: ACTION_GET_COURSES_REQUESTED,
  payload: data
})

export const ACTION_SET_COURSES_DATA = "ACTION_SET_COURSES_DATA";

export const setCoursesDataAction = (data) => ({
  type: ACTION_SET_COURSES_DATA,
  payload: data
})

export const ACTION_GET_NEWS_DATA_REQUESTED = "ACTION_GET_NEWS_DATA_REQUESTED";

export const getNewsDataRequestedAction = (data) => ({
  type: ACTION_GET_NEWS_DATA_REQUESTED,
  payload: data
})

export const ACTION_SET_NEWS_DATA = "ACTION_SET_NEWS_DATA";

export const setNewsDataAction = (data) => ({
  type: ACTION_SET_NEWS_DATA,
  payload: data
})
