import {PayloadWithCallback} from "../../interfaces/store";
import {Photos} from "unsplash-js/dist/methods/search/types/response";
import {UnsplashGetPhotosParams} from "../../api/Unsplash";

export const UNSPLASH_GET_PHOTOS = 'UNSPLASH_GET_PHOTOS';

export interface UnsplashPhotosGetRequestedPayload extends PayloadWithCallback {
  params: UnsplashGetPhotosParams;
}

export const unsplashPhotosGetRequestedAction = (data: UnsplashPhotosGetRequestedPayload) => ({
  type: UNSPLASH_GET_PHOTOS,
  payload: data,
});

export const UNSPLASH_SET_PHOTOS = 'UNSPLASH_SET_PHOTOS';

export const unsplashSetPhotosAction = (data: Photos) => ({
  type: UNSPLASH_SET_PHOTOS,
  payload: data,
});