import {DATE_MONTH_KEYS, MILLISECONDS_IN_SECONDS, SECONDS_IN_HOUR, SECONDS_IN_MINUTE} from "../constants/date";

export interface PreparedDateInfoInterface {
    date?: number,
    monthKey?: string,
}

export const PrepareScreenplayDate = (isoDate: string,): PreparedDateInfoInterface => {
    const date: Date = new Date(isoDate);

    return {
        date: date.getDate(),
        monthKey: `DATE.MONTH.${DATE_MONTH_KEYS[date.getMonth()]}`,
    }
};

export const PrepareDate = (
    date: string | Date,
    isFullYear = false,
    withTime = true,
    separator = '.',
    isReversed = false,
): string => {
    const dateObject: Date = new Date(date);
    const day: number = dateObject.getDate();
    const preparedDay: string | number = day > 9 ? day : `0${day}`;
    const month: number = dateObject.getMonth() + 1;
    const preparedMonth: string | number = month > 9 ? month : `0${month}`;
    const fullYear: number = dateObject.getFullYear();
    const year: string | number = isFullYear ? fullYear : `${fullYear}`.slice(-2);
    const hour: number = dateObject.getHours();
    const preparedHours: string | number = hour > 9 ? hour : `0${hour}`;
    const minutes: number = dateObject.getMinutes();
    const preparedMinutes: string | number = minutes > 9 ? minutes : `0${minutes}`;
    let resultDate: string = isReversed
        ? `${year}${separator}${preparedMonth}${separator}${preparedDay}`
        : `${preparedDay}${separator}${preparedMonth}${separator}${year}`;
    if (withTime) {
        resultDate += ` ${preparedHours}:${preparedMinutes}`;
    }
    return resultDate;
};

export const SubtractDates = (date1?: string | number, date2?: string | number): Date => {
    return new Date(new Date(date1).getTime() - new Date(date2).getTime());
};

export const MillisecondsToSeconds = (milliseconds: number): number => {
    return Math.floor(milliseconds / MILLISECONDS_IN_SECONDS);
};

export const SecondsToHoursAndMinutes = (seconds: number): number[] => {
    const hours: number = Math.floor(seconds / SECONDS_IN_HOUR);
    const minutes: number = Math.floor((seconds - (hours * SECONDS_IN_HOUR)) / SECONDS_IN_MINUTE);

    return [hours, minutes];
};

export const DateToTime = (date: Date): string => {
    const hours: number = date.getHours();
    const minutes: number = date.getMinutes();
    const preparedHours: string | number = hours > 9 ? hours : `0${hours}`;
    const preparedMinutes: string | number = minutes > 9 ? minutes : `0${minutes}`;
    return `${preparedHours}:${preparedMinutes}`;
};